import * as React from 'react';

import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Typography } from '@edapp/ed-components';
import { nonProxyMotion } from '@maggie/config/motion-wrapper';
import { useIsDevice } from '@maggie/core/device';
import { OfflineSelectors } from '@maggie/store/offline/selectors';
import { UserSelectors } from '@maggie/store/user/selectors';

export const DownloadFooter: React.FC = () => {
  const { t } = useTranslation('learners-experience');
  const isNewNavbar = useSelector(UserSelectors.hasNewNavbarEnabled);
  const isDownloading = useSelector(OfflineSelectors.getDownloading);
  const isDevice = useIsDevice();

  if (!isDevice) {
    return null;
  }

  return (
    <AnimatePresence>
      {isDownloading && (
        <DownloadingContainer
          isNewNavbar={isNewNavbar}
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ ease: 'anticipate', clamp: true }}
        >
          <DownloadingAnimation color="textMuted" variant="paragraph" p={isNewNavbar ? 2 : 3}>
            {t('lesson.status.downloading')}
          </DownloadingAnimation>
        </DownloadingContainer>
      )}
    </AnimatePresence>
  );
};

const DownloadingContainer = styled(nonProxyMotion.div)<{ isNewNavbar: boolean }>`
  background-color: ${props => props.theme.colors.lightGrey};
  ${({ isNewNavbar, theme }) =>
    isNewNavbar
      ? css`
          position: relative;
          border-top: 1px solid ${theme.colors.grey};
        `
      : css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          box-shadow: ${theme.shadows.middle};
        `}
`;

const DownloadingAnimation = styled(Typography)`
  font-size: 18px;

  :after {
    content: ' .';
    animation: dots 2s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: ${props => props.theme.colors.zilch};
      text-shadow: 0.25em 0 0 ${props => props.theme.colors.zilch},
        0.5em 0 0 ${props => props.theme.colors.zilch};
    }

    40% {
      color: ${props => props.theme.colors.darkGrey};
      text-shadow: 0.25em 0 0 ${props => props.theme.colors.zilch},
        0.5em 0 0 ${props => props.theme.colors.zilch};
    }

    60% {
      text-shadow: 0.25em 0 0 ${props => props.theme.colors.darkGrey},
        0.5em 0 0 ${props => props.theme.colors.zilch};
    }

    80%,
    100% {
      text-shadow: 0.25em 0 0 ${props => props.theme.colors.darkGrey},
        0.5em 0 0 ${props => props.theme.colors.darkGrey};
    }
  }
`;
