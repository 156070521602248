import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { ThemeProvider as Provider } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

import { Loading } from '@edapp/ed-components';
import { ScEdappUIConfigAdapter, localizationWithI18next } from '@edapp/sc-web-ui';

import type { ThemeType as ThemeAppType } from '../app/theme';
import type { ThemeType as ThemeCommonType } from '../common/theme';
import { getTheme, schemes } from '../constants';
import { GlobalStyle, THEME_CHANGED_EVENT, themeTraining } from './constants';
import { convertScThemeToEdScheme } from './helpers';
import type { ThemeSetting } from './types';

type SCThemeContextType = {
  themeSetting: ThemeSetting;
};

const SCThemeContext = React.createContext<SCThemeContextType | undefined>(undefined);

type ThemeProviderProps = {
  /**
   * Theme type coming from SafetyCulture MFE shell
   */
  initialThemeSetting: ThemeSetting;
  baseTheme?: ThemeAppType | ThemeCommonType;
};
export const SCThemeContextProvider: React.FC<React.PropsWithChildren<ThemeProviderProps>> = ({
  children,
  initialThemeSetting,
  baseTheme
}) => {
  const { t } = useTranslation();
  const [themeSetting, setThemeSetting] = React.useState<ThemeSetting>(initialThemeSetting);

  /**
   * Subscribe to theme changed event and update internal state
   */
  React.useEffect(() => {
    const onThemeChanged = ({ detail: setting }: CustomEvent<ThemeSetting>) => {
      if (setting === themeSetting) {
        return; // same one - nothing to do here
      }

      const nextSettingScheme = convertScThemeToEdScheme(setting);
      if (!schemes.find(s => s === nextSettingScheme)) {
        // Add error log to sentry?
        return; // invalid scheme
      }

      setThemeSetting(setting);
    };
    window.addEventListener(THEME_CHANGED_EVENT, onThemeChanged);
    return () => window.removeEventListener(THEME_CHANGED_EVENT, onThemeChanged);
  }, [themeSetting]);

  const scheme = convertScThemeToEdScheme(themeSetting);
  const theme = getTheme(scheme, baseTheme ?? themeTraining);

  return (
    <SCThemeContext.Provider value={{ themeSetting }}>
      <Provider theme={(theme as unknown) as DefaultTheme}>
        <React.Suspense fallback={<Loading py={2} />}>
          <ScEdappUIConfigAdapter
            edappTheme={theme}
            localization={localizationWithI18next(t, 'sc-web-ui')}
            isUxp={true}
            schemeType={scheme}
          >
            <GlobalStyle />
            {children}
          </ScEdappUIConfigAdapter>
        </React.Suspense>
      </Provider>
    </SCThemeContext.Provider>
  );
};

export const useSCTheme = (): SCThemeContextType => {
  const context = React.useContext(SCThemeContext);
  if (context === undefined) {
    throw new Error('useSCTheme must be used within a SCThemeContextProvider');
  }
  return context;
};
