import { find, findIndex, get, includes } from 'lodash';

import { ErrorLogger } from '@edapp/monitoring';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import { UserSelectors } from '@maggie/store/user/selectors';

import { SocialLearningApi } from './social_learning_api';
import type {
  SocialLearningComment,
  SocialLearningCommentForThomas,
  SocialLearningCommentInteraction,
  SocialLearningCommentPost,
  SocialLearningCommentsForSlideResponse,
  SocialLearningSlide
} from './types';

const DEBUG = false;

const PAGE_SIZE = 10;

export class SocialLearningService {
  static async getSocialLearningSummary(lessonId: string) {
    return Promise.all([
      SocialLearningApi.checkIfNewComments(lessonId),
      SocialLearningApi.getCommentCountForLesson(lessonId)
    ]);
  }

  static isSocialLearningEnabled() {
    return UserSelectors.hasSocialLearningEnabled(window.__store.getState().user);
  }

  slideCommentCount: number;
  hasMorePage: boolean;
  currentPageNum: number;
  comments: SocialLearningCommentForThomas[];

  constructor() {
    this.slideCommentCount = 0;
    this.hasMorePage = true;
    this.currentPageNum = 1;
    this.comments = [];

    this.postProgress = this.postProgress.bind(this);
    this.getSlideCommentsNextPage = this.getSlideCommentsNextPage.bind(this);
    this.getSlideCommentsFromApp = this.getSlideCommentsFromApp.bind(this);
    this.getAllCommentsForALesson = this.getAllCommentsForALesson.bind(this);
    this.getCommentsForSlide = this.getCommentsForSlide.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.__handleDeletion__ = this.__handleDeletion__.bind(this);
    this.__mapToEngineData__ = this.__mapToEngineData__.bind(this);
  }

  // enabled anonymous
  isAttributionEnabled() {
    return UserSelectors.getSocialAttribution(window.__store.getState());
  }

  clearState() {
    this.slideCommentCount = 0;
    this.hasMorePage = true;
    this.currentPageNum = 1;
    return (this.comments = []);
  }

  postProgress(lessonId: string, slideId: string) {
    if (this.slideCommentCount > 0) {
      SocialLearningApi.postProgress(lessonId, slideId).catch(err => {
        if (DEBUG) {
          console.error('Post user progress failed', err);
        }
      });
    }
  }

  /**
   * This is called from thomas
   */
  getSlideCommentsNextPage({ lessonId, slideId }: { lessonId: string; slideId: string }) {
    if (DEBUG) {
      console.debug('getSlideCommentsNextPage');
    }
    if (this.hasMorePage) {
      this.currentPageNum++;
      return this.getCommentsForSlide(lessonId, slideId, this.currentPageNum);
    } else {
      return this.raisePageEndEvent();
    }
  }

  /**
   * This is called from learners app
   */
  getSlideCommentsFromApp(payload: {
    lessonId: string;
    slideId: string;
    pageNum: number;
  }): Promise<{ comments: SocialLearningCommentForThomas[]; total: number; pageNum: number }> {
    return new Promise((resolve, reject) => {
      return SocialLearningApi.getCommentsForLessonSlide(
        payload.lessonId,
        payload.slideId,
        payload.pageNum,
        PAGE_SIZE
      )
        .then(data => {
          const comments = this.__mapToEngineData__(data.items);
          const total = data.totalCount;
          return resolve({ comments, total, pageNum: payload.pageNum });
        })
        .catch(err => reject(err));
    });
  }

  getAllCommentsForALesson(lessonId: string) {
    return new Promise<SocialLearningSlide[]>((resolve, reject) => {
      return SocialLearningApi.getLessonCommentSummary(lessonId)
        .then(({ slides }) => {
          const slideComments = slides.map<SocialLearningSlide>(item => {
            return {
              comments: this.__mapToEngineData__(item.comments),
              id: item.slideId,
              slideTitle: item.slideTitle,
              currentPage: 1,
              // We force a big number to ensure it will trigger a pagination
              totalCount: Number.MAX_SAFE_INTEGER
            };
          });
          return resolve(slideComments);
        })
        .catch(err => reject(err));
    });
  }

  raisePageEndEvent() {
    if (DEBUG) {
      console.debug('raisePageEndEvent:: in app');
    }
    return Backbone.Events.trigger('nomore-comments', null);
  }

  getCommentsForSlide(lessonId: string, slideId: string, pageNum: number = 1) {
    const lesson = LessonSelectors.getLesson(lessonId, window.__store.getState());
    if (!lesson) {
      return;
    }
    const slide = find(lesson.configuration.slides, s => s.id === slideId);

    if (!get(slide, 'data.socialLearning.enabled')) {
      return;
    }

    return SocialLearningApi.getCommentsForLessonSlide(lessonId, slideId, pageNum, PAGE_SIZE)
      .then(resp => {
        const { total, comments, hasMore } = this.__processResponse__(resp);
        this.slideCommentCount = total;
        this.hasMorePage = hasMore;
        if (!hasMore) {
          this.raisePageEndEvent();
        }
        this.comments = this.comments.concat(comments);
        return this.__sendDataToEngine__(this.comments, this.slideCommentCount, this.hasMorePage);
      })
      .catch(err => {
        if (DEBUG) {
          console.error('err', err);
        }
        return this.__raiseErrorToEngine__({ msg: 'getCommentsForSlide' });
      });
  }

  postComment(lessonId: string, slideId: string, commentData: SocialLearningCommentPost) {
    return SocialLearningApi.postComment(lessonId, slideId, commentData)
      .then(() => {
        SocialLearningApi.postProgress(lessonId, slideId);
        this.clearState();
        return this.getCommentsForSlide(lessonId, slideId, this.currentPageNum);
      })
      .catch(err => {
        if (DEBUG) {
          console.error('err', err);
        }
        return this.__raiseErrorToEngine__({ msg: 'postComment' });
      });
  }

  addLike({ lessonId, slideId, commentId }: SocialLearningCommentInteraction) {
    return SocialLearningApi.addLike({ lessonId, slideId, commentId })
      .then(() => {
        if (DEBUG) {
          return console.debug('done like ');
        }
      })
      .catch(err => {
        if (DEBUG) {
          console.error('err', err);
        }
        return this.__raiseErrorToEngine__({ msg: 'addLike' });
      });
  }

  dislike({ lessonId, slideId, commentId }: SocialLearningCommentInteraction) {
    return SocialLearningApi.disLike({ lessonId, slideId, commentId })
      .then(() => {
        if (DEBUG) {
          return console.debug('done dislike ');
        }
      })
      .catch(err => {
        if (DEBUG) {
          console.error('err', err);
        }
        return this.__raiseErrorToEngine__({ msg: 'disLike' });
      });
  }

  addFlag({ lessonId, slideId, commentId }: SocialLearningCommentInteraction) {
    SocialLearningApi.addFlag({ lessonId, slideId, commentId })
      .then(() => {
        if (DEBUG) {
          console.debug('done flag ');
        }
      })
      .catch(err => {
        if (DEBUG) {
          console.error('err', err);
        }
        this.__raiseErrorToEngine__({ msg: 'addLike' });
      });
  }

  // Delete comment -> get 200 OK response
  // -> remove comment from local collection
  // -> decrease total count by one -> update view (Engine)
  // -> reduce comment size may cause pageNum state incorrect need to update it
  deleteComment({ lessonId, slideId, commentId }: SocialLearningCommentInteraction) {
    if (DEBUG) {
      console.debug('delete comment', lessonId, slideId, commentId);
    }
    return SocialLearningApi.deleteComment({ lessonId, slideId, commentId })
      .then(() => {
        const data = this.__handleDeletion__(commentId);
        if (!data) {
          return;
        }
        const { comments, slideCommentCount, currentPageNum } = data;
        this.comments = comments;
        this.slideCommentCount = slideCommentCount;
        this.currentPageNum = currentPageNum;
        let hasMore = false;
        if (PAGE_SIZE * currentPageNum < slideCommentCount) {
          hasMore = true;
        }
        return this.__sendDataToEngine__(this.comments, this.slideCommentCount, hasMore);
      })
      .catch(err => {
        if (DEBUG) {
          console.error('err', err);
        }
        return this.__raiseErrorToEngine__({ msg: 'deleteComment' });
      });
  }

  __handleDeletion__(commentId: string) {
    // find the comment to be deleted and delete it from array
    const index = findIndex(this.comments, comment => comment.id === commentId);
    if (index < 0 || index >= this.comments.length) {
      console.error('cannot find comments in comments', index);
      this.__raiseErrorToEngine__({ msg: 'deleteComment' });
      return undefined;
    }
    const comments = this.comments.slice();
    comments.splice(index, 1);
    const slideCommentCount = this.slideCommentCount - 1;
    const currentPageNum = Math.ceil(this.comments.length / PAGE_SIZE);
    return { comments, slideCommentCount, currentPageNum };
  }

  __processResponse__(data: SocialLearningCommentsForSlideResponse) {
    let hasMore = false;
    let total = 0;
    let comments: SocialLearningCommentForThomas[] = [];
    if ((data != null ? data.items : undefined) && data.items.length > 0) {
      if (data.totalCount) {
        total = data.totalCount;
      }
      if (total > PAGE_SIZE * this.currentPageNum) {
        hasMore = true;
      }
      const mappedData = this.__mapToEngineData__(data.items);
      comments = mappedData;
    }
    return { total, comments, hasMore };
  }

  __getAuthor__(name: string, email: string) {
    if (!this.isAttributionEnabled()) {
      return 'Anonymous';
    }
    let author = this.__getFirstName__(name);
    author = author
      ? this.__getEmailWithoutDomain__(author)
      : this.__getEmailWithoutDomain__(email);

    return author || 'Anonymous';
  }

  __getEmailWithoutDomain__(email: string) {
    const emailRegx = /^([^@]+)@*/;
    const result = email.match(emailRegx);
    return result ? result[1] : undefined;
  }

  __getFirstName__(name: string) {
    const regex = /^([\S]+)\s*/;
    const result = name.match(regex);
    return result ? result[1] : undefined;
  }

  // map to the format that fits the engine
  __mapToEngineData__(data: SocialLearningComment[]): SocialLearningCommentForThomas[] {
    const userId = UserSelectors.getId(window.__store.getState());
    return data.map(item => {
      return {
        id: item.commentId,
        message: item.text,
        author: this.__getAuthor__(item.user.name, item.user.email),
        likes: item.likes.length,
        timestamp: item.created,
        isFromCurrentLearner: item.user.id === userId,
        isLikedByLearner: includes(
          item.likes.map(usrInfo => usrInfo.userId),
          userId
        ),
        isFlaggedByLearner: includes(
          item.flags.map(usrInfo => usrInfo.userId),
          userId
        )
      };
    });
  }

  // trigger event to pass data to engine
  __sendDataToEngine__(
    comments: SocialLearningCommentForThomas[],
    total: number,
    hasMore: boolean = true
  ) {
    Backbone.Events.trigger('comment-loaded', { comments, total, hasMore });
  }

  __raiseErrorToEngine__(message: { msg: string }) {
    ErrorLogger.captureEvent('Social Learning Service Error', 'error', { message });
    Backbone.Events.trigger('error-sl', message);
  }
}
