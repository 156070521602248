/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { PromiseExtended } from 'dexie';
import { t } from 'i18next';
import * as _ from 'lodash';

import { ErrorLogger } from '@edapp/monitoring';
import { RequestUtils } from '@edapp/request';
import type { Token } from '@edapp/request';
import { appVersion } from '@maggie/config/appVersion';
import { ENV } from '@maggie/config/env';
import { checkOnline } from '@maggie/cordova/network_utils';
import { Platform } from '@maggie/cordova/platform';
import { Urls } from '@maggie/store/constants';
import { UserSelectors } from '@maggie/store/user/selectors';
import { createUUID } from '@maggie/utils/uuid';

import type {
  AttemptInteraction,
  EventInteraction,
  EventInteractionName,
  GameInteraction,
  Interaction,
  QuizPlayInteraction,
  ReviewLessonInteraction,
  ReviewSlideInteraction,
  SlideInteraction,
  SlideStateInteraction,
  SurveyInteraction,
  ViewInteraction,
  VisitInteraction
} from './hippo-interaction-types';
import type { InteractionElement, UserIdentification } from './interaction-database';
import { interactionQueue } from './interaction-queue';

const ENQUEUE_FAILURE_RETRY_DELAY = 1000;
const ENQUEUE_FAILURE_MAX_RETRY = 10;
const RETRY_DELAY = 1000; // 1 sec
const RETRY_MAX = 10000; // 10 sec
const RETRY_BASE = 1.5;
const POLL_INTERVAL = 5000;
const MAX_SERVER_ERRORS = 3;
const POST_TIMEOUT = 60000;
const QUEUE_WAIT_POLL_INTERVAL = 100;
const MAX_PARALLELISM = 1;
const POLL_IMPORTANT_INTERVAL = 20000; // 20 sec, not very often
const MAX_BATCH_SIZE = 20;
const MAX_COMFORTABLE_QUEUE_LENGTH = 500; // we have telemetry on queues longer than this

const queue = interactionQueue('interaction-send-queue');

type InteractionBatchRequestError = {
  status: XMLHttpRequest['status'];
  statusText: XMLHttpRequest['statusText'];
  response: XMLHttpRequest['response'];
  responseText: XMLHttpRequest['responseText'];
  readyState: XMLHttpRequest['readyState'];
};

const getType = (interaction: Interaction) => {
  const rules = {
    slide(i: SlideInteraction) {
      return (
        (i != null ? i.type : undefined) === 'slide' &&
        // @ts-ignore - not sure why we checking if game || survey are there... to be safe, I will keep the code here
        !((i != null ? i.game : undefined) || (i != null ? i.survey : undefined))
      );
    },
    view(i: ViewInteraction) {
      return (i != null ? i.type : undefined) === 'view';
    },
    event(i: EventInteraction) {
      return (i != null ? i.type : undefined) === 'event';
    },
    game(i: GameInteraction) {
      // @ts-ignore - not sure why we checking if slide is there... to be safe, I will keep the code here
      return (i != null ? i.type : undefined) === 'slide' && (i != null ? i.game : undefined);
    },
    visit(i: VisitInteraction) {
      return (i != null ? i.type : undefined) === 'visit';
    },
    survey(i: SurveyInteraction) {
      // @ts-ignore - not sure why we checking if slide is there... to be safe, I will keep the code here
      return (i != null ? i.type : undefined) === 'slide' && (i != null ? i.survey : undefined);
    },
    attempt(i: AttemptInteraction) {
      return i.type === 'attempt';
    },
    'slide-state'(i: SlideStateInteraction) {
      return i.type === 'slide-state';
    },
    'quiz-play'(i: QuizPlayInteraction) {
      return i.type === 'quiz-play';
    },
    'review-lesson'(i: ReviewLessonInteraction) {
      return i.type === 'review-lesson';
    },
    'review-slide'(i: ReviewSlideInteraction) {
      return i.type === 'review-slide';
    }
  };

  const type = Object.keys(rules).find(key => {
    return rules[key](interaction);
  });

  return type;
};

const affectsSyncData = (interaction: Interaction): boolean => {
  const eventsThatAffectSyncData: EventInteractionName[] = [
    // TODO: https://safetyculture.atlassian.net/browse/TRAINING-528
    // @ts-ignore
    'lesson-opened',
    'lesson-unlocked',
    'course-completed',
    // TODO: https://safetyculture.atlassian.net/browse/TRAINING-528
    // @ts-ignore
    'course-opened',
    'course-unlocked',
    // TODO: https://safetyculture.atlassian.net/browse/TRAINING-528
    // @ts-ignore
    'course-reset-completed'
  ];

  const types = {
    event(i: EventInteraction) {
      if (!i) {
        return false;
      }

      return Array.from(eventsThatAffectSyncData).includes(i.name);
    },
    attempt() {
      return true;
    },
    // TODO: https://safetyculture.atlassian.net/browse/TRAINING-528
    // the one in hippo is called 'slide-state'
    'slide-states'() {
      return true;
    }
  };

  if (!!interaction.type) {
    const rule = types[interaction.type];
    if (!!rule) {
      return rule(interaction);
    }
  }

  return false;
};

const getIdentity = (): UserIdentification => {
  const { id: userId, applicationId } = UserSelectors.getUser(window.__store.getState());
  return {
    userId,
    applicationId
  };
};

const getToken = () => UserSelectors.getToken(window.__store.getState());

const prepareInteractionForQueue = (interaction: Interaction): InteractionElement => {
  // Store the token with the interaction in the queue
  // so we can handle logging in/out without issues.
  const id = interaction.ClientId != null ? interaction.ClientId : createUUID();
  const toPost = _.extend(
    {
      ClientId: id,
      endpoint: getType(interaction)
    },
    interaction
  );
  const serverErrors = 0;

  return {
    id,
    toPost,
    ...getIdentity(),
    serverErrors
  };
};

const enqueueInteraction = (interaction: Interaction, timesRetried?: number) => {
  if (!timesRetried) {
    timesRetried = 0;
  }

  const { userId, applicationId } = getIdentity();
  if (!userId || !applicationId) {
    return;
  }

  if (timesRetried >= ENQUEUE_FAILURE_MAX_RETRY) {
    // At this point it's unlikely the queue is working at all.
    // As a last-ditch effort, try to send the interaction directly
    // to the server.
    const token = getToken();
    if (!token) {
      ErrorLogger.captureEvent(
        'Attempted last-ditch interaction sending but there was no token',
        'info',
        {}
      );
      return;
    }

    // ErrorLogger.captureEvent('Tried to enqueue too many times', 'info', {
    //   timesRetried,
    //   interaction
    // });
    const interactionToSend = prepareInteractionForQueue(interaction);
    sendBatchOfInteractions([interactionToSend], token);
    return;
  }

  // If the interaction affects sync data, give it high priority
  queue.enqueue(prepareInteractionForQueue(interaction), affectsSyncData(interaction)).catch(() => {
    // when interactions fail to be enqueued, retry them
    // ErrorLogger.captureEvent('failure to queue interaction', 'error', { interaction, err });

    return setTimeout(
      () => enqueueInteraction(interaction, (timesRetried || 0) + 1),
      ENQUEUE_FAILURE_RETRY_DELAY
    );
  });

  // If the interaction will affect the sync data,
  // tell the session to wait for the server to be up to date
  // before applying these changes. Hippo tracks this value
  // in the campus user (campus_user.modified_updated_timestamp)
  if (affectsSyncData(interaction)) {
    return pollForImportantInteractions();
  }

  return;
};

// Supported since May 2023 on mobile: https://caniuse.com/mdn-api_abortsignal_timeout_static
const abortSignalTimeout = AbortSignal?.['timeout'];

const sendOne = (batch: InteractionElement[], token: Token): Promise<string[]> => {
  const toSend = batch.map(({ toPost }) => toPost);

  // we need to add "endpoint" to interactions here,
  // just in case they had interactions enqueued before the
  // update to add "endpoint"
  for (const interaction of Array.from(toSend)) {
    if (interaction.endpoint == null) {
      const interactionType = getType(interaction);
      if (!interactionType) {
        console.error(`Unknown interactionType`);
      }

      interaction.endpoint = getType(interaction) || '';
    }
  }

  return RequestUtils.httpFetch<string[], Interaction<string, string>[]>(
    'POST',
    `${ENV.HIPPO_API}/${Urls.INTERACTIONS}/batch`,
    token,
    toSend,
    undefined, // credentials
    {
      Platform: Platform.get(),
      AppVersion: appVersion
    },
    undefined, // isUrlEncodedFormData
    window.__l10n.language,
    undefined, // uriEncodeData
    abortSignalTimeout?.(POST_TIMEOUT)
  );
};

// Identifies errors that are probably due to a bug
// rather than network connectivity. We only retry
// these a maximum of MAX_SERVER_ERRORS times because
// they are not likely to work until a fix is deployed.
// They are however logged to the clientissues collection.
const isServerError = (status: number) => {
  if (400 <= status && status <= 499) {
    return true;
  } else if (500 <= status && status <= 511) {
    return true;
  } else {
    return false;
  }
};

// this function is called from many places, it should handle whether
// there is something in progress or not.
let currentlyRunning = 0;
const startIfAnythingToSend = () => {
  const recurseLater = () => setTimeout(startIfAnythingToSend, POLL_INTERVAL);
  if (!checkOnline()) {
    recurseLater();
    return;
  }

  if (currentlyRunning > MAX_PARALLELISM) {
    return;
  }

  const identity = getIdentity();
  const token = getToken();
  if (!token) {
    // not authenticated yet - let's try again later
    recurseLater();
    return;
  }

  return queue
    .takeBatch(MAX_BATCH_SIZE, identity)
    .then(batch => {
      if (batch.length === 0) {
        recurseLater(); // nothing to send
        return;
      }

      ++currentlyRunning;
      sendBatchOfInteractions(batch, token).then(() => {
        --currentlyRunning;
      });
    })
    .catch(() => {
      // queue.__db.elements.toArray().then(elements =>
      //   ErrorLogger.captureEvent('Failed to send batch', 'error', {
      //     err,
      //     elements: JSON.stringify(elements)
      //   })
      // );

      recurseLater();
    });
};

let currentNumTries = 0;
const sendBatchOfInteractions = async (batch: InteractionElement[], token: Token) => {
  // Both processSuccess and processError will update the queue
  // and reschedule a future poll of the queue.
  try {
    const succeededInteractions = await sendOne(batch, token);
    processSuccess(batch, succeededInteractions);
  } catch (err) {
    // ErrorLogger.captureEvent('Failed to send batch of interactions', 'error', {
    //   err,
    //   batch: JSON.stringify(batch)
    // });
    processError(err, batch);
  }
};

const processSuccess = async (batch: InteractionElement[], succeededElements: string[]) => {
  // This means the call to the server succeeded
  // Any interactions that are not in succeededElements
  // failed for some reason on the server, so they must be
  // retried using the handleInteractionServerIssue logic.
  currentNumTries = 0;
  const removePromises: Array<Promise<void>> = succeededElements.map(queue.remove);
  const triedElements = batch.map(element => element.id);
  const failedElements = _.difference(triedElements, succeededElements);
  const handleFailedElementPromises = failedElements.map(elementId => {
    const msg = `Succeeded but failed to sync all interactions? 
      Tried: ${triedElements.map(el => `${el}, `)}
      Succeeded: ${succeededElements.map(el => `${el}, `)}
    `;
    const err: InteractionBatchRequestError = {
      status: 0,
      readyState: 0,
      response: msg,
      responseText: msg,
      statusText: msg
    };
    ErrorLogger.captureEvent('Failed to sync all interactions', 'error', {
      err,
      msg,
      succeededElements,
      triedElements
    });
    return handleInteractionServerIssue(elementId, err);
  });

  try {
    return await Promise.all(removePromises.concat(handleFailedElementPromises));
  } catch (err) {
    ErrorLogger.captureEvent('Failed to remove elements from queue', 'error', {
      err,
      succeededElements,
      triedElements
    });
    throw err;
  } finally {
    startIfAnythingToSend();
    if (_.some(batch, element => affectsSyncData(element.toPost))) {
      pollForImportantInteractions();
    }
  }
};

const processError = async (err: InteractionBatchRequestError, batch: InteractionElement[]) => {
  if (isServerError(err.status)) {
    // Case where server responded, but there was something
    // wrong with the request or the server encountered a bug.
    const updatePromises = batch.map(el => {
      const elementId = el.id;
      return handleInteractionServerIssue(elementId, err);
    });
    try {
      await Promise.all(updatePromises);
    } catch (err) {
      ErrorLogger.captureException(err);
      throw err;
    } finally {
      startIfAnythingToSend();
    }
  } else {
    // Timeout case: wait a bit and try again, gradually backing off
    // to a max of RETRY_MAX.
    const retryInterval = Math.pow(RETRY_BASE, currentNumTries) * RETRY_DELAY;
    const releasePromises = batch.map(element => queue.release(element.id));
    try {
      await Promise.all(releasePromises);
    } catch (err) {
      ErrorLogger.captureException(err);
      throw err;
    } finally {
      setTimeout(startIfAnythingToSend, retryInterval);
    }

    console.error(`failed to send, waiting ${retryInterval / 1000.0} seconds`);
    if (retryInterval < RETRY_MAX) {
      ++currentNumTries;
    }
    console.error('failed to send, err is: ', err);

    const app = UserSelectors.getApplicationId(window.__store.getState());

    const extra = {
      interactionIds: batch.map(element => element.id),
      currentNumTries,
      errorCode: err.status,
      xhrReadyState: err.readyState,
      responseText: err.responseText,
      application: app
    };
    return ErrorLogger.captureEvent('timed out or failed to send batch', 'warning', { ...extra });
  }
};

const incrementServerErrors = (element: InteractionElement) => {
  return _.extend({}, element, { serverErrors: element.serverErrors + 1 });
};

const handleInteractionServerIssue = async (
  elementId: string,
  err: InteractionBatchRequestError
): Promise<void> => {
  const element = await queue.updateElement(elementId, incrementServerErrors);
  if (!element) {
    return; // tried to update interaction that doens't exist anymore?
  }

  if (element.serverErrors >= MAX_SERVER_ERRORS) {
    // Time to give up on this interaction, log it and drop it from the queue
    return dropInteractionAndReportIssue(element, err);
  } else {
    // Not found/Internal server error: move it to the back of the queue,
    // but keep track of the number of failures so we can give up after a few
    // tries so they don't clog the queue forever.
    await queue.release(element.id);
    await queue.reducePriority(element.id);
  }
};

const dropInteractionAndReportIssue = (
  element: InteractionElement,
  err: InteractionBatchRequestError
) => {
  const UNAUTHORIZED_STATUS_CODE = 401;

  const extra = {
    category: 'synchronisation',
    issue: 'interaction-triggered-server-error',
    clientId: element.id,
    userId: element.userId,
    applicationId: element.applicationId,
    toPost: element.toPost,
    data: element,
    element: JSON.stringify(element),
    errorStatus: err.status,
    errorStatusText: err.statusText,
    errorResponse: err.response,
    errorResponseText: err.responseText
  };

  // It's ok to drop this interaction - because your token is invalid and not accepted
  if (err.status === UNAUTHORIZED_STATUS_CODE) {
    ErrorLogger.captureEvent('dropping unauthorised interaction', 'warning', extra);
  } else {
    ErrorLogger.captureEvent(
      'dropping interaction due to too many server errors',
      'critical',
      extra
    );
  }

  return queue.remove(element.id);
};

const waitForQueueEmpty = ({ timeout, minimum }: { timeout: number; minimum: number }) => {
  const t0 = Date.now();
  return new Promise<void>((resolve, reject) => {
    const pollQueue: () => void | PromiseExtended<
      number | void | ReturnType<typeof setTimeout>
    > = () => {
      const t1 = Date.now();
      if (t1 - t0 > timeout) {
        const seconds = timeout / 1000.0;
        return reject(
          `More than ${seconds} seconds have passed, stopping interactions queue polling now`
        );
      }

      return queue
        .hasAny(() => true, getIdentity())
        .catch(err => {
          ErrorLogger.captureEvent('Wait for queue empty failed', 'error', { err });
          pollQueue();
        })
        .then(hasAny => {
          if (hasAny) {
            return setTimeout(pollQueue, QUEUE_WAIT_POLL_INTERVAL);
          } else {
            return resolve();
          }
        });
    };

    return setTimeout(pollQueue, minimum);
  });
};

// Do not allow user to close browser if important interactions
// are enqueued. We update this variable every 20 sec, after
// enqueuing an interaction, and after sending an interaction.
// Specifically we are looking for interactions that
let _importantInteractionsEnqueued = false;
let importantInteractionsTimeout = setTimeout(() => null, 1);
const pollForImportantInteractions = () => {
  const recurseLater = (): NodeJS.Timeout =>
    (importantInteractionsTimeout = setTimeout(
      pollForImportantInteractions,
      POLL_IMPORTANT_INTERVAL
    ));

  clearTimeout(importantInteractionsTimeout);
  const important = (el: InteractionElement) => affectsSyncData(el.toPost);

  let identity: UserIdentification;
  try {
    identity = getIdentity();
  } catch {
    // The store might not have been created yet.
    recurseLater();
    return;
  }

  return queue
    .hasAny(important, identity)
    .then(hasImportant => (_importantInteractionsEnqueued = hasImportant))
    .catch(() => (_importantInteractionsEnqueued = false))
    .finally(recurseLater);
};

const requestInProgress = (e: Event) => {
  e = e || window.event;
  if (_importantInteractionsEnqueued && Platform.isWeb()) {
    const val = t('prompts.request-in-progress', { ns: 'learners-experience' });
    // Displays a dialog box with message in IE11, just a dialog box in Chrome
    if (e) {
      e.returnValue = !!val;
    }
    return val;
  } else {
    return;
  }
};

window.canRejectionReload = () => {
  if (window.reloading) {
    return false;
  }
  const lastReload = window.localStorage.getItem('onunhandledrejection_reload');
  if (lastReload == null) {
    return true;
  }

  // 5mins
  const MINIMUM_RELOAD_INTERVAL = 300000;
  // tslint:disable-next-line: radix
  return parseInt(lastReload) + MINIMUM_RELOAD_INTERVAL < new Date().getTime();
};

const unhandledPromiseRejection = (event: PromiseRejectionEvent) => {
  event.preventDefault();
  const { reason } = event;
  if ((reason != null ? reason.name : undefined) || (reason != null ? reason.message : undefined)) {
    console.error(
      'Unhandled promise rejection:',
      reason != null ? reason.name : undefined,
      reason != null ? reason.message : undefined
    );
  }

  const isIndexedDBRejection = (evt: PromiseRejectionEvent) => {
    const evtReason = evt.reason;
    if (!evtReason) {
      return false;
    }

    return (
      (!!evtReason.name && evtReason.name.indexOf('IDBTransaction') >= 0) ||
      (!!evtReason.message && evtReason.message.indexOf('IDBTransaction') > 0) ||
      (!!evtReason.message && evtReason.message.indexOf('Indexed Database server') > 0)
    );
  };

  console.error(
    'Unhandled promise rejection:',
    (reason != null ? reason.stack : undefined) != null
      ? reason != null
        ? reason.stack
        : undefined
      : reason
  );
  if (isIndexedDBRejection(event) && window.canRejectionReload()) {
    console.error('Reloading the webview to re-initialise the connection to the db');
    window.reloading = true;
    window.localStorage.setItem('onunhandledrejection_reload', new Date().getTime() + '');
    return window.location.reload();
  }
};

// Some telemetry for really huge interaction queues
const checkForHugeInteractionQueue = () =>
  queue.count().then(num => {
    if (num > MAX_COMFORTABLE_QUEUE_LENGTH) {
      return ErrorLogger.captureEvent(
        'detected queue longer than max comfortable length',
        'error',
        {
          number: num
        }
      );
    }
  });

const removeInteraction = (id: string) => {
  return queue.remove(id);
};

/**
 * This function is called when the app starts up, and it will make sure the interactions keep being polled
 */
const startupInteractionsApi = () => {
  startIfAnythingToSend();
  pollForImportantInteractions();
  checkForHugeInteractionQueue();
};

export const InteractionApi = {
  enqueueInteraction,
  removeInteraction,
  requestInProgress,
  unhandledPromiseRejection,
  waitForQueueEmpty,
  startupInteractionsApi
};
