import { isEmpty, uniqBy } from 'lodash-es';

import { ENV } from '@maggie/config/env';
import type { ThomasSlideInteraction } from '@maggie/core/lessons/thomas/thomas-interaction-types';
import { ConfigSelectors } from '@maggie/store/config/selectors';
import type { LxStoreState } from '@maggie/store/types';

import { LessonSelectors } from '../lessons/selectors';
import type { StyleType, SummaryListItem } from '../types';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_BRANDING_TEXT_COLOR } from '../utils';
import type { RapidRefreshType } from './types';

const getRapidRefreshStatus = (state: LxStoreState) => {
  return {
    loading: state.courseware.rapidRefreshes.loading,
    error: state.courseware.rapidRefreshes.error,
    errorCode: state.courseware.rapidRefreshes.errorCode
  };
};

const getRapidRefreshErrorCode = (state: LxStoreState) => {
  return getRapidRefreshStatus(state).errorCode;
};

const getRapidRefreshItem = (courseId: string, session: number) => (state: LxStoreState) => {
  return state.courseware.rapidRefreshes.items.find(
    rr => rr.courseId === courseId && rr.currentSession === session
  );
};

const getRapidRefreshItems = (state: LxStoreState) => {
  const rapidRefreshItems = ConfigSelectors.isOnline(state)
    ? state.courseware.rapidRefreshes.items
    : [];
  return rapidRefreshItems;
};

const getSummaryDialogOpen = (state: LxStoreState) => {
  return state.courseware.rapidRefreshes.summaryDialogOpen;
};

const getRapidRefreshScore = (state: LxStoreState) => {
  return state.courseware.rapidRefreshes.currentScore;
};

const getCorrectAnswersFromInteractions = (state: LxStoreState) => {
  return state.courseware.rapidRefreshes.currentInteractions.filter(
    (i: ThomasSlideInteraction) => !!i.correct
  ).length;
};

const getInteractionsForSummaryList = (state: LxStoreState): SummaryListItem[] => {
  const interactions = state.courseware.rapidRefreshes.currentInteractions;

  const interactiveSlides = interactions.filter(
    (i: ThomasSlideInteraction) => i.slideData && !isEmpty(i.answerDetails)
  );

  return uniqBy(interactiveSlides, 'id').map<SummaryListItem>((i: ThomasSlideInteraction) => {
    return LessonSelectors.getInteractionhSummaryItem(i);
  });
};

const isRapidRefreshLocked = (courseId: string) => (state: LxStoreState) => {
  // this shall be changed in very soon
  return !state.courseware.courses.coursesProgress[courseId]?.unlocked;
};

const getStyleObject = (rapidRefresh: RapidRefreshType): StyleType => {
  const styleConfig = rapidRefresh.session.styleConfiguration;
  const colors = styleConfig.colors;
  return {
    text: colors?.text || rapidRefresh.brandingTextColor || DEFAULT_BRANDING_TEXT_COLOR,
    background: {
      color: colors?.background || DEFAULT_BACKGROUND_COLOR,
      image: `url("${ENV.imageUrl(styleConfig.background || rapidRefresh.brandingImage)}")`
    }
  };
};

const getRapidRefreshesCount = (state: LxStoreState) => {
  return getRapidRefreshItems(state).length;
};

const getRapidRefreshBackground = (rapidRefresh: RapidRefreshType) => {
  const styleConfig = rapidRefresh.session.styleConfiguration;
  return ENV.imageUrl(styleConfig.background || rapidRefresh.brandingImage);
};

export const RapidRefreshSelectors = {
  getRapidRefreshItems,
  getRapidRefreshItem,
  getRapidRefreshStatus,
  getSummaryDialogOpen,
  getRapidRefreshScore,
  getCorrectAnswersFromInteractions,
  getInteractionsForSummaryList,
  isRapidRefreshLocked,
  getStyleObject,
  getRapidRefreshErrorCode,
  getRapidRefreshesCount,
  getRapidRefreshBackground
};
