import * as React from 'react';
import { Image } from '@edapp/ed-components';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { MainToolbarContext } from './MainToolbarContext';

type Props = {
  /**
   * The src of image to be rendered
   */
  imgSrc: string;

  /**
   * The maximum height the image will display
   */
  imgHeight: number;

  /**
   * The minimum height the image will display
   * When it reaches this size, it goes blurry
   */
  imgMinHeight: number;

  /**
   * Optional background-color in case there is no image available
   */
  backgroundColor?: string;

  /**
   * Content to display when isSticky in the header
   *
   * !Note: remember to style the content with
   * ! z-index: 12;
   * ! position: sticky;
   * ! top: 0;
   */
  headerContent?: React.ReactNode;

  /**
   * Optional callback to listen to scroll events.
   */
  onScroll?: (e: React.UIEvent) => void;
};

export const StickyHeader: React.FC<React.PropsWithChildren<Props>> = ({
  imgSrc,
  children,
  imgHeight,
  imgMinHeight,
  headerContent,
  backgroundColor,
  onScroll
}) => {
  const [top, setTop] = React.useState(0);
  const { setScrollTop } = React.useContext(MainToolbarContext);

  const safeTop = React.useMemo(
    () => parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--sat')),
    [top]
  );
  const MIN_SIZE = imgMinHeight - imgHeight + safeTop;

  const handleScroll = (e: React.UIEvent) => {
    const scrollTop = (e.target as HTMLDivElement).scrollTop;
    const top = Math.max(Math.min(-scrollTop, 0), MIN_SIZE);
    setScrollTop(scrollTop);
    setTop(top);
    onScroll?.(e);
  };

  const isSticky = top <= MIN_SIZE;

  return (
    <Scroller data-testid="sticky-header-scroller" onScroll={handleScroll}>
      {/* Header */}
      <Sticky
        data-testid="sticky-header"
        backgroundColor={backgroundColor}
        height={imgHeight}
        style={{ top }}
      >
        <CoverImage data-testid="cover-image" src={imgSrc} height={imgHeight} />

        <AnimatePresence>
          {isSticky && (
            <BackdropFilter
              height={imgHeight}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          )}
        </AnimatePresence>

        {headerContent}
      </Sticky>

      {/* Body */}
      {children}
    </Scroller>
  );
};

const Scroller = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  display: flex;
  flex-direction: column;

  .is-ios & {
    box-sizing: border-box;
    & > :last-child {
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    }
  }
`;

type StickyProps = { height: number; backgroundColor?: string };
const Sticky = styled(motion.div)<StickyProps>(
  ({ height, backgroundColor }) => css`
    height: ${height}px;
    width: 100%;
    flex-shrink: 0;

    position: sticky;

    z-index: 1000;

    ${backgroundColor && `background-color: ${backgroundColor};`}
  `
);

const CoverImage = styled(Image)<{ height: number }>(
  ({ height }) => css`
    height: ${height}px;
    width: 100%;
    object-fit: cover;

    position: absolute;
    inset: 0;
    z-index: 10;
  `
);

const BackdropFilter = styled(motion.div)<{ height: number }>(
  ({ height }) => css`
    height: ${height}px;
    width: 100%;

    position: absolute;
    inset: 0;
    backdrop-filter: blur(42px);
    z-index: 11;
  `
);
