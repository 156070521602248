import { ENV } from '@maggie/config/env';
import { optimizeCloudinaryImageURL } from '@maggie/core/optimize';

type AssetEntityType = 'courses' | 'lessons' | 'collections' | 'playlists';

export class Asset {
  private forEntity: AssetEntityType;
  private entityId: string;

  public fileName: string;
  public remoteURL: string;
  public folderPath: string;
  public filePath: string;

  constructor(url: string | undefined | null, forEntity: AssetEntityType, entityId: string) {
    if (!url) {
      url = ''; // add a fallback value
    }

    this.forEntity = forEntity;
    this.entityId = entityId;
    this.fileName = url.match(/[^\/]+$/)?.[0] || '';
    this.remoteURL = this.isCloudinary(url) ? optimizeCloudinaryImageURL(url) : url;
    this.folderPath = `downloads/${this.forEntity}/${this.entityId}`;
    this.filePath = window.__scWebview.platform ? url : `${this.folderPath}/${this.fileName}`;
  }

  private isCloudinary = (url: string) => {
    return url.includes(ENV.CLOUDINARY_URL) || url.includes(ENV.CLOUDINARY_CDN_URL);
  };
}

const imageTypes = ['png', 'jpg', 'jpeg', 'gif', 'svg'];
const videoTypes = ['mp4', 'mpeg', 'm4v'];
const audioTypes = ['wav', 'mp3'];
const allowedTypes = [...imageTypes, ...videoTypes, ...audioTypes];
const assetRegex = new RegExp(`^[^\|]*(${allowedTypes.join('|')})$`, 'i'); // Doesnt contain |
export const isAsset = (asset: string) => typeof asset === 'string' && !!asset.match(assetRegex);

const defaultPath = ['img/', 'audio/', 'video/'];
const defaultRegex = new RegExp(`(${defaultPath.join('|')})`, 'i');
const isDefault = (asset: string) => {
  if (asset.indexOf('http') >= 0) {
    return false;
  }
  return typeof asset === 'string' && !!asset.match(defaultRegex);
};
export const isDefaultAsset = (asset: string) => isAsset(asset) && isDefault(asset);

const scormRegex = /^\/external-packages/;
export const isScorm = (url: string) => typeof url === 'string' && !!url.match(scormRegex);
