// Check online status using network-information plugin
import { Platform } from './platform';

/**
 * @returns `true` if not device, or the online status
 */
export const checkDeviceOnline = () => {
  if (!!window.__scWebview.platform) {
    return window.__scWebview.isOnline;
  }

  if (Platform.isCordova()) {
    return checkOnline();
  }

  return true; // not device - web is always online
};

export const checkOnline = () => {
  if (navigator.connection == null || window.Connection == null) {
    return navigator.onLine;
  }

  const currentNetworkState = navigator.connection.type;

  const states = {};
  states[Connection.UNKNOWN] = false;
  states[Connection.NONE] = false;
  states[Connection.ETHERNET] = true;
  states[Connection.WIFI] = true;
  // In iOS, all cellular connection defaults to Connection.CELL
  states[Connection.CELL] = true;
  states[Connection.CELL_2G] = true;
  states[Connection.CELL_3G] = true;
  states[Connection.CELL_4G] = true;

  return !!states[currentNetworkState];
};

export const isWifi = () => {
  if (navigator.connection == null || window.Connection == null) {
    return navigator.onLine;
  }
  const currentNetworkState = navigator.connection.type;
  return currentNetworkState === Connection.WIFI || currentNetworkState === Connection.ETHERNET;
};

export const isData = () => {
  if (navigator.connection == null || window.Connection == null) {
    return navigator.onLine;
  }
  const currentNetworkState = navigator.connection.type;
  return (
    currentNetworkState === Connection.CELL ||
    currentNetworkState === Connection.CELL_2G ||
    currentNetworkState === Connection.CELL_3G ||
    currentNetworkState === Connection.CELL_4G
  );
};

export const getConnectionType = () => {
  if (navigator.connection == null || window.Connection == null) {
    return '';
  }
  const currentNetworkState = navigator.connection.type;
  return !!isData() ? currentNetworkState : Connection.WIFI;
};
