import * as React from 'react';

import { useTheme } from 'styled-components';

import { ToolbarBehaviour } from '@maggie/core/router/types';
import { StickyHeader } from '@maggie/layout/view/route-split-view-layout/split-view-main/main-toolbar/StickyHeader';

import { useSplitViewContext } from '../../split-view-context/useSplitViewContext';
import { MainToolbarContent } from './MainToolbarContent';
import { MainToolbarContextProvider } from './MainToolbarContext';

const COVER_IMAGE_HEIGHT = 200;

export const MainToolbar: React.FC<React.PropsWithChildren<{}>> = React.memo(({ children }) => {
  const context = useSplitViewContext();
  const theme = useTheme();

  if (context.toolbarBehaviour === ToolbarBehaviour.none) {
    return <MainToolbarContextProvider>{children}</MainToolbarContextProvider>;
  }

  if (context.toolbarBehaviour === ToolbarBehaviour.fixed) {
    return (
      <MainToolbarContextProvider>
        <MainToolbarContent
          title={context.title}
          textColor={context.toolbarTextColor}
          backgroundColor={context.toolbarBackgroundColor}
          toolbarBehaviour={context.toolbarBehaviour}
          backButtonStyles={context.backButtonStyles}
        />
        {children}
      </MainToolbarContextProvider>
    );
  }

  if (context.toolbarBehaviour === ToolbarBehaviour.transparent) {
    return (
      <MainToolbarContextProvider>
        <MainToolbarContent
          title={context.title}
          textColor={context.toolbarTextColor}
          backgroundColor={context.toolbarBackgroundColor}
          toolbarBehaviour={context.toolbarBehaviour}
          backButtonStyles={context.backButtonStyles}
        />
        {children}
      </MainToolbarContextProvider>
    );
  }

  if (context.toolbarBehaviour === ToolbarBehaviour.sticky) {
    return (
      <MainToolbarContextProvider>
        <StickyHeader
          backgroundColor={context.toolbarBackgroundColor}
          headerContent={
            <MainToolbarContent
              title={context.title}
              textColor={context.toolbarTextColor}
              backgroundColor={context.toolbarBackgroundColor}
              toolbarBehaviour={context.toolbarBehaviour}
              backButtonStyles={context.backButtonStyles}
            />
          }
          imgSrc={context.toolbarBackground}
          imgHeight={COVER_IMAGE_HEIGHT}
          imgMinHeight={theme.toolbarHeight}
        >
          {children}
        </StickyHeader>
      </MainToolbarContextProvider>
    );
  }

  throw Error(`unknown type of toolbar behaviour ${context.toolbarBehaviour}`);
});
