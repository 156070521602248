import * as React from 'react';
import styled, { css } from 'styled-components';
import { Box, Typography } from '@edapp/ed-components';
import type { StyleConfiguration } from '@edapp/authoring-logic';

type SubmissionCardHeaderProps = {
  name: string;
  courseName: string;
  styleConfiguration?: StyleConfiguration;
};

export const SubmissionCardHeader: React.FC<SubmissionCardHeaderProps> = ({
  name,
  courseName,
  styleConfiguration
}: SubmissionCardHeaderProps) => {
  return (
    <HeaderBox flex flexDirection="row" alignItems="center">
      <ImageBox styleConfiguration={styleConfiguration} />
      <Box flex flexDirection="column" textAlign="left">
        <Typography variant="subtitle1" color="text" textTransform="none">
          {name}
        </Typography>
        <Typography variant="small" color="textMuted" textTransform="none">
          {courseName}
        </Typography>
      </Box>
    </HeaderBox>
  );
};

// If background exists, then make image the background, otherwise make it the colors background
const ImageBox = styled(Box)<{ styleConfiguration?: StyleConfiguration }>`
  width: 80px;
  height: 56px;
  background-size: cover;
  background-position: center;
  border-radius: ${({ theme }) => theme.sizes.borderRadius}px;
  margin-right: ${({ theme }) => theme.space(2)}px;
  ${({ styleConfiguration, theme }) =>
    !!styleConfiguration?.background
      ? css`
          background-image: url("${styleConfiguration.background}");
        `
      : css`
          background-color: ${styleConfiguration?.colors?.background || theme.colors.lightGrey};
        `}
`;

const HeaderBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding: ${({ theme }) => theme.space(2)}px;
`;
