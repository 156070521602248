import type {
  QuizPlayInteraction,
  SlideInteraction,
  ViewInteraction
} from '@maggie/core/tracker/hippo-interaction-types';
import { TrackerInteractions } from '@maggie/core/tracker/tracker-interactions';
import { BrainBoostActions } from '@maggie/store/brain-boost/actions';
import type { SlideType } from '@maggie/store/courseware/lessons/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import type {
  ThomasSlideInteraction,
  ThomasViewInteraction
} from '../thomas/thomas-interaction-types';
import type { NextSlidePayload } from '../thomas/types';
import { slidedeckPrefix } from '../thomas/utils';
import { Attempt } from './attempt';

export class BrainboostAttempt extends Attempt {
  private id: string;

  constructor(id: string, slides: SlideType[]) {
    super(slides);
    this.id = id;
  }

  private onNextSlide = (data: NextSlidePayload) => {
    if (data?.slideIndex !== undefined) {
      window.__store.dispatch(BrainBoostActions.hideToast());
    }
  };

  private getInteractedSlides() {
    return this.interactions.filter(i => (i as ThomasSlideInteraction).answerDetails?.length > 0);
  }

  private isBrainboostSlideInteraction(interaction: ThomasSlideInteraction) {
    return interaction.id && interaction.course_id && interaction.lesson_id;
  }

  protected recordView = (view: ThomasViewInteraction) => {
    super.recordView(view);

    const interaction: ViewInteraction = {
      ...view,
      type: 'view',
      lesson_title: 'Brainboost',
      already_completed: false,
      attempt: this._attemptId,
      quiz_id: this.id
    };
    TrackerInteractions.trackViewWithDuration(interaction);
  };

  protected recordInteraction(interaction: ThomasSlideInteraction) {
    super.recordInteraction(interaction);

    // Give user feedback
    if (this.isBrainboostSlideInteraction(interaction)) {
      window.__store.dispatch(BrainBoostActions.showToast(!!interaction.correct));
      window.__store.dispatch(
        BrainBoostActions.brainBoostSlideComplete({
          slideId: (interaction as any).slide_id,
          lessonId: interaction.lesson_id,
          courseId: interaction.course_id
        })
      );
    }

    // Save info
    const userExternalId = UserSelectors.getExternalIdentifier(window.__store.getState().user);
    const slideInteraction: ThomasSlideInteraction & SlideInteraction = {
      ...interaction,
      type: 'slide',
      quiz_id: this.id,
      attempt: this._attemptId,
      user_external_identifier: userExternalId
    };
    TrackerInteractions.trackSlideInteraction(slideInteraction);
  }

  protected calculateScore() {
    super.calculateScore();

    const interactedSlides = this.getInteractedSlides();
    if (interactedSlides.length > 0) {
      const correctSlides = interactedSlides.filter(i => (i as ThomasSlideInteraction).correct);
      return correctSlides.length / interactedSlides.length;
    }

    return 0;
  }

  public startRecording(id: string) {
    super.startRecording(id);

    const prefix = slidedeckPrefix(id);
    this._eventManager.listenTo(Backbone.Events, `${prefix}:next-slide`, this.onNextSlide);
  }

  public finishAttempt(): void {
    super.finishAttempt();

    if (this._score > 0) {
      const interactedSlides = this.getInteractedSlides();
      window.__store.dispatch(
        BrainBoostActions.scoreQuizPlay({
          score: this._score,
          totalSlides: interactedSlides.length
        })
      );
    }

    const interaction: QuizPlayInteraction = {
      type: 'quiz-play',
      startedTimestamp: this._startedAt,
      endedTimestamp: this._endedAt,
      score: this._score,
      quizId: this.id
    };
    TrackerInteractions.trackQuizPlayInteraction(interaction);
  }

  public stopRecording(id: string) {
    super.stopRecording(id);

    const prefix = slidedeckPrefix(id);
    this._eventManager.stopListening(Backbone.Events, `${prefix}:next-slide`);
  }
}
