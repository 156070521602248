import { isBefore, isEqual } from 'date-fns';

import type { CourseSummaryType } from '@maggie/store/courseware/collections/types';
import { MandatoryTypes } from '@maggie/store/courseware/courses/constants';
import type { PlaylistSummaryType } from '@maggie/store/courseware/playlists/types';

/**
 * It is expected to return
 *  * a `negative value` if first argument is less than second argument [c1, c2]
 *  * `zero` if they're equal [c1c2]
 *  * a `positive value` otherwise [c2, c1]
 */
type CriteriaSortFn = (course1: CourseSummaryType, course2: CourseSummaryType) => number;

export class UpNext {
  playlists: PlaylistSummaryType[];
  courses: CourseSummaryType[];
  coursesByPlaylist: Record<string, PlaylistSummaryType>;
  coursesAvailability: Record<string, boolean>;

  constructor(
    playlists: PlaylistSummaryType[],
    courses: CourseSummaryType[],
    coursesAvailability: Record<string, boolean> = {}
  ) {
    this.playlists = playlists;
    this.courses = courses;
    this.coursesAvailability = coursesAvailability;

    this.coursesByPlaylist = courses.reduce((res, item) => {
      const playlist = playlists.find(p => p.courseIds.indexOf(item.courseId) >= 0);
      if (!!playlist) {
        res[item.courseId] = playlist;
      }
      return res;
    }, {});
  }

  private sortByAvailability: CriteriaSortFn = (c1, c2) => {
    const c1Availability = this.coursesAvailability[c1.courseId];
    const c2Availability = this.coursesAvailability[c2.courseId];

    if (c1Availability === c2Availability) {
      // if both have same availability, return 0
      return 0;
    }

    // if one is available and other isn't, return the one available first
    return c1Availability ? -1 : 1;
  };

  private sortByMandatoryDueDate: CriteriaSortFn = (c1, c2) => {
    if (!c1.mandatory.enabled && !c2.mandatory.enabled) {
      // if both are not mandatory, return 0
      return 0;
    }

    if (c1.mandatory.enabled !== c2.mandatory.enabled) {
      // if one is not mandatory and other is, return the mandatory one first
      return c1.mandatory.enabled ? -1 : 1;
    }

    if (c1.mandatory.type !== c2.mandatory.type) {
      // if one is due by and other isn't, it should come first
      return c1.mandatory.type === MandatoryTypes.DueBy ? -1 : 1;
    }

    if (c1.mandatory.type === MandatoryTypes.DueBy && c2.mandatory.type === MandatoryTypes.DueBy) {
      if (isEqual(new Date(c1.mandatory.date), new Date(c2.mandatory.date))) {
        // if same due date, return 0
        return 0;
      }

      // if one is due before other, return the one due first
      return isBefore(new Date(c1.mandatory.date), new Date(c2.mandatory.date)) ? -1 : 1;
    }

    // if none of the above conditions are met
    return 0;
  };

  private sortByPlaylistCourseOrder: CriteriaSortFn = (c1, c2) => {
    const playlist1 = this.coursesByPlaylist[c1.courseId];
    const playlist2 = this.coursesByPlaylist[c2.courseId];
    if (!playlist1 && !playlist2) {
      // if both are not in any playlist, return 0
      return 0;
    }

    if (!playlist1 || !playlist2) {
      // if one is in playlist and other isn't, return the one in playlist first
      return playlist1 ? -1 : 1;
    }

    if (playlist1.id !== playlist2.id) {
      // if in different playlists, return the one in the first playlist
      return playlist1.lexoRank.localeCompare(playlist2.lexoRank);
    }

    // if in same playlist, return the one that comes first in the playlist
    const c1Index = playlist1.courseIds.indexOf(c1.courseId);
    const c2Index = playlist1.courseIds.indexOf(c2.courseId);
    return c1Index < c2Index ? -1 : 1;
  };

  private sortByPublishedDate: CriteriaSortFn = (c1, c2) => {
    if (!c1.publishedAt && !c2.publishedAt) {
      // if both don't have published date, return 0
      return 0;
    }

    if (!c1.publishedAt || !c2.publishedAt) {
      // if one has published date and other doesn't, return the one with published date first
      return c1.publishedAt ? -1 : 1;
    }

    if (isEqual(new Date(c1.publishedAt), new Date(c2.publishedAt))) {
      // if both have same published date, return 0
      return 0;
    }

    // if one is published before other, return the one published most recently first
    return isBefore(new Date(c1.publishedAt), new Date(c2.publishedAt)) ? 1 : -1;
  };

  public apply(): CourseSummaryType[] {
    if (this.courses.length === 0) {
      return [];
    }

    const sortFns = [
      this.sortByAvailability,
      this.sortByMandatoryDueDate,
      this.sortByPlaylistCourseOrder,
      this.sortByPublishedDate
    ];
    const courses = [...this.courses]; // copy array to avoid mutation
    courses.sort((x, y) => {
      for (const sortFn of sortFns) {
        const result = sortFn(x, y);
        if (result !== 0) {
          return result;
        }
      }
      return 0;
    });

    return courses;
  }
}
