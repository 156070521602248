import { fork, put, select, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import { CourseActions } from '@maggie/store/courseware/courses/actions';
import { LessonActions } from '@maggie/store/courseware/lessons/actions';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import type { CourseType } from '../courses/types';
import type { ConferenceActionsUnionType } from './actions';
import { ConferenceActionTypes } from './actions';
import type { ConferenceType, UpcomingConference } from './types';

export type ConferenceAction<A extends string> = ActionFromActionType<
  ConferenceActionsUnionType,
  A
>;

function* handleFetchUpcomingConferences() {
  const userToken: ReturnType<typeof UserSelectors.getToken> = yield select(UserSelectors.getToken);

  if (!userToken) {
    return;
  }

  yield put(
    RequestActions.getAuthed<UpcomingConference>(
      Urls.UPCOMING_CONFERENCES,
      ConferenceActionTypes.FETCH_UPCOMING_CONFERENCES_SUCCESS,
      ConferenceActionTypes.FETCH_UPCOMING_CONFERENCES_FAILURE
    )
  );
}

function* handleFetchUpcomingConferencesSuccess(
  action: ConferenceAction<ConferenceActionTypes.FETCH_UPCOMING_CONFERENCES_SUCCESS>
) {
  const { items: upcomingConferences } = action.payload;

  const conferenceIds = upcomingConferences.map(conference => conference.id);

  yield put(LessonActions.fetchLessonsProgress(conferenceIds));
}

function* handleFetchConference(action: ConferenceAction<ConferenceActionTypes.FETCH_CONFERENCE>) {
  const { id } = action.payload;

  yield put(
    RequestActions.getAuthed<ConferenceType>(
      Urls.CONFERENCE(id),
      ConferenceActionTypes.FETCH_CONFERENCE_SUCCESS,
      ConferenceActionTypes.FETCH_CONFERENCE_FAILURE
    )
  );
}

// We must fetch the course to display its branding for the conference
function* handleFetchConferenceSuccess(
  action: ConferenceAction<ConferenceActionTypes.FETCH_CONFERENCE_SUCCESS>
) {
  const { payload: conference } = action;

  const parentCourse: CourseType | undefined = yield select(
    (state: LxStoreState) => state.courseware.courses.courses[conference.courseId]
  );

  if (!parentCourse) {
    yield put(CourseActions.fetchSyncCourse(conference.courseId));
  }
}

function* watchFetchConference() {
  yield takeLatest(ConferenceActionTypes.FETCH_CONFERENCE, handleFetchConference);
}

function* watchFetchConferenceSuccess() {
  yield takeLatest(ConferenceActionTypes.FETCH_CONFERENCE_SUCCESS, handleFetchConferenceSuccess);
}

function* watchFetchUpcomingConferencesSuccess() {
  yield takeLatest(
    ConferenceActionTypes.FETCH_UPCOMING_CONFERENCES_SUCCESS,
    handleFetchUpcomingConferencesSuccess
  );
}

function* watchFetchUpcomingConferences() {
  yield takeLatest(
    ConferenceActionTypes.FETCH_UPCOMING_CONFERENCES,
    handleFetchUpcomingConferences
  );
}

export const conferenceSagas = [
  fork(watchFetchUpcomingConferencesSuccess),
  fork(watchFetchConference),
  fork(watchFetchConferenceSuccess),
  fork(watchFetchUpcomingConferences)
];
