import * as React from 'react';

import type { ViewHeadType } from '@maggie/core/router/types';

type ViewContextType = {
  title: string;
  viewHeadType: ViewHeadType | { mobile: ViewHeadType; desktop: ViewHeadType };
  viewHeadTextColor: string;
  isViewHeadSubtle: boolean;
  setIsViewHeadSubtle: (subtle: boolean) => void;
};

export const ViewContext = React.createContext<ViewContextType>({
  title: '',
  viewHeadType: 'fixed',
  viewHeadTextColor: 'white',
  isViewHeadSubtle: false,
  setIsViewHeadSubtle: () => ({})
});
