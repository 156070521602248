import * as _ from 'lodash';

import type {
  AnswersValue,
  DemographicQuestionType
} from '@maggie/store/learner-demographic/types';

import type { FieldValueType, FieldsValueStateType } from './types';

const MAX_CHAR_TEXT_AREA = 200;

export const changeValue = (state: FieldsValueStateType, name: string, value: FieldValueType) => {
  return Object.keys(state).reduce((acc, fieldName) => {
    if (fieldName === name) {
      acc[fieldName] = value;
      return acc;
    }

    const field = state[fieldName];
    switch (field.type) {
      case 'radio':
        field.value = false;
        break;
      case 'radio-text-area':
        field.checked = false;
        field.value = '';
        break;
      case 'text-area':
      default:
        // text areas are not supported in this type of question - only `FreeText`
        break;
    }

    acc[fieldName] = { ...field };
    return acc;
  }, {} as FieldsValueStateType);
};

export const getAnswersValue = (fields: FieldsValueStateType): AnswersValue[] => {
  return Object.keys(fields).reduce((acc, fieldName) => {
    const field = fields[fieldName];
    if (!!field.value) {
      acc.push({
        id: fieldName,
        freetext: typeof field.value === 'string' ? field.value : undefined
      });
    }
    return acc;
  }, [] as AnswersValue[]);
};

export const getFieldsValue = (
  question: DemographicQuestionType | undefined,
  initialValues = {}
): FieldsValueStateType => {
  if (!question) {
    return {};
  }

  if (question.type === 'FreeText') {
    const answer = question.answers[0];
    if (!answer) {
      // wrong data from backend? - avoid crashing...
      return {};
    }
    const name = answer.id;

    const field: FieldValueType = {
      type: 'text-area',
      placeholder: answer.title,
      max: MAX_CHAR_TEXT_AREA,
      value: initialValues[name] ?? ''
    };

    return { [name]: field };
  }

  const sortedAnswers = _.sortBy(question.answers, 'rank');
  return sortedAnswers.reduce((acc, answer) => {
    const name = answer.id;
    const field: FieldValueType = answer.isFreeText
      ? {
          type: 'radio-text-area',
          label: answer.title,
          checked: !!initialValues[name] ? true : undefined,
          value: initialValues[name] ?? '',
          max: MAX_CHAR_TEXT_AREA,
          placeholder: ''
        }
      : {
          type: 'radio',
          label: answer.title,
          value: initialValues[name] ?? undefined
        };

    acc[name] = field;
    return acc;
  }, {});
};
