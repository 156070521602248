import * as React from 'react';

import styled, { css } from 'styled-components';

import type { ViewHeadType } from '@maggie/core/router/types';
import { AppLayoutContext } from '@maggie/layout/AppLayoutContext';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';

import { ViewContext } from './ViewContext';
import { getViewTypeStyles } from './view-utils';

export const ViewBody: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { locked } = React.useContext(AppLayoutContext);
  const isMobile = useIsMobile();
  const { viewHeadType } = React.useContext(ViewContext);

  return (
    <Body locked={locked} isMobile={isMobile} viewType={viewHeadType} data-testid="view-body">
      {children}
    </Body>
  );
};

type Props = {
  isMobile: boolean;
  viewType: ViewHeadType | { mobile: ViewHeadType; desktop: ViewHeadType };
  locked: boolean;
};
export const Body = styled.div<Props>(
  ({ isMobile, viewType, locked, theme }) => css`
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    z-index: 0;

    overflow: auto;

    background-color: ${theme.colors.background};
    color: ${theme.colors.text};
    /* Enable scrolling in "View All" of new home screen */
    flex: 1;

    ${getViewTypeStyles(viewType, isMobile, theme).viewBody}

    ${!!locked &&
    css`
      margin-top: 0;
      /* Because there is no view-head, we can take all the space available from the parent */
      height: 100%;
    `}
  `
);
