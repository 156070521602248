import * as React from 'react';
import * as braze from '@braze/web-sdk';
import { Box } from '@edapp/ed-components';
import { BrazeCaptionedImageCard } from './BrazeCaptionedImageCard';
import styled from 'styled-components';
import { ErrorLogger } from '@edapp/monitoring';

type CardExtras = {
  card_target?: 'LMS' | 'LX';
  card_position?: string;
  href_target?: 'self' | 'blank';
  card_platform?: 'sc_training' | 'edapp';
  device_target: 'all' | 'desktop' | 'mobile';
};

type Props = {
  platform: 'LMS' | 'LX';
  isUxp: boolean;
  isMobile: boolean;
  onClick?: (url: string) => void;
};

const useCards = (platform: string, isUxp: boolean, isMobile: boolean) => {
  const [cards, setCards] = React.useState<braze.CaptionedImage[]>();

  React.useEffect(() => {
    try {
      const id = braze.subscribeToContentCardsUpdates(updates => {
        const captionedImageCards = updates.cards.filter(
          card => card instanceof braze.CaptionedImage
        ) as braze.CaptionedImage[];
        setCards(captionedImageCards);
      });

      braze.requestContentCardsRefresh();
      braze.openSession();

      return () => {
        if (id) {
          braze.removeSubscription(id);
        }
      };
    } catch (error) {
      ErrorLogger.captureEvent('Error on loading braze content cards', 'warning', { error });
    }
  }, [setCards]);

  const sortedCards = React.useMemo(() => {
    // Workaround to test and fix when cards come duplicate
    const distinctCards = cards?.reduce((acc, card) => {
      if (!acc.find(c => c.title == card.title)) {
        acc.push(card);
      }
      return acc;
    }, [] as braze.CaptionedImage[]);

    return distinctCards
      ?.filter(c => {
        const cardExtras = c.extras as CardExtras;
        return (
          (typeof cardExtras.card_target === 'undefined' || cardExtras?.card_target === platform) &&
          (typeof cardExtras.device_target === 'undefined' ||
            cardExtras?.device_target === 'all' ||
            (cardExtras?.device_target === 'mobile' && isMobile) ||
            (cardExtras?.device_target === 'desktop' && !isMobile)) &&
          (typeof cardExtras.card_platform === 'undefined' ||
            (cardExtras?.card_platform === 'sc_training' && isUxp) ||
            (cardExtras?.card_platform === 'edapp' && !isUxp))
        );
      })
      .sort((a, b) => {
        const cardAExtras = a.extras as CardExtras;
        const cardBExtras = b.extras as CardExtras;
        const positionA =
          cardAExtras.card_position !== undefined
            ? parseInt(cardAExtras.card_position.trim())
            : Infinity;
        const positionB =
          cardBExtras.card_position !== undefined
            ? parseInt(cardBExtras.card_position.trim())
            : Infinity;
        return positionA - positionB;
      });
  }, [cards, platform, isUxp, isMobile]);

  return sortedCards;
};

export const ClassicBrazeContentCard: React.FC<Props> = ({
  platform,
  isUxp,
  isMobile,
  onClick
}) => {
  const cards = useCards(platform, isUxp, isMobile);

  return cards ? (
    <Box bgColor="lightGrey" rounded py={6}>
      <Container flex flexDirection="row" justifyContent="center" flexWrap="wrap">
        {cards.map((card, index) => {
          const hrefTarget = (card.extras as CardExtras).href_target || 'blank';

          const handleOnClick = () => {
            onClick?.(card.url ?? '');
          };

          return (
            <BrazeCaptionedImageCard
              image={card.imageUrl ?? ''}
              title={card.title}
              description={card.description}
              linkText={card.linkText ?? ''}
              linkUrl={card.url ?? ''}
              target={`_${hrefTarget}`}
              onClick={platform === 'LX' ? handleOnClick : undefined}
              key={index}
            />
          );
        })}
      </Container>
    </Box>
  ) : null;
};

const Container = styled(Box)`
  max-width: 1008px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
