import type { Overwrite } from '@edapp/utils';
import type { RouteName, RouteParams } from '@maggie/core/router/types';
import { AssessmentSelectors } from '@maggie/store/courseware/assessments/selectors';
import { ConferenceSelectors } from '@maggie/store/courseware/conferences/selectors';
import { DiscussionSelectors } from '@maggie/store/courseware/discussions/selectors';
import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import { ObservationSelectors } from '@maggie/store/courseware/observations/selectors';
import { SearchUtils } from '@maggie/store/search/utils';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import type { NavigationState } from './types';

type CommonLocationType = { hash: string; search: string; pathname: string };
/**
 * @returns the location "pathname" - first checks for hash, secondly the pathname.
 */
export const getLocationPath = (location: CommonLocationType = window.location) => {
  return location.hash ? location.hash + location.search : location.pathname + location.search;
};

/**
 * This function facilitates the usage and typesafety of query parameters of routes
 * It ensures query is always defined and typecasts to acceptable query arguments
 *
 * @returns `Q` or `{}` - never `undefined`
 */
export const strictQueryParams = <R extends RouteName, Q = {}>(params: RouteParams<R>) => {
  params.query = !params.query ? {} : params.query;

  if (typeof params.query !== 'object') {
    params.query = {};
  }

  return params as Overwrite<typeof params, { query: Q }>;
};

// This function is duplicated in `learners-app/client/app/router.coffee`
export const parseQueryString = <T extends {}>(qstr: string | undefined): T => {
  if (!qstr) {
    return {} as T;
  }

  const query = {};

  if (qstr != null) {
    const pairs = qstr.split('&');
    for (const pair of Array.from(pairs)) {
      const [k, v] = Array.from(pair.split('='));
      query[decodeURIComponent(k)] = decodeURIComponent(v) || '';
    }
  }

  return query as T;
};

// Make RouteParams typesafe by Anthony
export const isRoute = <R extends RouteName>(
  route: RouteName,
  params: RouteParams<RouteName>,
  compare: R
): params is RouteParams<R> => {
  return route === compare;
};

const getNavigationCourseParent = (courseId: string, course: NavigationState['course']) => {
  if (courseId === course.id) {
    return course.from;
  }

  return {};
};

export type RouteType = { route: RouteName; params: RouteParams<RouteName> };
export const findParentRoute = <R extends RouteName>(
  route: R,
  params: RouteParams<R>,
  state: LxStoreState
): RouteType | undefined => {
  switch (route) {
    case 'playlist':
    case 'courseCollection': {
      return { route: 'home', params: {} };
    }

    case 'accountSettings':
    case 'achievements': {
      return { route: 'profile', params: {} };
    }

    case 'pastWinners':
    case 'upcomingPrizes': {
      return { route: 'game', params: {} };
    }

    case 'performance':
    case 'game':
    case 'leaderboards': {
      // Leaderboards in SCT doesn't have parent page.
      // Otherwise current functionality is retained -> redirects to /profile
      const isUxp = UserSelectors.getProvisioningType(state) === 'external';
      if (!isUxp && UserSelectors.hasNewNavbarEnabled(state)) {
        return { route: 'profile', params: {} };
      }
      return undefined;
    }

    case 'leaderboard': {
      return { route: 'leaderboards', params: {} };
    }

    case 'socialLearning': {
      const lessonId = (params as RouteParams<'socialLearning'>).lessonId;
      return { route: 'lesson', params: { id: lessonId } };
    }

    case 'socialLearningSlide': {
      const lessonId = (params as RouteParams<'socialLearningSlide'>).lessonId;
      return { route: 'socialLearning', params: { lessonId } };
    }

    case 'microCredentials': {
      return { route: 'performance', params: {} };
    }

    case 'briefcaseDocument': {
      const courseId = (params as RouteParams<'briefcaseDocument'>).id;
      const id = (params as RouteParams<'briefcaseDocument'>).documentId;
      const shouldGoBackToSearch = SearchUtils.shouldGoBackToSearch(id, 'BriefcaseDocument', state);
      if (shouldGoBackToSearch) {
        const newNavbar = UserSelectors.hasNewNavbarEnabled(state);
        if (newNavbar) {
          return { route: 'search', params: {} };
        } else {
          return { route: 'home', params: {} };
        }
      }

      return {
        route: 'course',
        params: {
          id: courseId,
          query: getNavigationCourseParent(courseId, state.navigation.course)
        }
      };
    }

    case 'conference': {
      const conferenceId = (params as RouteParams<'conference'>).id;
      const shouldGoBackToSearch = SearchUtils.shouldGoBackToSearch(
        conferenceId,
        'Conference',
        state
      );
      if (shouldGoBackToSearch) {
        const newNavbar = UserSelectors.hasNewNavbarEnabled(state);
        if (newNavbar) {
          return { route: 'search', params: {} };
        } else {
          return { route: 'home', params: {} };
        }
      }

      const conference = ConferenceSelectors.makeGetConference(conferenceId)(state);
      if (!conference) {
        return undefined;
      }

      return {
        route: 'course',
        params: {
          id: conference.courseId,
          query: getNavigationCourseParent(conference.courseId, state.navigation.course)
        }
      };
    }

    case 'thread': {
      const discussionId = (params as RouteParams<'thread'>).discussionId;
      return { route: 'discussion', params: { id: discussionId } };
    }

    case 'discussion': {
      const discussionId = (params as RouteParams<'discussion'>).id;
      const shouldGoBackToSearch = SearchUtils.shouldGoBackToSearch(
        discussionId,
        'Discussion',
        state
      );
      if (shouldGoBackToSearch) {
        const newNavbar = UserSelectors.hasNewNavbarEnabled(state);
        if (newNavbar) {
          return { route: 'search', params: {} };
        } else {
          return { route: 'home', params: {} };
        }
      }

      const { discussion } = DiscussionSelectors.getDiscussion(discussionId)(state);
      if (!discussion) {
        return undefined;
      }

      return {
        route: 'course',
        params: {
          id: discussion.courseId,
          query: getNavigationCourseParent(discussion.courseId, state.navigation.course)
        }
      };
    }

    case 'submission': {
      const assessmentId = (params as RouteParams<'submission'>).assessmentId;
      return { route: 'assignment', params: { id: assessmentId } };
    }

    case 'assignment': {
      const assessmentId = (params as RouteParams<'assignment'>).id;
      const shouldGoBackToSearch = SearchUtils.shouldGoBackToSearch(
        assessmentId,
        'Assignment',
        state
      );
      if (shouldGoBackToSearch) {
        const newNavbar = UserSelectors.hasNewNavbarEnabled(state);
        if (newNavbar) {
          return { route: 'search', params: {} };
        } else {
          return { route: 'home', params: {} };
        }
      }

      const { assessment } = AssessmentSelectors.getAssessment(assessmentId)(state);
      if (!assessment) {
        return undefined;
      }

      return {
        route: 'course',
        params: {
          id: assessment.courseId,
          query: getNavigationCourseParent(assessment.courseId, state.navigation.course)
        }
      };
    }

    case 'lesson': {
      const lessonId = (params as RouteParams<'lesson'>).id;
      const shouldGoBackToSearch = SearchUtils.shouldGoBackToSearch(lessonId, 'Lesson', state);
      if (shouldGoBackToSearch) {
        const newNavbar = UserSelectors.hasNewNavbarEnabled(state);
        if (newNavbar) {
          return { route: 'search', params: {} };
        } else {
          return { route: 'home', params: {} };
        }
      }

      const lesson = LessonSelectors.getLesson(lessonId, state);
      if (!lesson) {
        return undefined;
      }

      return {
        route: 'course',
        params: {
          id: lesson.courseId,
          query: getNavigationCourseParent(lesson.courseId, state.navigation.course)
        }
      };
    }

    case 'lessonReview': {
      const lessonId = (params as RouteParams<'lessonReview'>).lessonId;
      const shouldGoBackToSearch = SearchUtils.shouldGoBackToSearch(lessonId, 'Lesson', state);
      if (shouldGoBackToSearch) {
        const newNavbar = UserSelectors.hasNewNavbarEnabled(state);
        if (newNavbar) {
          return { route: 'search', params: {} };
        } else {
          return { route: 'home', params: {} };
        }
      }

      return undefined;
    }

    case 'observation': {
      const observationId = (params as RouteParams<'observation'>).id;
      const shouldGoBackToSearch = SearchUtils.shouldGoBackToSearch(
        observationId,
        'Observation',
        state
      );
      if (shouldGoBackToSearch) {
        const newNavbar = UserSelectors.hasNewNavbarEnabled(state);
        if (newNavbar) {
          return { route: 'search', params: {} };
        } else {
          return { route: 'home', params: {} };
        }
      }

      const observation = ObservationSelectors.getObservation(observationId)(state);
      if (!observation) {
        return undefined;
      }

      return {
        route: 'course',
        params: {
          id: observation.courseId,
          query: getNavigationCourseParent(observation.courseId, state.navigation.course)
        }
      };
    }

    case 'course': {
      const courseId = (params as RouteParams<'course'>).id;
      const shouldGoBackToSearch = SearchUtils.shouldGoBackToSearch(courseId, 'Course', state);
      if (shouldGoBackToSearch) {
        const newNavbar = UserSelectors.hasNewNavbarEnabled(state);
        if (newNavbar) {
          return { route: 'search', params: {} };
        } else {
          return { route: 'home', params: {} };
        }
      }

      const { collectionId, playlistId } = getNavigationCourseParent(
        courseId,
        state.navigation.course
      );

      if (playlistId) {
        return { route: 'playlist', params: { id: playlistId } };
      }

      if (collectionId) {
        return { route: 'courseCollection', params: { id: collectionId } };
      }

      return { route: 'home', params: {} };
    }
  }
};
