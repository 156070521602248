import * as React from 'react';
import styled, { css } from 'styled-components';
import { useTimer } from '../../hooks';
import SwipeableCarouselArrow from './arrows/SwipeableCarouselArrow';
import SwipeableCarouselSlide from './carousel-slide/SwipeableCarouselSlide';
import SwipeableCarouselDotGroup from './carousel-dot-group/SwipeableCarouselDotGroup';
import { SwipeableViews } from '../swipeable-views/SwipeableViews';
import { SwipeableViewSlide } from '../swipeable-views/Slide';
import type { ColorsKeys } from '@edapp/themes';
import type { IconSize } from '../icons';
import type { SwipeableCarouselAnimationType } from './types';
import type { ArrowProps } from './arrows/SwipeableCarouselArrow';
export { SwipeableCarouselAnimations } from './types';

type CarouselProps = {
  children: React.ReactNode | React.ReactNode[];
  onBeforePrevSlide?: (slideIndex: number) => void;
  onBeforeNextSlide?: (slideIndex: number) => void;
  customPrevArrow?: React.FC<ArrowProps>;
  customNextArrow?: React.FC<ArrowProps>;
  animations?: string[] | SwipeableCarouselAnimationType[];
  disabled?: boolean;
  showDots?: boolean;
  dotsColor?: ColorsKeys;
  dotsSize?: IconSize;
  hideArrows?: boolean;
  showFullContent?: boolean;
  /**
   * @default false
   */
  autoplay?: boolean;
  /**
   * Amount of time (ms) to show each
   * carousel slide when autoplay is enabled
   * @default 5000
   */
  autoplayDelay?: number;
};

export const SwipeableCarousel: React.FC<CarouselProps> = React.memo(
  ({
    onBeforePrevSlide,
    onBeforeNextSlide,
    customPrevArrow,
    customNextArrow,
    animations = [],
    children,
    disabled = false,
    showDots = false,
    dotsColor,
    dotsSize,
    hideArrows = false,
    showFullContent = false,
    autoplay = false,
    autoplayDelay = 5000,
    ...rest
  }) => {
    const [current, setCurrent] = React.useState(0);
    const slides = React.Children.toArray(children);
    const maxIndex = slides.length - 1;

    const currentChild = children?.[current];

    const autoplaySlide = (time: number) => {
      if (time === autoplayDelay) {
        setCurrent(prev => (prev === maxIndex ? 0 : prev + 1));
        // restart timer
        reset();
        start();
      }
    };

    const shouldAutoplay = autoplay && slides.length > 1;
    const { start, stop, reset } = useTimer({
      initialTimerStatus: shouldAutoplay ? 'running' : 'stop',
      timerType: 'increment',
      endTime: autoplayDelay,
      onTimerEnd: autoplaySlide
    });

    const prevSlide = () => {
      if (current > 0) {
        if (onBeforePrevSlide) {
          onBeforePrevSlide(current - 1);
        }

        setCurrent(current - 1);
        reset();
      }
    };

    const nextSlide = () => {
      // Call onBeforeNextSlide even if on last slide
      if (current <= maxIndex) {
        if (onBeforeNextSlide) {
          onBeforeNextSlide(current + 1);
        }
      }

      // But only increment slide count if there are more slides
      if (current < maxIndex) {
        setCurrent(current + 1);
      }
      reset();
    };

    const goToSlide = (slideNumber: number) => {
      if (onBeforeNextSlide) {
        onBeforeNextSlide(slideNumber);
      }
      setCurrent(slideNumber);
      reset();
    };

    const showArrows = (!hideArrows && currentChild?.props.showArrows) ?? true;
    const PreviousArrowComponent = customPrevArrow ?? SwipeableCarouselArrow;
    const NextArrowComponent = customNextArrow ?? SwipeableCarouselArrow;
    const showLeftArrow = showArrows && current > 0 && !disabled;
    const showRightArrow = showArrows && current < maxIndex && !disabled;

    return (
      <Wrapper
        {...(autoplay && {
          onMouseEnter: stop,
          onMouseLeave: start
        })}
        showFullContent={showFullContent}
        {...rest}
      >
        {showLeftArrow && (
          <PreviousArrowComponent onClick={prevSlide} direction="previous" disabled={disabled} />
        )}
        {showRightArrow && !disabled && (
          <NextArrowComponent onClick={nextSlide} direction="next" disabled={disabled} />
        )}
        <StyledSwipeableViews
          viewIndex={current}
          onChangeViewIndex={goToSlide}
          disabled={disabled}
          showFullContent={showFullContent}
        >
          {slides.map((slide, index) => (
            <SwipeableCarouselSlide
              animations={animations}
              key={`slide-${index}`}
              active={index === current}
              showFullContent={showFullContent}
            >
              {slide}
            </SwipeableCarouselSlide>
          ))}
        </StyledSwipeableViews>
        {showDots && (
          <StyledSwipeableCarouselDotGroup
            onClick={goToSlide}
            currentPosition={current}
            slidesCount={slides.length}
            showFullContent={showFullContent}
            color={dotsColor}
            size={dotsSize}
          />
        )}
      </Wrapper>
    );
  }
);

type WrapperProps = Pick<CarouselProps, 'showFullContent'>;

const handleFullContentDimensions = (showFullContent: boolean) => {
  if (showFullContent)
    return css`
      width: 100%;
      height: 100%;
    `;
};

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  white-space: nowrap;
  ${({ showFullContent }) => handleFullContentDimensions(!!showFullContent)};
`;

type StyledSwipeableViewsProps = Pick<CarouselProps, 'showFullContent'>;

const StyledSwipeableViews = styled(SwipeableViews)<StyledSwipeableViewsProps>`
  & {
    ${SwipeableViewSlide} {
      display: inline-flex;
      vertical-align: middle;
      ${({ showFullContent }) => handleFullContentDimensions(!!showFullContent)}
    }
    ${({ showFullContent }) => handleFullContentDimensions(!!showFullContent)};
  }
`;

type StyledCarouselDotGroupProps = Pick<CarouselProps, 'showFullContent'>;

const StyledSwipeableCarouselDotGroup = styled(SwipeableCarouselDotGroup)<
  StyledCarouselDotGroupProps
>`
  ${({ showFullContent }) =>
    showFullContent &&
    `
    position: absolute;
    bottom: 30px;
  `}
`;
