import * as _ from 'lodash';

import { ErrorLogger } from '@edapp/monitoring';

import type { InteractionElement, UserIdentification } from './interaction-database';
import { InteractionDatabase, orderKey } from './interaction-database';

const QUEUE_ELEMENT_ABANDON_TIMEOUT = 13000;

const isForUser = (element: InteractionElement, identity: UserIdentification): boolean =>
  element.userId && element.applicationId
    ? element.userId === identity.userId && element.applicationId === identity.applicationId
    : // Legacy interaction which was enqueued with a campus token – assume it's for the user so it gets cleared
      !!element['token'];

// ### DESCRIPTION ###
// # This module implements a queue using IndexedDB
// # that is thread-safe, so multiple browsing contexts
// # can be running and not send each others' interactions.

// ### OPERATION ###
// # The queue is designed to be fully durable in
// # the sense that there is no way to lose data
// # (except through a disk failure or browser bug).
// # To allow this, when sending an interaction you do
// # not dequeue it, but rather "lock" it which means no
// # other thread will be able to do so. This is
// # implemented by the "takeNext" function which
// # works by setting the `startedWorking` property
// # of the queue element to the current time.
// # After that, we do not allow that element to be
// # taken from the queue until either the `startedWorking`
// # is set back to zero (the default state), or
// # a certain amount of time has passed, after which
// # we consider the queue element to be "abandoned",
// # which could mean the browsing context was closed
// # or the device lost power or otherwise failed
// # during the send operation.
// #
// # Once we have received an OK response from the server,
// # we then can remove the interaction completely from the
// # queue.

const interactionQueue = (prefix: string) => {
  // Set up a Dexie instance
  const db = new InteractionDatabase(prefix);

  // enqueue an interaction
  const enqueue = (element: InteractionElement, withPriority = false) => {
    const toAdd = _.extend(
      {
        startedWorking: 0,
        priority: withPriority ? 0 : 1
      },
      element
    );
    toAdd.order = orderKey(toAdd);
    return db.elements.add(toAdd);
  };

  // Try to get a lock on an interaction (to send it)
  // This works by setting abandonThreshold
  const takeNext = (identity: UserIdentification) => {
    return db.transaction('rw', db.elements, () => {
      const abandonThreshold = Date.now() - QUEUE_ELEMENT_ABANDON_TIMEOUT;

      return db.elements
        .orderBy('[order]')
        .filter(
          element =>
            (element.startedWorking || 0) < abandonThreshold && isForUser(element, identity)
        )
        .limit(1)
        .toArray()
        .then(foundElements => {
          if (foundElements.length !== 1) {
            return null;
          }

          // success! we can take this element
          const [foundElement] = Array.from(foundElements);
          const now = Date.now();
          return db.elements
            .update(foundElement.id, { startedWorking: now })
            .then(numUpdatedElements => {
              foundElement.startedWorking = now;
              if (numUpdatedElements !== 1) {
                console.error(`this should never happen! updated ${numUpdatedElements}`);
                return null;
              } else {
                return foundElement;
              }
            });
        });
    });
  };

  const takeBatch = (max: number, identity: UserIdentification): Promise<InteractionElement[]> => {
    if (max <= 0) {
      // base case: takeBatch(0) = []
      return Promise.resolve([]);
    }

    // This calls takeNext in a loop until the max number is reached
    // or takeNext() is rejected due to there being no queue elements.
    // It also will ensure that all taken elements have the same token.
    // recursive case:
    // takeBatch(n) = [takeNext()] concat takeBatch(n - 1)
    return takeNext(identity)
      .then(element => {
        // if we can takeNext, try again, there could be more.
        if (element != null) {
          return takeBatch(max - 1, identity)
            .then(batch => [element].concat(batch))
            .catch(() => []);
        } else {
          return [];
        }
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  };

  // Update a queue element transactionally
  const updateElement = async (
    id: string | null,
    updateFn: (element: InteractionElement) => InteractionElement
  ): Promise<InteractionElement | undefined> => {
    if (!id) {
      ErrorLogger.captureEvent(
        `interaction-queue.updateElement received a null id. See extra data for updateFn that was sent with it.`,
        'error',
        { id, updateFn: JSON.stringify(updateFn) }
      );
      return;
    }

    return db.transaction('rw', db.elements, async () => {
      const element = await db.elements.get(id);
      if (!element) {
        console.error(`Could not find interaction in the queue with id ${id}`);
        return; // cannot find interaction anymore - that's ok?
      }

      const transformed = updateFn(element!);
      await db.elements.put(transformed);
      return transformed;
    });
  };

  const reducePriority = (id: string) => {
    // We actually increase the priority because we're
    // sorting in ascending order when we takeNext.
    return updateElement(id, element => {
      const transformed = _.extend({}, element, { priority: element.priority || -1 + 1 });
      transformed.order = orderKey(transformed);
      return transformed;
    });
  };

  // Releases an element to allow it to be used again
  const release = (id: string) => {
    return db.elements.update(id, { startedWorking: 0 });
  };

  // Completely removes an element from the queue
  const remove = (id: string) => {
    return db.elements.delete(id);
  };

  const hasAny = (f: (e: InteractionElement) => boolean, identity: UserIdentification) => {
    return db.elements
      .filter(e => isForUser(e, identity))
      .filter(f)
      .toArray()
      .then(ats => ats.length > 0);
  };

  const count = () => {
    return db.elements.count();
  };

  return {
    __db: db,

    enqueue,
    release,
    takeBatch,
    updateElement,
    remove,
    reducePriority,
    hasAny,
    count
  };
};

export { interactionQueue };
