import { all, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';
import { CustomAchievementsActions } from '@maggie/store/custom-achievements/actions';
import { Flags } from '@maggie/store/feature-flags/constants';
import { FeatureFlagsSelectors } from '@maggie/store/feature-flags/selectors';
import { LearnerDemographicActions } from '@maggie/store/learner-demographic/actions';
import { RatingDialogActions } from '@maggie/store/rating-dialog/actions';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { SlideProgressActionTypes, SlideProgressActions } from '../../slide-progress/actions';
import { CourseActions } from '../courses/actions';
import { CourseSelectors } from '../courses/selectors';
import { LoadingActions } from '../loading/actions';
import { LessonActionTypes, LessonActions } from './actions';
import { watchAttemptLesson } from './attempt-lesson-sagas';
import { watchCloseLesson } from './close-lesson-sagas';
import { watchCloseLessonSummary } from './close-lesson-summary-sagas';
import { watchFetchCourseLeaderboards } from './course-leaderboard-sagas';
import { watchFetchLessonAttempt, watchFetchLessonAttemptList } from './fetch-lesson-attempt';
import { watchOpenLesson } from './open-lesson-sagas';
import { LessonSelectors } from './selectors';
import type { LessonAction, LessonType } from './types';
import { watchUpdateLessonCompleted } from './update-lesson-completed-sagas';
import { watchUpdateLessonFailed } from './update-lesson-failed-sagas';
import { watchUpdateLessonOpened } from './update-lesson-opened-sagas';
import { watchUpdateLessonsUnlock } from './update-lesson-unlock-sagas';

// FETCH LESSONS
function* handleFetchLesson(action: LessonAction<LessonActionTypes.FETCH_LESSON>) {
  const lessonIds: string[] = [action.payload.lessonId];

  yield put(
    RequestActions.getAuthed(
      Urls.LESSON,
      LessonActionTypes.FETCH_LESSON_SUCCESS,
      LessonActionTypes.FETCH_LESSON_FAILURE,
      undefined,
      {
        ids: lessonIds
      }
    )
  );
}
function* watchFetchLesson() {
  yield takeLatest(LessonActionTypes.FETCH_LESSON, handleFetchLesson);
}

function* handleFetchLessonProgress(action: LessonAction<LessonActionTypes.FETCH_LESSON_PROGRESS>) {
  const lessonIds = action.payload.ids;

  const successAction = action.payload.isPrerequisites
    ? LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS
    : LessonActionTypes.FETCH_LESSON_PROGRESS_SUCCESS;

  const failureAction = action.payload.isPrerequisites
    ? LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_FAILURE
    : LessonActionTypes.FETCH_LESSON_PROGRESS_FAILURE;

  yield put(
    RequestActions.getAuthed(Urls.LESSON_PROGRESS, successAction, failureAction, undefined, {
      lessonIds
    })
  );
}
function* watchFetchLessonProgress() {
  yield takeLatest(LessonActionTypes.FETCH_LESSON_PROGRESS, handleFetchLessonProgress);
}

function* handleFetchLessonWithProgress(
  action: LessonAction<LessonActionTypes.FETCH_LESSON_WITH_PROGRESS>
): any {
  const { lessonId, withLoading } = action.payload;
  const lessonIds = [lessonId];

  const hasReviewLessonFlag: boolean = yield select<LxStoreState>(s =>
    FeatureFlagsSelectors.isFlagEnabled(Flags.reviewLesson, s)
  );

  yield all([
    withLoading ? put(LoadingActions.startLoading()) : null,
    put(LessonActions.fetchLesson(lessonId)),
    put(LessonActions.fetchLessonsProgress(lessonIds)),
    put(SlideProgressActions.fetchSlidesProgress(lessonIds)),
    hasReviewLessonFlag
      ? put(LessonActions.fetchLessonAttemptList([lessonId]))
      : put(LessonActions.fetchLessonAttempt(lessonId))
  ]);

  const { success, failure } = yield race({
    success: all({
      lesson: take(LessonActionTypes.FETCH_LESSON_SUCCESS),
      progress: take(LessonActionTypes.FETCH_LESSON_PROGRESS_SUCCESS),
      slideProgress: take(SlideProgressActionTypes.FETCH_SLIDE_PROGRESS_SUCCESS)
    }),
    failure: race({
      lessonFailure: take(LessonActionTypes.FETCH_LESSON_FAILURE),
      progressFailure: take(LessonActionTypes.FETCH_LESSON_PROGRESS_FAILURE),
      slideProgressFailure: take(SlideProgressActionTypes.FETCH_SLIDE_PROGRESS_FAILURE)
    })
  });

  if (failure) {
    return yield put({
      type: LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_FAILURE,
      payload: { error: failure }
    });
  }

  const lesson: LessonType = success.lesson.payload.items[0];
  if (!lesson) {
    yield put({
      type: LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_FAILURE,
      payload: { error: 'Lesson not found.' }
    });
    return;
  }

  const course = yield select<LxStoreState>(s => CourseSelectors.getCourse(lesson.courseId, s));
  if (!course) {
    yield put(CourseActions.fetchSyncCourse(lesson.courseId));
  }

  const prerequisites = lesson ? lesson.prerequisites : [];
  if (prerequisites.length === 0) {
    // if we don't have prerequisites - we should put the success
    // offline sagas are racing for that action
    // if never gets called, it awaits forever
    yield put({
      type: LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS,
      payload: []
    });
  } else {
    yield put(LessonActions.fetchLessonsProgress(prerequisites, true));
    yield race({
      success: take(LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_SUCCESS),
      failure: take(LessonActionTypes.FETCH_LESSON_PROGRESS_PREREQUISITES_FAILURE)
    });
  }

  yield all([
    withLoading ? put(LoadingActions.stopLoading()) : null,
    put({ type: LessonActionTypes.FETCH_LESSON_WITH_PROGRESS_SUCCESS })
  ]);
}
function* watchFetchLessonWithProgress() {
  yield takeLatest(LessonActionTypes.FETCH_LESSON_WITH_PROGRESS, handleFetchLessonWithProgress);
}

function* handleFetchLessonBreadcrumb(
  action: LessonAction<LessonActionTypes.FETCH_LESSON_BREADCRUMB>
) {
  const lessonId = action.payload.id;
  yield put(
    RequestActions.getAuthed(
      `api/breadcrumb-info/lesson/${lessonId}`,
      LessonActionTypes.FETCH_LESSON_BREADCRUMB_SUCCESS,
      LessonActionTypes.FETCH_LESSON_BREADCRUMB_FAILURE
    )
  );
}

function* watchFetchLessonBreadcrumb() {
  yield takeLatest(LessonActionTypes.FETCH_LESSON_BREADCRUMB, handleFetchLessonBreadcrumb);
}

function* handleFetchLessonsAccess(action: LessonAction<LessonActionTypes.FETCH_LESSONS_ACCESS>) {
  const { lessonIds } = action.payload;
  yield put(
    RequestActions.getAuthed(
      Urls.LESSONS_ACCESS,
      LessonActionTypes.FETCH_LESSONS_ACCESS_SUCCESS,
      LessonActionTypes.FETCH_LESSONS_ACCESS_FAILURE,
      undefined,
      { Ids: lessonIds }
    )
  );
}

function* watchFetchLessonsAccess() {
  yield takeLatest(LessonActionTypes.FETCH_LESSONS_ACCESS, handleFetchLessonsAccess);
}

function* handleFinishedThomasLesson(
  action: LessonAction<LessonActionTypes.FINISHED_THOMAS_LESSON>
) {
  const { lessonId } = action.payload;

  const state: LxStoreState = yield select();
  const isIndividualLearner = UserSelectors.isIndividualLearner(state.user);
  const lesson = LessonSelectors.getLesson(lessonId, state);

  if (!lesson) return;

  yield put(CustomAchievementsActions.fetchCustomAchievementsList());

  if (isIndividualLearner) {
    const course = CourseSelectors.getCourse(lesson.courseId, state);

    // Show Rating modal only after lesson which are rateable and the course hasnt been prompt rating yet
    if (course && !course.userRating && !course.hasBeenPromptRating && lesson.isRateable) {
      const { brandingImage, title, userRating = null, userReview = '' } = course;
      yield put(RatingDialogActions.openRatingDialog(brandingImage, title, userRating, userReview));
      yield put(CourseActions.hasBeenPromptRating(course.id));
    }

    // Show Learners Demographic modal
    else {
      const { numberOfLessonsClosed, questions } = state.learnerDemographic;
      const { demographicQuestionFrequency } = state.user;
      const isRightMoment =
        numberOfLessonsClosed !== 0 && numberOfLessonsClosed % demographicQuestionFrequency === 0;
      const hasQuestions = questions.length > 0;

      if (isRightMoment && hasQuestions) {
        yield put(LearnerDemographicActions.open());
      }
    }
  }
}

function* watchFinishedThomasLesson() {
  yield takeLatest(LessonActionTypes.FINISHED_THOMAS_LESSON, handleFinishedThomasLesson);
}

const lessonsSagas = [
  fork(watchFetchLesson),
  fork(watchFetchLessonProgress),
  fork(watchFetchLessonWithProgress),
  fork(watchFetchLessonBreadcrumb),
  fork(watchFetchLessonsAccess),
  fork(watchUpdateLessonOpened),
  fork(watchUpdateLessonsUnlock),
  fork(watchFetchCourseLeaderboards),
  fork(watchCloseLessonSummary),
  fork(watchFinishedThomasLesson),
  fork(watchUpdateLessonCompleted),
  fork(watchUpdateLessonFailed),
  fork(watchAttemptLesson),
  fork(watchOpenLesson),
  fork(watchCloseLesson),
  fork(watchFetchLessonAttempt),
  fork(watchFetchLessonAttemptList)
];

export { lessonsSagas };
