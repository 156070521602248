import * as React from 'react';

import type { ColorsKeys } from '@edapp/themes';

import { Typography } from '../typography';
import { Container } from './styled';
import type { BackgroundColor, Props } from './types';

export const getStatusTextColor = (background: BackgroundColor): ColorsKeys => {
  switch (background) {
    case 'blue':
      return 'textBlue';
    case 'navy':
      return 'white';
    case 'orange':
      return 'textOrange';
    case 'purple':
      return 'purple';
    case 'green':
      return 'green';
    case 'red':
      return 'red';
    default:
      return 'text';
  }
};

export const StatusLabel = React.forwardRef<HTMLDivElement, Props>(
  ({ label, background = 'greyActive', text, onClick, testId, children, ...rest }, ref) => {
    const colors = React.useMemo(() => text || getStatusTextColor(background), [background, text]);

    return (
      <Container
        ref={ref}
        rounded={true}
        opacity={background === 'navy' ? 1 : 0.1}
        backgroundColor={background}
        textAlign="center"
        onClick={onClick}
        testId={testId}
        {...rest}
      >
        <Typography variant="subtitle2" color={colors}>
          {label}
        </Typography>

        {children}
      </Container>
    );
  }
);
