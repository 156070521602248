import type { LxStoreState } from '@maggie/store/types';

import type { SubmissionType } from './types';

const getObservation = (observationId: string) => (state: LxStoreState) => {
  if (state.courseware.observations.selectedObservation.id === observationId) {
    return state.courseware.observations.selectedObservation;
  }

  return state.courseware.observations.completedObservationsList.items.find(
    i => i.id === observationId
  );
};

const getSubmissionIdforObservation = (observationId: string) => (state: LxStoreState) => {
  const observation = getObservation(observationId)(state);
  return observation?.submissions[0]?.id;
};

const getAllObservationsList = (state: LxStoreState) => {
  return state.courseware.observations.completedObservationsList?.items || [];
};

const getObservationsTotalCount = (state: LxStoreState) => {
  return state.courseware.observations.completedObservationsList?.totalCount || 0;
};

const getIsFetchingObservationSubmissions = (state: LxStoreState) => {
  return state.courseware.observations.fetchingAllObservationSubmissionsLoading;
};

const getIsFetchingObservation = (state: LxStoreState) => {
  return state.courseware.observations.fetchingObservationLoading;
};

const getSelectedObservation = (state: LxStoreState) => {
  return state.courseware.observations.selectedObservation;
};

const getSelectedSubmission = (submissionId: string) => {
  return (state: LxStoreState) => {
    const observation = state.courseware.observations.selectedObservation;
    const submission = observation.submissions.find(
      (submission: SubmissionType) => submission.id === submissionId
    );
    return submission;
  };
};

export const ObservationSelectors = {
  getAllObservationsList,
  getObservationsTotalCount,
  getIsFetchingObservationSubmissions,
  getIsFetchingObservation,
  getSelectedObservation,
  getSelectedSubmission,
  getObservation,
  getSubmissionIdforObservation
};
