import type { CoursewareState } from './types';

import { initialAssessmentState } from './assessments/constants';
import { initialCollectionsState } from './collections/constants';
import { initialConferencesState } from './conferences/constants';
import { initialCoursesState } from './courses/constants';
import { initialDiscussionsState } from './discussions/constants';
import { initialDocumentsState } from './documents/constants';
import { initialLessonsState } from './lessons/constants';
import { initialCoursewareLoadingState } from './loading/constants';
import { initialMicroCredentialsState } from './micro-credentials/constants';
import { initialPlaylistsState } from './playlists/constants';
import { initialRapidRefreshesState } from './rapid-refreshes/constants';
import { initialObservationsState } from './observations/constants';
import { initialAwardedCertificatesState } from './awarded-certificates/constants';
import { initialGroupTrainingState } from './group-training/reducer';

export const initialCoursewareState: CoursewareState = {
  collections: initialCollectionsState,
  courses: initialCoursesState,
  documents: initialDocumentsState,
  lessons: initialLessonsState,
  isLoading: initialCoursewareLoadingState,
  playlists: initialPlaylistsState,
  discussions: initialDiscussionsState,
  assessments: initialAssessmentState,
  conferences: initialConferencesState,
  microCredentials: initialMicroCredentialsState,
  rapidRefreshes: initialRapidRefreshesState,
  observations: initialObservationsState,
  awardedCertificates: initialAwardedCertificatesState,
  groupTraining: initialGroupTrainingState
};
