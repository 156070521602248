import type { Localization } from '@safetyculture/sc-web-ui';

// Default UI component strings to fall back on if the app
// does not support i18next

export const defaultLocalisation: Localization = {
  timeline: {
    showMore: () => 'Show more',
    showLess: () => 'Show less',
    loadMore: () => 'View more'
  },
  button: {
    done: () => 'Done',
    remove: () => 'Remove',
    addFilter: () => 'Add filter',
    viewMore: () => 'View more'
  },
  carousel: {
    showNext: () => 'show next',
    showPrev: () => 'show previous'
  },
  uploader: {
    invalidFileType: () => 'Invalid file type. The supported file types are:',
    dragHere: () => 'Drag your file here',
    tryAgain: () => 'Try again',
    browse: () => 'browse',
    emptyFile: () => 'File is empty',
    fileTooLarge: () => 'File is to large.',
    or: () => 'or',
    uploading: () => 'Uploading...',
    dropFileHere: () => 'Drop your file here'
  },
  hierarchicalPicker: {
    loadMore: () => 'Load more'
  },
  formLabel: {
    optionalLabelDefault: () => '(optional)'
  },
  typeLabel: {
    inspection: () => 'Inspection',
    action: () => 'Action',
    issue: () => 'Issue',
    course: () => 'Course',
    headsUp: () => 'Heads Up',
    rapidRefresh: () => 'Rapid Refresh',
    playlist: () => 'Playlist',
    brainBoost: () => 'Brain Boost',
    courseCollection: () => 'Course Collection'
  },
  priorityLabel: {
    none: () => 'None',
    low: () => 'Low',
    medium: () => 'Medium',
    high: () => 'High'
  },
  statusLabel: {
    'in-progress': () => 'In Progress',
    completed: () => 'Completed',
    open: () => 'Open',
    resolved: () => 'Resolved',
    'to-view': () => 'To View',
    acknowledged: () => 'Acknowledged'
  },
  searchInput: {
    search: () => 'Search',
    clear: () => 'Clear Search'
  },
  layouts: {
    courseLayout: {
      lessons: (numberOfLessons: number) =>
        numberOfLessons === 1 ? '1 Lesson' : `${numberOfLessons} Lessons`
    },
    rapidRefreshLayout: {
      sessions: (currentSession: number, totalSession: number) =>
        `${currentSession} of ${totalSession}`,
      questions: (numberOfQuestions: number) =>
        numberOfQuestions === 1 ? '1 Question' : `${numberOfQuestions} Questions`
    },
    brainBoostLayout: {
      questions: (numberOfQuestions: number) =>
        numberOfQuestions === 1 ? '1 Question' : `${numberOfQuestions} Questions`,
      duration: (duration: number) => (duration === 1 ? '1 Minute' : `${duration} Minutes`)
    },
    playlistLayout: {
      courses: (numberOfCourses: number) =>
        numberOfCourses === 1 ? '1 Course' : `${numberOfCourses} Courses`
    }
  },
  passwordInput: {
    showPassword: () => 'Show password as plain text.',
    hidePassword: () => 'Hide password'
  },
  modal: {
    closeButton: () => 'Close button'
  }
};
