export const LOCAL_STORAGE_FEATURE_FLAGS_KEY = 'ed:feature-flags';

export const featureFlagsRoute = 'api/feature-flags';

export enum Flags {
  enableMindfulLearning = 'enable-mindful-learning',
  enableNotificationCentre = 'enable-notification-centre',
  enableAdvancedIntegrations = 'advanced-integrations-enabled',
  enableAiCourseGeneration = 'enable-ai-course-generation',
  enableAiCourseGenerationUXP = 'enable-ai-course-generation-uxp',
  enableRichTextEditorCodeView = 'enable-rich-text-editor-code-view',
  enableAiDocumentUpload = 'enable-ai-document-upload',
  enableAiDocumentWholeCourse = 'enable-ai-document-whole-course',
  enableCourseSharing = 'enable-course-sharing',
  enableAuthenticationModal = 'enable-authentication-modal',
  enableCourseComments = 'enable-course-comments',
  enableTranslationsV2 = 'enable-translations-v2',
  /**
   * User management flags
   */
  enableV2UserProfile = 'enable-v2-user-profile',
  enableNewEdappGroupAssignation = 'course-group-assignation-edapp',
  enableAssignTrainingManagers = 'enable-assign-training-managers',
  enableMigrationTool = 'enable-migration-tool'
}
