import { ErrorLogger } from '@edapp/monitoring';
import { ENV } from '@maggie/config/env';

const cordovaScriptPath = 'cordova.js';
let script: HTMLScriptElement | null = document.querySelector(`script[src="${cordovaScriptPath}"]`);

const logError = (err: ErrorEvent) => {
  ErrorLogger.captureEvent('Fail to bootstrap cordova', 'fatal', { err, china: ENV.FOR_CHINA });
};

function cordovaBootstrap() {
  script?.removeEventListener('error', logError);
  document.addEventListener('resume', () => Backbone.Events.trigger('resume'));
  document.addEventListener('active', () => Backbone.Events.trigger('active'));
  document.addEventListener('pause', () => Backbone.Events.trigger('pause'));

  // For Android: back button shares the same logic as our App's back button, unless:
  // #home, #login: exit app
  // #lessonPlay, restricted deeplinks, locked dialog is opened: back button action disabled
  document.addEventListener('backbutton', e => {
    const disableAndroidBackButton =
      window.__store.getState().config.restrictToCourseScreen ||
      window.__store.getState().config.restrictToLessonScreen ||
      window.__store.getState().lockedDialog.isOpen ||
      window.__router.getMain().routeName === 'lessonPlay';

    const shouldExitApp =
      window.__router.getMain().routeName === 'home' ||
      window.__router.getMain().routeName === 'login';

    if (shouldExitApp) {
      navigator.app.exitApp();
    } else if (disableAndroidBackButton) {
      e.preventDefault();
    } else {
      window.__router.goBack();
    }
  });

  window.__cordovaReady = true;
}

export const isCordovaBuild =
  (document.querySelector("meta[name='cordova']") as HTMLMetaElement)?.content === 'yes' ?? false;

/**
 * Injects cordova.js in the index.html
 *
 * For App in China, instead of loading cordova.js immediately,
 * we're delaying the load until user has agreed to T&C.
 *
 */
export function bootCordova() {
  script = document.createElement('script');
  script.src = cordovaScriptPath;
  script.addEventListener('load', cordovaBootstrap, { once: true });
  script.addEventListener('error', logError);

  document.head.appendChild(script);
}
