import type { SocialMediaApiType, SocialMediaType } from './types';
import { SocialMediaEnum } from './types';

const getSubtitle = (industry: string, location: string) => {
  if (industry && location) {
    return `${industry}  |  ${location}`;
  }
  return industry || location;
};

const hasSocialLink = (socialMedia: object) => {
  let hasSocial = false;
  Object.values(SocialMediaEnum).map(type => {
    if (!!socialMedia[type]) {
      hasSocial = true;
    }
  });
  return hasSocial;
};

const getDisplayLink = (link: string) => link.replace(/^https?:\/\/www\./, '');

const socialMediaFromApi = (socialFromApi: SocialMediaApiType[]) => {
  const socialMedia = {
    [SocialMediaEnum.LINKEDIN]: '',
    [SocialMediaEnum.FACEBOOK]: '',
    [SocialMediaEnum.TWITTER]: '',
    [SocialMediaEnum.INSTAGRAM]: '',
    [SocialMediaEnum.YOUTUBE]: ''
  };
  Object.values(SocialMediaEnum).map(type => {
    const url = socialFromApi.find(social => social.type.toLowerCase() === type)?.url;
    socialMedia[type] = url || '';
  });
  return socialMedia;
};

const socialMediaToApi = (socialMedia: SocialMediaType) =>
  Object.keys(socialMedia).map(name => ({ type: name, url: socialMedia[name] }));

export { getSubtitle, hasSocialLink, getDisplayLink, socialMediaFromApi, socialMediaToApi };
