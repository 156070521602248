import * as React from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { css } from 'styled-components';

import { Box, Button, Typography } from '@edapp/ed-components';
import EDAPP_LOGO from '@maggie/assets/img/ed-logo.png';
import DEMOGRAPHIC_BACKGROUND from '@maggie/assets/img/leaner-demographic-background.png';
import { TouchScroll } from '@maggie/components/common/TouchScroll';
import { FeatureImage } from '@maggie/components/common/feature-image/FeatureImage';
import { MainLogo } from '@maggie/components/common/main-logo/MainLogo';
import { DemographicQuestionForm } from '@maggie/components/learner-demographics/demographic-question-form/DemographicQuestionForm';
import type { FieldsValueStateType } from '@maggie/components/learner-demographics/demographic-question-form/types';
import {
  getAnswersValue,
  getFieldsValue
} from '@maggie/components/learner-demographics/demographic-question-form/utils';
import { useDeviceKeyboardHeight } from '@maggie/hooks//useDeviceKeyboardHeight';
import { LearnerDemographicActions } from '@maggie/store/learner-demographic/actions';
import { LearnerDemographicSelectors } from '@maggie/store/learner-demographic/selectors';

export const LearnerDemographic: React.FC<{}> = () => {
  const keyboardHeight = useDeviceKeyboardHeight();

  const question = useSelector(LearnerDemographicSelectors.findNextQuestionAvailable);
  const isOpen = useSelector(s => s.learnerDemographic.isOpen);

  const close = bindActionCreators(LearnerDemographicActions.close, useDispatch());
  const optOut = bindActionCreators(LearnerDemographicActions.optOut, useDispatch());
  const saveAnswer = bindActionCreators(LearnerDemographicActions.saveAnswer, useDispatch());

  const [formState, setFormState] = React.useState<FieldsValueStateType>(getFieldsValue(question));

  React.useEffect(() => {
    setFormState(getFieldsValue(question));
  }, [question?.id, isOpen]);

  const handleOptOut = () => {
    if (!question) {
      return;
    }

    optOut(question.id);
    close();
  };

  const hanldeSubmitAnswer = () => {
    if (!question || !isValid()) {
      return;
    }

    saveAnswer(question.id, getAnswersValue(formState));
    close();
  };

  const isValid = () => {
    const values = getAnswersValue(formState);
    return values.length > 0;
  };

  const onOpenPrivacyPolicy = () => {
    window.open('https://www.edapp.com/privacy-policy/', '_system');
  };

  return (
    <AnimatePresence>
      {!!isOpen && !!question && (
        <Background initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <FeatureImage image={DEMOGRAPHIC_BACKGROUND} />

          <StyledTouchScroll>
            <MainLogo src={EDAPP_LOGO} />

            <Container p="md" pt="xl">
              <AnimatePresence>
                {keyboardHeight === 0 && (
                  <HeadingTitle
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                  >
                    {/* TODO: https://safetyculture.atlassian.net/browse/TRAINING-520 */}
                    <strong>Help</strong> our community of authors{' '}
                    <strong>improve their courseware!</strong>
                  </HeadingTitle>
                )}
              </AnimatePresence>

              <Box
                flexGrow={1}
                flexShrink={0}
                alignSelf="stretch"
                justifySelf="center"
                flex={true}
                justifyContent="center"
                alignItems="center"
              >
                <DemographicQuestionForm
                  questionTitle={question.title}
                  questionType={question.type}
                  formState={formState}
                  onChange={setFormState}
                />
              </Box>

              <Footer>
                {/* TODO: https://safetyculture.atlassian.net/browse/TRAINING-520 */}
                <TinyText>
                  I would love to help EdApp improve, and I understand that my personal data will be
                  used in line with EdApp’s <a onClick={onOpenPrivacyPolicy}>Privacy Policy</a>. I
                  know I can withdraw my consent at any time.
                </TinyText>

                <Box
                  flex={true}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <StyledButton variant="pale" onClick={handleOptOut}>
                    {/* TODO: https://safetyculture.atlassian.net/browse/TRAINING-520 */}
                    No thanks
                  </StyledButton>

                  <StyledButton variant="light" onClick={hanldeSubmitAnswer} disabled={!isValid()}>
                    {/* TODO: https://safetyculture.atlassian.net/browse/TRAINING-520 */}
                    Submit
                  </StyledButton>
                </Box>
              </Footer>
            </Container>
          </StyledTouchScroll>
        </Background>
      )}
    </AnimatePresence>
  );
};

const StyledButton = styled(Button)`
  flex-grow: 1;
  margin: ${({ theme }) => theme.space(1)}px;

  ${({ disabled, variant }) =>
    variant === 'light' &&
    !!disabled &&
    css`
      opacity: 0.6;
    `}
`;

const TinyText = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 0.875rem;

  & a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Footer = styled(Box)`
  align-self: stretch;
  justify-self: flex-end;
  padding-bottom: ${({ theme }) => theme.space(6.5)}px;
  text-align: center;
`;

const Container = styled(Box)`
  height: 100%;
  width: 100%;
  max-width: 480px;
  margin: auto;

  padding-top: ${({ theme }) => theme.space(13)}px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadingTitle = styled(motion(Typography))`
  align-self: stretch;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.875rem;
  font-weight: 300;
  line-height: 2.125rem;
`;

const Background = styled(motion(TouchScroll))`
  background-color: white;
  overflow-x: hidden;
  z-index: 2;
`;

const StyledTouchScroll = styled(motion(TouchScroll))`
  background: ${({ theme }) => theme.colors.loginGradient};
  color: ${({ theme }) => theme.colors.white};
`;
