import { t } from 'i18next';
import { createSelector } from 'reselect';

import type { DictionaryType } from '@edapp/utils';
import { ENV } from '@maggie/config/env';
import { checkDeviceOnline } from '@maggie/cordova/network_utils';
import { OfflineAssets } from '@maggie/core/offlineAssets';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { CourseSelectors } from '../courses/selectors';
import type { UnlockPayload } from '../types';
import { CoursewareTypeNames } from '../types';
import { CoursewareUtils } from '../utils';
import type { CollectionType, CourseSummaryType, CourseSummaryWithCompletionDate } from './types';
import { CollectionUtils } from './utils';

const getMemoizedCollections = createSelector(
  (state: LxStoreState) => getCollections(state),
  items =>
    CollectionUtils.sortCourseCollections(Object.values(items) || [], window.__store.getState())
);

const getLibrarySortOption = (state: LxStoreState) => {
  return state.courseware.collections.librarySortOption;
};

const getCollections = (state: LxStoreState): DictionaryType<CollectionType> => {
  return checkDeviceOnline()
    ? state.courseware.collections.items
    : OfflineAssets.normalizeUrlsInDictionary(state.offline.courseware.collections);
};

const getTotalCourses = (state: LxStoreState) => {
  return checkDeviceOnline()
    ? state.courseware.collections.totalCourses
    : getAllCoursesInCollections(state).length;
};

const getCollection = (id: string, state: LxStoreState) => {
  return checkDeviceOnline()
    ? state.courseware.collections.items[id]
    : OfflineAssets.normalizeUrls(state.offline.courseware.collections[id]);
};

const getCollectionStatus = () => {
  // why do we need this at all?
  return {
    id: 'active',
    text: t('course.status.active', { ns: 'learners-experience' })
  };
};

const getFetchErrorCode = (state: LxStoreState) => {
  return state.courseware.collections.fetchCollectionsErrorCode;
};

const getTitle = (state: LxStoreState, isSubtle?: boolean) => {
  if (isSubtle) {
    return '';
  }

  const collectionId = state.navigation.collectionId;
  const collection = CollectionsSelectors.getCollection(collectionId, state);
  return collection?.title || '';
};

const getCollectionStyle = (state: LxStoreState) => {
  const collectionId = state.navigation.collectionId;
  const collection = CollectionsSelectors.getCollection(collectionId, state);
  return !!collection ? CoursewareUtils.getBrandingStyleObject(collection) : undefined;
};

const getTextColor = (state: LxStoreState) => {
  return getCollectionStyle(state)?.text;
};

const getBackground = (state: LxStoreState) => {
  const collectionId = state.navigation.collectionId;
  const collection = CollectionsSelectors.getCollection(collectionId, state);
  return ENV.imageUrl(collection?.brandingImage);
};

const getCollectionsCount = (state: LxStoreState) => {
  return Object.keys(getCollections(state)).length;
};

const getTotalCollections = (state: LxStoreState) => {
  return checkDeviceOnline()
    ? state.courseware.collections.totalCount
    : Object.keys(state.offline.courseware.collections).length;
};

const getManualCollections = (state: LxStoreState) => {
  const allCollections = getCollections(state);
  return Object.values(allCollections).filter(CoursewareUtils.isManualCourseCollection);
};

const getManualCollectionsCount = (state: LxStoreState) => {
  return getManualCollections(state).length;
};

const getManualCollectionsCourseCount = (state: LxStoreState) => {
  const manualCollections = getManualCollections(state);
  return manualCollections.reduce((acc, c) => acc + c.courses.length, 0);
};

const getCourseForCollection = (collectionId: string) => (
  state: LxStoreState
): CourseSummaryType | undefined => {
  const collection = getCollection(collectionId, state);
  const courses = collection?.courses || [];

  const lastOpenedCourseId = state.navigation.course.id;
  const lastCourseOpened = courses.find(c => c.courseId === lastOpenedCourseId);

  if (!!lastCourseOpened) {
    return lastCourseOpened;
  }

  return courses[0];
};

const getAllCoursesInCollections = (state: LxStoreState) => {
  const collections = getCollections(state);
  return Object.values(collections).reduce((acc, item) => {
    return [...acc, ...item.courses];
  }, []);
};

const getCoursesInProgress = (state: LxStoreState) => {
  const progress = CourseSelectors.getCoursesProgress(state);
  const courses = getAllCoursesInCollections(state);
  return Object.values(courses).filter(
    c => progress[c.courseId]?.percentageCompleted > 0 && !progress[c.courseId]?.completed
  );
};

const getCoursesInProgressCount = (state: LxStoreState) => {
  return getCoursesInProgress(state).length;
};

const getCoursesCompleted = (state: LxStoreState) => {
  const progress = CourseSelectors.getCoursesProgress(state);
  const courses = getAllCoursesInCollections(state);
  return Object.values(courses)
    .reduce((filtered: CourseSummaryWithCompletionDate[], c) => {
      const completionDate = progress[c.courseId]?.completedDate;
      const completed = progress[c.courseId]?.completed;
      if (completed && !!completionDate) {
        filtered.push({
          ...c,
          completionDate,
          coursewareType: CoursewareTypeNames.COURSE_SUMMARY
        });
      }
      return filtered;
    }, [])
    .sort((c1, c2) => {
      return new Date(c2.completionDate).getTime() - new Date(c1.completionDate).getTime();
    });
};

const getCoursesCompletedCount = (state: LxStoreState) => {
  return getCoursesCompleted(state).length;
};

const getCoursesAndCourseCollections = (state: LxStoreState) => {
  const collections = CollectionsSelectors.getMemoizedCollections(state);
  return CollectionUtils.getManualCollectionsAndCourses(collections);
};

/**
 * Only courses - no course collections
 */
const getSortedLibraryCourses = (state: LxStoreState) => {
  const showCollections = UserSelectors.shouldShowCourseCollectionSection(state.user);
  const collections = getMemoizedCollections(state).filter(cc =>
    // Include courses inside collections if we're not displaying collections
    !showCollections ? true : !cc.isManual
  );
  const sortOption = getLibrarySortOption(state);
  return CollectionUtils.sortLibraryCourses(collections, sortOption);
};

/**
 *
 * Only course collections - no individual courses
 */
const getSortedLibraryCollections = (state: LxStoreState) => {
  const showCollections = UserSelectors.shouldShowCourseCollectionSection(state.user);
  if (!showCollections) {
    return [];
  }

  const collections = getMemoizedCollections(state).filter(cc => cc.isManual);
  const sortOption = getLibrarySortOption(state);
  return CollectionUtils.sortLibraryCollections(collections, sortOption);
};

const getManualCollectionsSortedByRank = (state: LxStoreState) => {
  const collections = getCollections(state);
  return Object.values(collections)
    .filter(CoursewareUtils.isManualCourseCollection)
    .sort((a, b) => a.rank - b.rank);
};

/**
 * This gets all courses that have a prerequisite on the courseId
 */
const getUnlockPayloadFromPrerequisites = (courseId: string) => (
  state: LxStoreState
): UnlockPayload[] => {
  const courses = CourseSelectors.getCourses(state);
  const courseCollections = CollectionsSelectors.getCollections(state);
  const allCourseSummaries = Object.values(courseCollections).reduce(
    (r, cc) => [...r, ...cc.courses],
    []
  );
  const progress = CourseSelectors.getCoursesProgress(state);

  const coursesToUnlock = Object.values(courses)
    .filter(course => {
      if (course.id === courseId) {
        return false;
      }

      if (course.prerequisites.includes(courseId)) {
        const prereqs = course.prerequisites.filter(id => id !== courseId);
        return prereqs.every(prereqId => !!progress[prereqId]?.completed);
      }

      return false;
    })
    .map<UnlockPayload>(course => ({
      id: course.id,
      title: course.title,
      unlocked: true,
      courseVersionNumber: course.versionNumber
    }));

  const courseSummariesToUnlock = allCourseSummaries
    .filter(course => {
      if (course.courseId === courseId) {
        return false;
      }

      if (course.prerequisites?.find(prereq => prereq.id === courseId)) {
        return course.prerequisites.every(prereq => !!progress[prereq.id].completed);
      }

      return false;
    })
    .map<UnlockPayload>(course => ({
      id: course.courseId,
      title: course.title,
      unlocked: true,
      courseVersionNumber: course.versionNumber
    }));

  return [...coursesToUnlock, ...courseSummariesToUnlock];
};

export const CollectionsSelectors = {
  getMemoizedCollections,
  getLibrarySortOption,
  getCollections,
  getCollection,
  getCollectionStatus,
  getCollectionsCount,
  getManualCollectionsCount,
  getManualCollectionsCourseCount,
  getFetchErrorCode,
  getTitle,
  getTextColor,
  getBackground,
  getCourseForCollection,
  getAllCoursesInCollections,
  getCoursesInProgress,
  getCoursesInProgressCount,
  getCoursesCompleted,
  getCoursesCompletedCount,
  getCoursesAndCourseCollections,
  getUnlockPayloadFromPrerequisites,
  getManualCollectionsSortedByRank,
  getSortedLibraryCourses,
  getSortedLibraryCollections,
  getTotalCollections,
  getTotalCourses
};
