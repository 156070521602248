import type { PlanningType, CommentType, WatchStatus, Media, PrerequisitesType } from '../types';
import type { ErrorCode, RequestTypes } from '@edapp/request';

enum AssessmentStatus {
  NOSUBMISSION = 'NoSubmission',
  SUBMITTED = 'Submitted',
  PASSED = 'Passed',
  TRYAGAIN = 'TryAgain'
}

type AssessmentProgress = {
  status: AssessmentStatus;
  postId: string | null;
  hasPosted: boolean;
  hasCommented: boolean;
};

type AssessmentPostCommentType = CommentType;

type AssessmentPostType = {
  id: string;
  content: string;
  userId: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userCustomFields: string[];
  userAvatarUrl: string;
  userIsAdmin: boolean;
  timestamp: string;
  watchingStatus: WatchStatus;
  media: Media[];
};

type AssessmentType = {
  id: string;
  courseId: string;
  courseVersionNumber: number;
  title: string;
  description: string;
  isPublished: boolean;
  postRequired: boolean;
  commentRequired: boolean;
  visibleToAllUsers: boolean;
  prerequisites: string[];
  planning: PlanningType;
  statusGraded: boolean;
  minimumScore: number;
  externalId: string;
  prerequisitesWithTitle: PrerequisitesType[];
  platformsAvailable: string[];
  moduleId: string;
};

type AssessmentFeedback = {
  userId: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userCustomFields: string[];
  userAvatarUrl: string;
  userIsAdministrator: boolean;
  id: string;
  content: string;
  status: AssessmentStatus;
  grade: number;
  timestamp: string;
};

type AssessmentsState = {
  fetchingAssessmentLoading: boolean;
  fetchingAssessmentError: string;
  fetchingAssessmentErrorCode: ErrorCode | undefined;
  assessments: {
    [id: string]: AssessmentType | undefined;
  };

  fetchingPostsLoading: boolean;
  fetchingPostsError: string;
  fetchingPostItemLoading: boolean;
  fetchingPostItemError: string;
  savingPostLoading: boolean;
  savingPostError: string;
  posts: {
    [assessmentId: string]: RequestTypes.PaginatedResponse<AssessmentPostType> | undefined;
  };

  fetchingPostCommentsLoading: boolean;
  fetchingPostCommentsError: string;
  savingPostCommentLoading: boolean;
  savingPostCommentError: string;
  postComments: {
    [postId: string]: RequestTypes.PaginatedResponse<AssessmentPostCommentType> | undefined;
  };

  fetchingProgressLoading: boolean;
  fetchingProgressError: string;
  progress: {
    [assessmentId: string]: AssessmentProgress | undefined;
  };

  fetchingFeedbackLoading: boolean;
  fetchingFeedbackError: string;
  feedback: {
    [postId: string]: AssessmentFeedback | undefined;
  };

  editPostSubscriptionLoading: boolean;
  editPostSubscriptionError: string;
};

export {
  AssessmentStatus,
  AssessmentProgress,
  AssessmentsState,
  AssessmentType,
  AssessmentPostType,
  AssessmentPostCommentType,
  AssessmentFeedback
};
