import * as React from 'react';

import styled, { css } from 'styled-components';

import { Box, Button, ChatIcon, EmptyStateCard, Typography } from '@edapp/ed-components';

import { ObservationStatus } from '../constants';

type AssessorFeedbackProps = {
  title?: string;
  status: ObservationStatus;
  feedback?: string;
  emptyCardTitle?: string;
  emptyCardDescription?: string;
  noFeedbackText?: string;
  downloadButtonText?: string;
  onFeedbackDownload?: () => void;
  type?: 'default' | 'pdf';
};

export const AssessorFeedback: React.FC<AssessorFeedbackProps> = ({
  title,
  status,
  feedback,
  emptyCardTitle,
  emptyCardDescription,
  noFeedbackText,
  downloadButtonText,
  onFeedbackDownload,
  type = 'default'
}) => {
  return (
    <StyledBox flex flexDirection="column">
      <Box pt={1} textAlign="left">
        <StyledTypography type={type} variant="subtext2" color="textMuted">
          {title || 'Assessor Feedback'}
        </StyledTypography>
        <StyledFeedbackBox textAlign="left" flexGrow={1}>
          {status === ObservationStatus.InProgress ? (
            <Box flex alignItems="center" justifyContent="center">
              <StyledEmptyStateCard
                icon={ChatIcon}
                title={emptyCardTitle || 'No feedback yet'}
                description={
                  emptyCardDescription ||
                  "You'll see feedback from your assessor after completing this practical assessment attempt."
                }
              />
            </Box>
          ) : feedback ? (
            feedback
          ) : (
            <Typography color="textMuted">
              {noFeedbackText || 'The assessor has not left a comment.'}
            </Typography>
          )}
        </StyledFeedbackBox>
      </Box>
      {status !== ObservationStatus.InProgress && !!onFeedbackDownload && (
        <Box pt={4} flex flexDirection="column" alignItems="flex-start">
          <StyledButton
            variant="primary"
            onClick={onFeedbackDownload}
            testId="observation-result-download-button"
          >
            {downloadButtonText || 'View full results'}
          </StyledButton>
        </Box>
      )}
    </StyledBox>
  );
};

const StyledEmptyStateCard = styled(EmptyStateCard)`
  background: none !important;
  box-shadow: none !important;
  ${ChatIcon} {
    width: 48px;
    height: 48px;
  }
`;

const StyledBox = styled(Box)`
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: ${({ theme }) => theme.flexboxgrid.breakpoints.md}rem;
`;

const StyledTypography = styled(Typography)<{ type?: 'default' | 'pdf' }>(
  ({ theme, type }) => css`
    padding-left: ${theme.space(type === 'default' ? 1 : 2)}px;
  `
);

const StyledButton = styled(Button)`
  @media (max-width: ${({ theme }) => theme.flexboxgrid.breakpoints.sm}rem) {
    width: 100%;
  }
`;

const StyledFeedbackBox = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.space(2)}px;
    background-color: ${theme.colors.white};
    border-radius: ${theme.sizes.borderRadius}px;
    margin-top: ${theme.space(1)}px;
  `
);
