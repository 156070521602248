import { ENV } from '@maggie/config/env';
import { OfflineAssets } from '@maggie/core/offlineAssets';
import { ConfigSelectors } from '@maggie/store/config/selectors';
import type { LxStoreState } from '@maggie/store/types';

import type { CourseSummaryType } from '../collections/types';
import { CourseSelectors } from '../courses/selectors';
import { CoursewareTypeNames } from '../types';
import type { PlaylistItemType, PlaylistStatus, PlaylistSummaryWithCompletionDate } from './types';
import { PlaylistUtils } from './utils';

const getCoursePlaylists = (store: LxStoreState, courseId: string): PlaylistItemType[] => {
  const course = store.courseware.courses.courses[courseId];
  if (!course) {
    return [];
  }
  const allPlaylist = Object.values(store.courseware.playlists.playlistItems);
  return allPlaylist.filter(p => p.courses.filter(c => c.courseId === courseId).length > 0);
};

const getPlaylistItems = (state: LxStoreState) => {
  const offlinePlaylists = Object.values(
    OfflineAssets.normalizeUrlsInDictionary(state.offline.courseware.playlists)
  );
  const playlistItems = ConfigSelectors.isOnline(state)
    ? state.courseware.playlists.playlists
    : PlaylistUtils.mapPlaylistsToPlaylistSummaries(offlinePlaylists);

  return playlistItems;
};

/**
 * Returns incompleted playlists before completed ones
 */
const getSortedPlaylists = (state: LxStoreState) => {
  return getPlaylistItems(state).sort((a, b) => {
    /**
     * a negative value if the first argument is less than the second argument,
     * zero if they're equal,
     * and a positive value otherwise
     */
    if (!a.isCompleted && !!b.isCompleted) {
      return -1;
    }

    return 0;
  });
};

const getPlaylistsTotalCount = (state: LxStoreState) => {
  return ConfigSelectors.isOnline(state)
    ? state.courseware.playlists.totalCount
    : Object.keys(state.offline.courseware.playlists).length;
};

const getIncompletePlaylists = (state: LxStoreState) => {
  return getPlaylistItems(state).filter(p => !p.isCompleted);
};

const getCompletedPlaylists = (state: LxStoreState): PlaylistSummaryWithCompletionDate[] => {
  const playlists = getPlaylistItems(state).filter(p => p.isCompleted);
  return playlists
    .map(p => {
      const completionDate = PlaylistUtils.getPlaylistCompletionDate(p, state);
      return !!completionDate
        ? {
            ...p,
            completionDate: completionDate.toISOString(),
            coursewareType: CoursewareTypeNames.PLAYLIST_SUMMARY
          }
        : null;
    })
    .filter(Boolean) as PlaylistSummaryWithCompletionDate[];
};

const getPlaylistsLoadingState = (state: LxStoreState) => {
  return state.courseware.playlists.fetchPlaylistsLoading;
};

const getPlaylistsError = (state: LxStoreState) => {
  return state.courseware.playlists.fetchPlaylistsError;
};

const getIsPlaylistItemLoading = (state: LxStoreState) => {
  return state.courseware.playlists.fetchPlaylistItemLoading;
};

const getPlaylistItemError = (state: LxStoreState) => {
  return state.courseware.playlists.fetchPlaylistItemError;
};

const getPlaylistItem = (id: string) => (state: LxStoreState) => {
  const playlistItem = ConfigSelectors.isOnline(state)
    ? state.courseware.playlists.playlistItems[id]
    : OfflineAssets.normalizeUrlsInDictionary(state.offline.courseware.playlists)[id];

  return playlistItem;
};

const getPlaylistStatus = (id: string) => {
  return (state: LxStoreState): PlaylistStatus => {
    const playlist = state.courseware.playlists.playlistItems[id];
    if (!playlist) {
      return 'new';
    }

    const courseIds = playlist.courses.map(c => c.courseId);
    const opened = courseIds.filter(cId => !!state.courseware.courses.coursesProgress[cId]?.opened);
    const attempted = opened.filter(
      cId => state.courseware.courses.coursesProgress[cId]?.percentageCompleted > 0
    );
    const completed = courseIds.filter(
      cId => !!state.courseware.courses.coursesProgress[cId]?.completed
    );

    if (completed.length === playlist.courseCount) {
      return 'completed';
    }

    if (attempted.length > 0) {
      return 'started';
    }

    return 'new';
  };
};

const getPlaylistCount = (state: LxStoreState) => {
  return getPlaylistItems(state).length;
};

const getFetchErrorCode = (state: LxStoreState) => {
  return state.courseware.playlists.fetchPlaylistItemErrorCode;
};

const getTitle = (state: LxStoreState, isSubtle?: boolean) => {
  if (isSubtle) {
    return '';
  }

  const playlistId = state.navigation.playlistId;
  const playlist = PlaylistsSelectors.getPlaylistItem(playlistId)(state);
  return playlist?.title || '';
};

const getTextColor = (state: LxStoreState) => {
  const playlistId = state.navigation.playlistId;
  const playlist = PlaylistsSelectors.getPlaylistItem(playlistId)(state);
  return playlist?.coverImageTextColor;
};

const getBackground = (state: LxStoreState) => {
  const playlistId = state.navigation.playlistId;
  const playlist = PlaylistsSelectors.getPlaylistItem(playlistId)(state);
  return ENV.imageUrl(playlist?.coverImage);
};

const getCourseForPlaylist = (playlistId: string) => (
  state: LxStoreState
): CourseSummaryType | undefined => {
  const playlist = PlaylistsSelectors.getPlaylistItem(playlistId)(state);
  const courses = playlist?.courses || [];

  const lastOpenedCourseId = state.navigation.course.id;
  const lastCourseOpened = courses.find(c => c.courseId === lastOpenedCourseId);

  if (!!lastCourseOpened) {
    return lastCourseOpened;
  }

  return courses[0];
};

const getFirstIncompleteCourse = (playlistId: string) => (state: LxStoreState) => {
  const playlistItem = getPlaylistItem(playlistId)(state);
  if (!playlistItem) {
    return undefined;
  }

  return playlistItem.courses.find(
    (course: CourseSummaryType) =>
      !CourseSelectors.getCourseProgress(course.courseId, state)?.completed
  );
};

export const PlaylistsSelectors = {
  getCoursePlaylists,
  getPlaylistItems,
  getSortedPlaylists,
  getPlaylistsTotalCount,
  getPlaylistItem,
  getPlaylistStatus,
  getPlaylistCount,

  getPlaylistsLoadingState,
  getPlaylistsError,
  getIsPlaylistItemLoading,
  getPlaylistItemError,

  getFetchErrorCode,
  getTitle,
  getTextColor,
  getBackground,
  getCourseForPlaylist,
  getIncompletePlaylists,
  getCompletedPlaylists,
  getFirstIncompleteCourse
};
