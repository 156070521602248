import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Box, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { StyledDialog, StyledDialogActionButton } from '@maggie/components/dialogs/styled';
import { LogoutActions } from '@maggie/store/logout/actions';
import { LogoutSelectors } from '@maggie/store/logout/selectors';
import { UserActions } from '@maggie/store/user/actions';

export const LogoutDialog: React.FC = () => {
  const { t } = useTranslation('learners-experience');
  const dispatch = useDispatch();

  const isOpen = useSelector(LogoutSelectors.getDialogOpen);

  const handleConfirm = () => {
    dispatch(UserActions.userLogout(true, false));
    dispatch(LogoutActions.closeLogoutDialog());
  };

  const handleCancel = () => {
    dispatch(LogoutActions.closeLogoutDialog());
  };

  return (
    <Dialog isOpen={isOpen}>
      <Box flex={true} flexDirection="column" flexGrow={1}>
        <Box flex={true} flexDirection="column" p="lg" justifyContent="center" alignItems="center">
          <Text variant="titleLarge" component="h2">
            {t('logout.title')}
          </Text>

          <Typography variant="paragraph" color="text" textAlign="center">
            {t('logout.main')}
          </Typography>
        </Box>

        <ActionButton color="navy" variant="transparent" onClick={handleConfirm}>
          {t('logout.confirm')}
        </ActionButton>

        <ActionButton color="navy" variant="transparent" onClick={handleCancel}>
          {t('logout.cancel')}
        </ActionButton>
      </Box>
    </Dialog>
  );
};

const Dialog = styled(StyledDialog)`
  @media only screen and (min-width: ${({ theme }) => theme.screens.screenSmMin}px) {
    width: 340px;
    margin: auto;
  }

  @media only screen and (min-width: ${({ theme }) => theme.screens.screenMdMin}px) {
    width: 420px;
  }
`;

const ActionButton = styled(StyledDialogActionButton)`
  font-weight: normal;
`;
