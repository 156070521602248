/* eslint-disable */
import React from 'react';

import { ColorsKeys } from '@edapp/themes';
import { ArgumentTypes } from '@edapp/utils';
import { Route } from '@igorrmotta/typesafe-react-router';
import { Tracker } from '@maggie/core/tracker/tracker';
import { NavigationIconNames } from '@maggie/layout/navigation/types';
import { splitViewLayoutSizes } from '@maggie/layout/view/route-split-view-layout/split-view-main/constants';
import type { LxStoreState } from '@maggie/store/types';

import { routePaths } from './routes';

export type GenericRoutesType = { [key: string]: Route<any> };
export type RoutesType = typeof routePaths;
export type RouteName = keyof RoutesType;
export type RouteParams<K extends RouteName> = ArgumentTypes<RoutesType[K]['create']>[0];

export type TrackedView = ReturnType<typeof Tracker.trackView>;

/**
 * The type of "header" in the old/normal route
 */
export type ViewHeadType = 'subtle' | 'fixed' | 'none';

/**
 * Defines the behaviour of the toolbar/header in the main.
 *
 *  - none: shows nothing
 *  - transparent: shows no background, just title and back button
 *  - sticky: shows an image, when user scrolls it sticks part of the image to the top.
 * includes back button and title
 *
 * @default none
 */
export enum ToolbarBehaviour {
  none = 'none',
  fixed = 'fixed',
  transparent = 'transparent',
  sticky = 'sticky'
}

export type BackButtonStyles = {
  color: ColorsKeys;
  bgColor: ColorsKeys;
};

export type SplitViewComponentProps<R extends RouteName> = {
  route: R;
  params: RouteParams<R>;
};

export type SplitViewLayoutItemType = {
  /**
   * Possible views that can be rendered on the main
   * when the one is in the detail
   */
  parents: RouteName[];

  /**
   * Component to display in the detail page
   * if the detail page does not exist
   */
  getDetailPlaceholder?: (state: LxStoreState) => React.ComponentType | null;

  main?: {
    /**
     * The size of container
     *
     * @default `md`
     */
    size?: keyof typeof splitViewLayoutSizes;

    /**
     * Overrides go back button's default colors
     */
    backButtonStyles?: BackButtonStyles;

    /**
     * Defines the behaviour of the toolbar/header in the main
     *
     * @default `transparent`
     */
    getToolbarBehaviour?: (state: LxStoreState) => ToolbarBehaviour;

    /**
     * Optional selector to get title of the page
     */
    getTitle?: (state: LxStoreState) => string;

    /**
     * Optional selector to get the text color
     *
     * @default 'white'
     */
    getToolbarTextColor?: (state: LxStoreState) => string | undefined;

    /**
     * Optional selector to get the background color for toolbar
     *
     * @default 'navy'
     */
    getToolbarBackgroundColor?: (state: LxStoreState) => ColorsKeys | undefined;

    /**
     * Optional selector to get the background image for toolbar
     */
    getToolbarBackground?: (state: LxStoreState) => string | undefined;
  };
};

export type ViewLayoutItemType = {
  /**
   * Tells the behaviour of the view head
   *
   *  - 'subtle': the view head is transparent until user scrolls (position: absolute)
   *  - 'fixed': the view head is always visible (position: relative)
   *  - 'none': the view head is hidden
   *
   * @default 'fixed'
   */
  getViewHeadType?: (
    state: LxStoreState
  ) => ViewHeadType | { mobile: ViewHeadType; desktop: ViewHeadType };
  /**
   * Optional selector to get title of the page
   * The title will be used in view-head/nav-bar and HTML doc title
   */
  getTitle?: (
    state: LxStoreState,
    isViewHeadSubtle?: boolean,
    routeParams?: RouteParams<RouteName>
  ) => string;
  /**
   * Optional selector to get the text color
   * The color will be used in view-head/nav-bar
   *
   * @default 'white'
   */
  getViewHeadTextColor?: (state: LxStoreState) => string | undefined;
  /**
   * Optional components to be rendered on navbar
   *
   * @default ['star-balance', 'brain-boost']
   */
  viewHeadComponents?: NavigationIconNames[];
};

export type RouteData = {
  /**
   * component to be rendered when route is accessed
   */
  component: React.ComponentType<{} | SplitViewComponentProps<any>>;

  /**
   * Template path of the route - like 'course/:id'
   */
  path: string;

  /**
   * The level (z-index) of the view
   */
  stackIndex: number;

  /**
   * Tells that route should hide top bar navigation
   */
  locked?: boolean;

  /**
   * The properties that define the layout and how it should behave
   */
  viewLayout?: ViewLayoutItemType;

  /**
   *
   * If defined, it means component will be rendered inside the split view layout.
   *
   * Notice that all navigations that should be done inside splitViewLayout should be done
   * using the props that will get injected in the container.
   *
   * The `component` of this route will have `SplitViewComponentProps` injected when the property is defined.
   *
   * @alpha
   * ! This is a feature in experiment and it's likely to change APIs in the future.
   */
  splitViewLayout?: SplitViewLayoutItemType;
  /**
   * If false, there will be no transition effect changing the route.
   */
  transitionEffect?: boolean;
};

export {};
