import * as React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Avatar, Box, Typography } from '@edapp/ed-components';
import { useAppearance } from '@edapp/themes';
import ED_LOGO_INVERT from '@maggie/assets/img/new-ed-logo-invert.png';
import ED_LOGO from '@maggie/assets/img/new-ed-logo.png';
import { Link } from '@maggie/components/link/Link';
import { NotificationsDropdown } from '@maggie/components/notifications/dropdown';
import { routePaths } from '@maggie/core/router/routes';
import { AppLayoutContext } from '@maggie/layout/AppLayoutContext';
import { useMenuItems } from '@maggie/layout/hooks/useMenuItems';
import { MaxWidthLayoutStyles } from '@maggie/layout/styled';
import { UserSelectors } from '@maggie/store/user/selectors';

import { TopBarContainer } from '../styled';
import { MenuItemTypography } from './MenuItem';
import { SearchInputWithDropdown } from './SearchInputWithDropdown';

export const NewDesktopNavigation: React.FC = () => {
  const { closeHomeSearch } = React.useContext(AppLayoutContext);
  const menuItems = useMenuItems();
  const { isDarkMode } = useAppearance();

  const userName = useSelector(state => UserSelectors.getUserFriendlyName(state.user));
  const userAvatarUrl = useSelector(UserSelectors.getAvatarUrl);

  const homePath = React.useMemo(() => menuItems.find(i => i.path === routePaths.home.create({})), [
    menuItems
  ]);

  const profilePath = React.useMemo(
    () => menuItems.find(i => i.path === routePaths.profile.create({})),
    [menuItems]
  );

  return (
    <TopBarContainer data-testid="app-desktop-nav">
      <StyledTopBarContent>
        <Box flex flexDirection="row" alignItems="center">
          {homePath && (
            <>
              <Typography testId="ed-logo" px={1.5}>
                <Link to={homePath.path}>
                  <StyledLogo src={isDarkMode ? ED_LOGO_INVERT : ED_LOGO} width={28} />
                </Link>
              </Typography>

              <MenuItemTypography to={homePath.path} activeClassName="active">
                {homePath.title}
              </MenuItemTypography>
            </>
          )}
        </Box>

        <Box flex flexDirection="row" alignItems="center" gap={2}>
          <SearchInputWithDropdown />

          <NotificationsDropdown />

          {profilePath && (
            <StyledLink onClick={closeHomeSearch} to={profilePath.path}>
              <Avatar alt={userName} src={userAvatarUrl} />
            </StyledLink>
          )}
        </Box>
      </StyledTopBarContent>
    </TopBarContainer>
  );
};

const StyledLink = styled(Link)`
  margin-left: ${({ theme }) => theme.space(2)}px;
`;

const StyledLogo = styled.img`
  vertical-align: middle;
`;

const StyledTopBarContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  ${MaxWidthLayoutStyles}
`;
