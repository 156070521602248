import * as React from 'react';

import * as DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Box, Button, Divider, Typography } from '@edapp/ed-components';
import { usePrevious } from '@edapp/ed-components/hooks';
import { Text } from '@edapp/sc-web-ui';
import {
  StyledDialog as Dialog,
  StyledDialogActionButton
} from '@maggie/components/dialogs/styled';
import { ConferenceActions } from '@maggie/store/courseware/conferences/actions';
import type { UpcomingConference } from '@maggie/store/courseware/conferences/types';
import { CourseSelectors } from '@maggie/store/courseware/courses/selectors';
import type { CourseType } from '@maggie/store/courseware/courses/types';
import { LessonActions } from '@maggie/store/courseware/lessons/actions';
import { CoursewareUtils } from '@maggie/store/courseware/utils';
import type { LxStoreState } from '@maggie/store/types';
import { globalZIndex } from '@maggie/theme/globalZIndex';

export const ConferenceReminderDialog: React.FC = () => {
  const { t } = useTranslation('learners-experience');
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState(false);

  const isOnHomeRoute = useSelector((state: LxStoreState) => state.navigation.route === 'home');

  const upcomingConferences = useSelector(
    (state: LxStoreState) => state.courseware.conferences.upcoming.items
  );

  const firstUpcomingConference: UpcomingConference | undefined = upcomingConferences[0];

  const parentCourse: CourseType | undefined = useSelector((state: LxStoreState) =>
    firstUpcomingConference
      ? state.courseware.courses.courses[firstUpcomingConference.courseId]
      : undefined
  );

  const arePrerequisitesAvailableAndCompleted = useSelector((state: LxStoreState) => {
    return !!parentCourse?.prerequisites.every(
      prereqCourseId => !!state.courseware.courses.coursesProgress[prereqCourseId]?.completed
    );
  });

  const isCourseAvailable = useSelector((state: LxStoreState) => {
    if (!parentCourse) {
      return false;
    }

    return CourseSelectors.isCourseAvailable(
      parentCourse.id,
      parentCourse.planning.start,
      parentCourse.planning.end,
      state,
      {
        isLockedByDefault: true
      }
    );
  });

  const previousUpcomingConferences = usePrevious(upcomingConferences) ?? [];

  // Condition for opening the dialog
  React.useEffect(() => {
    if (
      isOnHomeRoute &&
      upcomingConferences.length &&
      arePrerequisitesAvailableAndCompleted &&
      isCourseAvailable
    ) {
      setIsOpen(true);
    }
  }, [
    isOnHomeRoute,
    upcomingConferences,
    arePrerequisitesAvailableAndCompleted,
    isCourseAvailable
  ]);

  // Condition for closing the dialog
  React.useEffect(() => {
    if (
      isOpen &&
      (!isOnHomeRoute || (previousUpcomingConferences.length && !upcomingConferences.length))
    ) {
      setIsOpen(false);
    }
  }, [isOpen, isOnHomeRoute, previousUpcomingConferences, upcomingConferences]);

  const coverImage = parentCourse
    ? CoursewareUtils.getBrandingStyleObject(parentCourse).background.image
    : undefined;

  const upcomingConferencesForCourse = upcomingConferences.filter(
    conference => conference.courseId === firstUpcomingConference?.courseId
  );

  const sendOpenLessonInteractions = () => {
    upcomingConferencesForCourse.forEach(conference => {
      dispatch(LessonActions.updateLessonOpened(conference.id, conference.courseVersionNumber));
    });
  };

  const handleClickActionButton = () => {
    closeDialog();

    if (upcomingConferencesForCourse.length > 1) {
      // We aren't navigating to the conferences - we need to send 'lesson-open' interactions
      // for them so they aren't returned as upcoming conferences again
      sendOpenLessonInteractions();

      if (parentCourse) {
        window.__router.navigate('course', { id: parentCourse.id });
      }
    } else {
      window.__router.navigate(`conference`, { id: firstUpcomingConference.id });
    }
  };

  const closeDialog = () => {
    setIsOpen(false);
    window.setTimeout(() => {
      dispatch(ConferenceActions.clearUpcomingConferences());
    }, 1000);
  };

  const handleClickDismiss = () => {
    sendOpenLessonInteractions();
    closeDialog();
  };

  const parentCourseTitle = parentCourse?.title ?? '';

  return (
    <ReminderDialog
      isOpen={isOpen}
      usePortal={false}
      dialogWrapperStyle={{ zIndex: globalZIndex.CONFERENCE }}
    >
      <FullWidthBox
        boxSizing="border-box"
        flex={true}
        flexDirection="column"
        textAlign="center"
        alignItems="center"
      >
        {!!coverImage && <CoverImage url={coverImage} />}

        <FullWidthBox boxSizing="border-box" py="md" px="lg">
          <TitleWithMargin variant="subtitle2" color="navyMuted">
            {t('virtual-classrooms.upcomingSession')}
          </TitleWithMargin>

          {upcomingConferencesForCourse.length > 1 ? (
            <>
              <CourseTitle
                color="navyHover"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    styleMultipleConferencesText(
                      parentCourseTitle,
                      t('virtual-classrooms.multipleUpcoming', {
                        course_title: parentCourseTitle
                      })
                    )
                  )
                }}
              />
              <ActionButton variant="light" onClick={handleClickActionButton}>
                {t('virtual-classrooms.viewDetails')}
              </ActionButton>
            </>
          ) : (
            <>
              <Text variant="titleMedium" component="h3">
                {firstUpcomingConference?.title}
              </Text>
              <Divider margin="sm" />
              <TitleWithMargin variant="subtitle2" color="navyMuted">
                {t('types.course')}
              </TitleWithMargin>
              <CourseTitle color="navyHover">{firstUpcomingConference?.courseTitle}</CourseTitle>
              <ActionButton variant="light" onClick={handleClickActionButton}>
                {t('virtual-classrooms.viewDetails')}
              </ActionButton>
            </>
          )}
        </FullWidthBox>

        <DismissButton color="navy" variant="transparent" onClick={handleClickDismiss}>
          {t('common.dismiss')}
        </DismissButton>
      </FullWidthBox>
    </ReminderDialog>
  );
};

// We style the course title like this because it's part of an i18n string,
// where the course title may not always be at the same location in the string
function styleMultipleConferencesText(courseTitle: string, i18nString: string) {
  const titleIndex = i18nString.indexOf(courseTitle);

  if (titleIndex === -1) {
    return i18nString;
  }

  return (
    i18nString.substr(0, titleIndex) +
    '<strong>' +
    courseTitle +
    '</strong>' +
    i18nString.substr(titleIndex + courseTitle.length)
  );
}

const ReminderDialog = styled(Dialog)`
  width: 350px;
`;

const FullWidthBox = styled(Box)`
  width: 100%;
`;

const CoverImage = styled.div<{ url: string }>`
  background: ${({ url }) => url} no-repeat center;
  background-size: cover;
  width: 100%;
  height: 120px;
`;

const TitleWithMargin = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.space()}px;
  font-size: 13px;
`;

// TODO: https://safetyculture.atlassian.net/browse/TRAINING-519
const CourseTitle = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
`;

const ActionButton = styled(Button)`
  margin-top: ${({ theme }) => theme.space(3)}px;
  min-width: 180px;
  font-size: 18px;
`;

const DismissButton = styled(StyledDialogActionButton)`
  padding: ${({ theme }) => theme.space(2)}px;
  font-size: 14px;
  font-weight: 400;
`;
