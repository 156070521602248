import { css } from 'styled-components';

import type { ViewHeadType } from '@maggie/core/router/types';
import type { ThemeType } from '@maggie/theme/theme';

export const getViewTypeStyles = (
  viewType: ViewHeadType | { mobile: ViewHeadType; desktop: ViewHeadType },
  isMobile: boolean,
  theme: ThemeType
) => {
  const type = strictViewType(viewType, isMobile);

  switch (type) {
    case 'fixed': {
      return {
        viewHead: css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        `,
        viewBody: css`
          margin-top: ${theme.getNavbarHeight()}px;
          margin-top: calc(env(safe-area-inset-top) + ${theme.getNavbarHeight()}px);
          margin-top: calc(constant(safe-area-inset-top) + ${theme.getNavbarHeight()}px);
        `
      };
    }

    case 'subtle': {
      return {
        viewHead: css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        `,
        viewBody: css``
      };
    }

    case 'none':
      return {
        viewHead: css`
          display: none;
        `,
        viewBody: !!isMobile
          ? css`
              margin-top: -${theme.getNavbarHeight()}px;
              margin-top: -calc(env(safe-area-inset-top) + ${theme.getNavbarHeight()}px);
              margin-top: -calc(constant(safe-area-inset-top) + ${theme.getNavbarHeight()}px);
            `
          : css``
      };
  }
};

export const strictViewType = (
  viewType: ViewHeadType | { mobile: ViewHeadType; desktop: ViewHeadType },
  isMobile: boolean
) => {
  return typeof viewType === 'object' ? (isMobile ? viewType.mobile : viewType.desktop) : viewType;
};
