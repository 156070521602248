import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Box, CrossIcon, IconButton, QuestionIcon, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { brainBoostBackground } from '@maggie/components/brainboost/constants';
import { FullWindowModal } from '@maggie/components/common/FullWindowModal/FullWindowModal';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';

export const BrainboostInfoIcon: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation('learners-experience');
  const [open, setOpen] = React.useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const isMobile = useIsMobile();

  return (
    <>
      <StyledIconButton
        isMobile={isMobile}
        icon={StyledQuestionIcon}
        onClick={onOpen}
        iconSize="md"
        iconColor="textMuted"
        variant="transparent"
        className={className}
      />
      <StyledFullWindowModal open={open}>
        <StyledCrossButton
          icon={CrossIcon}
          onClick={onClose}
          iconSize="md"
          iconColor="textMuted"
          variant="transparent"
        />
        <ContentWrapper p="md" boxSizing="border-box">
          <Box mb={2}>
            <Text variant="titleLarge" component="h2" color="white.default">
              {t('brain-boost.title')}
            </Text>
          </Box>
          <Typography as="p" color="fixedWhite">
            {t('brain-boost.bb-info-paragraph-1')}
          </Typography>
          <Typography as="p" color="fixedWhite">
            {t('brain-boost.bb-info-paragraph-2')}
          </Typography>
          <Typography as="p" color="fixedWhite">
            {t('brain-boost.bb-info-paragraph-3')}
          </Typography>
        </ContentWrapper>
      </StyledFullWindowModal>
    </>
  );
};

const ContentWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  max-width: 480px;
  margin: ${({ theme }) => theme.space(15)}px auto;
`;

const StyledCrossButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.space(3)}px;
    right: ${theme.space(2)}px;
    z-index: 15;
    :hover:enabled {
      background-color: transparent;
    }
  `
);

const StyledQuestionIcon = styled(QuestionIcon)`
  cursor: pointer;
`;

const StyledIconButton = styled(IconButton)<{ isMobile: boolean }>`
  padding-top: ${({ theme }) => theme.space(1)}px;
  :hover:enabled {
    background-color: transparent;
  }
  :enabled:focus {
    background-color: transparent;
  }
`;

const StyledFullWindowModal = styled(FullWindowModal)`
  background-image: url(${brainBoostBackground});
  background-position: center;
  background-size: cover;
  text-align: center;
  align-items: center;
  flex-direction: column;
`;
