import { createAction } from '@edapp/utils';
import type { AttemptSlideInteraction } from '@maggie/core/lessons/attempt/types';

export enum RapidRefreshActionTypes {
  LAUNCH_RAPID_REFRESH = 'LAUNCH_RAPID_REFRESH',

  SET_RAPID_REFRESH_SUMMARY_DIALOG_OPEN = 'SET_RAPID_REFRESH_SUMMARY_DIALOG_OPEN',

  COMPLETE_RAPID_REFRESH = 'COMPLETE_RAPID_REFRESH',
  DID_COMPLETE_RAPID_REFRESH = 'DID_COMPLETE_RAPID_REFRESH',
  UPDATE_RAPID_REFRESH_SCORE = 'UPDATE_RAPID_REFRESH_SCORE',

  FETCH_RAPID_REFRESH_LIST = 'FETCH_RAPID_REFRESH_LIST',
  FETCH_RAPID_REFRESH_LIST_SUCCESS = 'FETCH_RAPID_REFRESH_LIST_SUCCESS',
  FETCH_RAPID_REFRESH_LIST_FAILURE = 'FETCH_RAPID_REFRESH_LIST_FAILURE',

  FETCH_RAPID_REFRESH_ITEM = 'FETCH_RAPID_REFRESH_ITEM',
  FETCH_RAPID_REFRESH_ITEM_SUCCESS = 'FETCH_RAPID_REFRESH_ITEM_SUCCESS',
  FETCH_RAPID_REFRESH_ITEM_FAILURE = 'FETCH_RAPID_REFRESH_ITEM_FAILURE',
  FETCH_RAPID_REFRESH_ITEM_COMPLETED = 'FETCH_RAPID_REFRESH_ITEM_COMPLETED',

  DID_OPEN_RAPID_REFRESH = 'DID_OPEN_RAPID_REFRESH'
}

export const RapidRefreshActions = {
  launchRapidRefresh: (courseId: string, session: number) =>
    createAction(RapidRefreshActionTypes.LAUNCH_RAPID_REFRESH, { courseId, session }),

  setRapidRefreshDialogOpen: (open: boolean) =>
    createAction(RapidRefreshActionTypes.SET_RAPID_REFRESH_SUMMARY_DIALOG_OPEN, { open }),

  /**
   * This happens when rapid refresh session is finished
   */
  completeRapidRefresh: (courseId: string, interactions: AttemptSlideInteraction[]) =>
    createAction(RapidRefreshActionTypes.COMPLETE_RAPID_REFRESH, { courseId, interactions }),

  didCompleteRapidRefresh: (courseId: string, session: number) =>
    createAction(RapidRefreshActionTypes.DID_COMPLETE_RAPID_REFRESH, { courseId, session }),
  updateRapidRefreshScore: (score: number) =>
    createAction(RapidRefreshActionTypes.UPDATE_RAPID_REFRESH_SCORE, { score }),

  fetchRapidRefreshList: () => createAction(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LIST, {}),

  /**
   * This action is only triggered if you're navigating straight to URL of a rapid refresh.
   * Otherwise, we assume that the fetchRapidRefreshList has already made the item available.
   */
  fetchRapidRefreshItem: (courseId: string, session: number) =>
    createAction(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM, { courseId, session }),
  fetchRapidRefreshItemCompleted: () =>
    createAction(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_ITEM_COMPLETED, {}),

  didOpenRapidRefresh: (courseId: string, session: number) =>
    createAction(RapidRefreshActionTypes.DID_OPEN_RAPID_REFRESH, { courseId, session })
};
