import { useSelector } from 'react-redux';

import { StatusBarManager } from '@maggie/cordova/status-bar';
import type { RouteName } from '@maggie/core/router/types';
import { AppLayoutSelectors } from '@maggie/layout/selectors';
import { UNAUTHENTICATED_ROUTES } from '@maggie/store/navigation/handlers/access-sagas';

type HookOptionsType = {
  routeName?: RouteName;
  isMobile?: boolean;
};

export const useSetStatusBarStyle = ({ routeName, isMobile = false }: HookOptionsType) => {
  const viewHeadType = useSelector(AppLayoutSelectors.getViewHeadType(routeName));
  const isLocked = useSelector(AppLayoutSelectors.getIsLocked(routeName));
  if (!routeName) {
    return;
  }

  /**
   * display light text, if route is:
   * - non unauthenticated route
   * - and,
   * - route has a view-head subtle and is mobile or route is locked (no navigation)
   */
  const isLightContent =
    !UNAUTHENTICATED_ROUTES.includes(routeName) &&
    ((viewHeadType === 'subtle' && isMobile) || isLocked);

  if (isLightContent) {
    StatusBarManager.setLightContent();
  } else {
    StatusBarManager.setDefault();
  }
};
