import * as React from 'react';

type MainToolbarContextType = {
  /**
   * The current position of the scroll
   * if it's controlled by the toolbar behaviour
   */
  scrollTop: number;
  setScrollTop: (scrollTop: number) => void;

  /**
   * The offset position of the title.
   * Used for animation/transition
   */
  titleOffset: number;
  setTitleOffset: (pos: number) => void;

  /**
   * The title element that should control the transition of sticky header
   * It's used to calculate based on scroll.
   * When scroll past the element, it trasitions its y axis
   */
  titleRef?: React.RefObject<HTMLHeadingElement>;
};

export const MainToolbarContext = React.createContext<MainToolbarContextType>({
  scrollTop: 0,
  setScrollTop: () => ({}),
  titleOffset: 0,
  setTitleOffset: () => ({}),
  titleRef: undefined
});

export const MainToolbarContextProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [titleOffset, setTitleOffset] = React.useState(0);
  const [scrollTop, setScrollTop] = React.useState(0);
  const titleRef = React.useRef<HTMLHeadingElement | null>(null);

  // set the title offset to get transition happening in toolbar
  React.useEffect(() => {
    if (scrollTop < 0) {
      return; // do nothing
    }

    const offset = titleRef.current ? titleRef.current.offsetTop - scrollTop : 100;
    setTitleOffset(offset);
  }, [scrollTop, titleRef.current]);

  return (
    <MainToolbarContext.Provider
      value={{ scrollTop, setScrollTop, titleOffset, setTitleOffset, titleRef }}
    >
      {children}
    </MainToolbarContext.Provider>
  );
};
