/**
 * This comes from `hippo/src/Hippo.Domain/Exceptions/ErrorCode.cs`
 */
export enum ErrorCode {
  /**
   * This when the error is not from hippo - it's communication related
   * Could be caused by connection dropped, or slow connection
   */
  NullResponse = 'NullResponse',

  /**
   * This is when hippo doesn't tell us what the error-code is
   * We can either use the message that hippo returns or use a generic error message
   */
  NotSpecified = 'NotSpecified',

  /**
   * An application has a SSO domain already defined that matches the email domain.
   */
  EmailDomainAlreadyInUse = 'EmailDomainAlreadyInUse',

  /**
   * Forgot Password has been called with wrong email
   */
  ForgotPasswordErrorCredentials = 'ForgotPasswordErrorCredentials',

  /**
   * Forgot Password had an error in the server side
   */
  ForgotPasswordServerError = 'ForgotPasswordServerError',

  /**
   * From emily
   * Tells when invite code has already been registered with that email
   */
  EmailAlreadyRegistered = 'email-already-registered',

  /*
   * From emily
   * When IL tries to register with an organizational email instead of a personal.
   */
  EmailIsNotPersonal = 'email-is-not-personal',

  /**
   * User tried to register with email that already exists in the same account
   * as the invite code requested
   */
  EmailExistsForSameAccount = 'EmailExistsForSameAccount',

  /**
   * From emily
   * Number of invited users has reached its limit
   */
  UserLimitReached = 'User limit reached',

  /**
   * SSO-Check error
   */
  BusinessIdNotFound = 'BusinessIdNotFound',

  /**
   * Tried to do an sso login with a token that's not valid
   */
  InvalidSsoToken = 'InvalidSsoToken',

  /**
   * Invalid username or password
   */
  InvalidCredentials = 'InvalidCredentials',

  /**
   * Account is locked. User has to wait to try again.
   */
  AccountLocked = 'AccountLocked',

  InvalidUsername = 'InvalidUsername',
  InvalidPassword = 'InvalidPassword',
  EmailVerificationPending = 'EmailVerificationPending',
  InvalidPasswordStrength = 'InvalidPasswordStrength',
  InvalidEmailAddress = 'InvalidEmailAddress',
  UsernameNotUnique = 'UsernameNotUnique',
  AnonymousRegistrationAlreadyComplete = 'AnonymousRegistrationAlreadyComplete',
  MagicLinkIsNotAllowed = 'MagicLinkIsNotAllowed',
  InvitedUserIsNotVerified = 'InvitedUserIsNotVerified',
  ResetPasswordIsNotAllowed = 'ResetPasswordIsNotAllowed',

  /**
   * User doesn't user group have permissions to access the courseware.
   */
  UserDoesNotHaveAccessToCourseware = 'UserDoesNotHaveAccessToCourseware',
  /**
   * Courseware has not been published yet
   */
  CoursewareIsNotPublished = 'CoursewareIsNotPublished',
  AuthenticationFailed = 'AuthenticationFailed',
  IntegrationRequestError = 'IntegrationRequestError',

  /**
   * Registering user with profile settings that are invalid
   */
  ProfileFieldIsNotValid = 'ProfileFieldIsNotValid',

  /**
   * Invite code not found
   */
  InviteCodeNotFound = 'InviteCodeNotFound',
  MultipleErrorsOccurred = 'MultipleErrorsOccurred',

  /**
   * File too big
   */
  FileSizeError = 'FileSizeError'
}

interface ErrorResponse {
  message: string;
  code: ErrorCode;
  statusCode?: number;
}

export interface ValidationErrorResponseType extends ErrorResponse {
  type: 'ValidationError';
  validationErrors: {
    [fieldName: string]: string[];
  };
}

export interface ErrorResponseType extends ErrorResponse {
  type: 'Error';
  /**
   * This array appears when code = 'MultipleErrorsOccurred'
   */
  errors?: Array<{
    code: string;
    message: string;
  }>;
}
