import { ErrorLogger } from '@edapp/monitoring';
import type { DictionaryType } from '@edapp/utils';
import { Sound } from '@maggie/cordova/sound';
import type { SlideType } from '@maggie/store/courseware/lessons/types';
import { createUUID } from '@maggie/utils/uuid';

import type {
  ThomasSlideInteraction,
  ThomasViewInteraction
} from '../thomas/thomas-interaction-types';
import { slidedeckPrefix } from '../thomas/utils';
import type { IAttempt } from './attempt-interface';
import type { AttemptSlideInteraction } from './types';

/**
 * Attempt is an abstract class that listens to `view` and `interaction` events.
 *
 * Each derived class is responsible to have its own implementation and definition of score and success.
 *
 * @export
 * @class Attempt
 */
export abstract class Attempt implements IAttempt {
  /**
   * Unique id of attempt
   */
  protected _attemptId: string;
  /**
   * The timestamp of when a lesson attempt starts.
   */
  protected _startedAt: number;
  /**
   * The timestamp of when a lesson attempt ends.
   */
  protected _endedAt: number;
  /**
   * The score of the lesson attempt.
   */
  protected _score: number;
  /**
   * Tells if user passed or not the completion criteria.
   *
   * The logic may vary if scoring is enabled or disabled.
   * The logic may vary if SCORM or AICC.
   */
  protected _successful: boolean;
  /**
   * Interactions that were raised while attempt was in progress.
   */
  protected _interactions: DictionaryType<AttemptSlideInteraction>;
  /**
   * View Interactions that were raised while attempt was in progress
   */
  protected _views: ThomasViewInteraction[];
  /**
   * Slides used to calculate the attempt
   */
  protected _slides: SlideType[];
  /**
   * To listen to backbone events.
   * We encapsulate so we can start/stop listening to same events as the outsiders.
   */
  protected _eventManager: Backbone.Events;

  constructor(
    slides: SlideType[],
    id?: string,
    interactions: DictionaryType<AttemptSlideInteraction> = {}
  ) {
    this._eventManager = { ...window.Backbone.Events } as any;
    this._attemptId = id || createUUID();
    this._slides = slides;
    this._interactions = interactions;
    this._views = [];

    this.recordView = this.recordView.bind(this);
    this.recordInteraction = this.recordInteraction.bind(this);
  }

  protected calculateScore() {
    return 0;
  }

  protected recordView(view: ThomasViewInteraction) {
    this._views.push(view);
  }

  protected recordInteraction(interaction: ThomasSlideInteraction) {
    this._interactions[interaction.id] = interaction;

    // Give user feedback
    if (!interaction.playedSound && interaction.correct != null) {
      const id = interaction.correct ? 'correct.mp3' : 'incorrect.mp3';
      Sound.play(id);
    }
  }

  public hasSeenLastOrExitSlide() {
    const slides = this._slides;
    if (slides.length === 0) {
      return false;
    }

    const slidesWithoutExitSlide = slides.filter(slide => slide.type !== 'exit');
    if (slidesWithoutExitSlide.length === 0) {
      return true;
    }
    const lastSlideId = slidesWithoutExitSlide[slidesWithoutExitSlide.length - 1]?.id;
    return this._views.some(view => view.id === lastSlideId);
  }

  public startAttempt() {
    if (this.isAttemptStarted) {
      ErrorLogger.captureEvent('Attempted to start attempt multiple times', 'error', {
        started: this._startedAt
      });
      return;
    }

    this._startedAt = Date.now();
  }

  public startRecording(id: string) {
    const prefix = slidedeckPrefix(id);
    this._eventManager.listenTo(Backbone.Events, `${prefix}:interaction`, this.recordInteraction);
    this._eventManager.listenTo(Backbone.Events, `${prefix}:view`, this.recordView);
  }

  public finishAttempt() {
    if (this.isAttemptFinished) {
      ErrorLogger.captureEvent('Attempted to finish attempt multiple times', 'error', {
        ended: this._endedAt,
        hasSeenLastSlide: this.hasSeenLastOrExitSlide()
      });
      return;
    }

    this._endedAt = Date.now();
    this._score = this.calculateScore();
  }

  public stopRecording(id: string) {
    const prefix = slidedeckPrefix(id);
    this._eventManager.stopListening(Backbone.Events, `${prefix}:interaction`);
    this._eventManager.stopListening(Backbone.Events, `${prefix}:view`);
  }

  public get attemptId() {
    return this._attemptId;
  }

  public get startedAt() {
    return this._startedAt;
  }

  public get isAttemptStarted() {
    return Boolean(this._startedAt);
  }

  public get endedAt() {
    return this._endedAt;
  }

  public get isAttemptFinished() {
    return Boolean(this._endedAt);
  }

  public get score() {
    return this._score;
  }

  public get successful() {
    return this._successful;
  }

  public get interactions() {
    return Object.values(this._interactions);
  }
}
