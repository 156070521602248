import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyleConfiguration } from '@edapp/authoring-logic';
import { Box, QRCode, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

type ObservationHeaderProps = {
  name: string;
  description?: string;
  subtitle: string;
  qrCodeURL: string;
  config?: Pick<StyleConfiguration, 'background' | 'colors'>;
  hasWatermark?: boolean;
};

export const ObservationHeader: React.FC<ObservationHeaderProps> = ({
  name,
  description,
  subtitle,
  qrCodeURL,
  config,
  hasWatermark = false,
  ...rest
}) => {
  return (
    <StyledWrapper
      config={config}
      flex
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      p={4}
      pt={10}
      {...rest}
    >
      <TextBox>
        <Text
          variant="titleMedium"
          component="h3"
          clamp={2}
          data-testid="observation-title"
          color="currentColor"
        >
          {name}
        </Text>
        {description && (
          <ObservationDescription color="inherit">{description}</ObservationDescription>
        )}
      </TextBox>
      <Box pl={3} pr={3} flex flexDirection="column" alignItems="center">
        <StyledQRCodeBox>
          <QRCode size={200} value={qrCodeURL} />
          {hasWatermark && (
            <StyledWatermark
              variant="subtitle1"
              color="greyActive"
              textTransform="uppercase"
              p="xs"
              weight="600"
            >
              Preview only
            </StyledWatermark>
          )}
        </StyledQRCodeBox>
        <Typography variant="subtext" color="inherit">
          {subtitle}
        </Typography>
      </Box>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)<Pick<ObservationHeaderProps, 'config'>>`
  ${({ config, theme }) => css`
    background-color: ${config?.colors?.background || theme.colors.blue};
    background-image: ${!!config?.background ? `url("${config.background}")` : 'none'};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: ${config?.colors?.text || theme.colors.white};
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
  `}
`;

const ObservationDescription = styled(Typography)`
  margin-top: ${({ theme }) => theme.space()}px;
  display: block;
  text-align: center;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  max-height: 200px;
  overflow-y: auto;
`;

const TextBox = styled(Box)`
  padding-bottom: ${({ theme }) => theme.space(4)}px;
`;

const StyledQRCodeBox = styled(Box)(
  ({ theme }) => css`
    width: fit-content;
    padding: ${theme.space(2)}px;
    background-color: ${theme.colors.fixedWhite};
    border-radius: ${theme.sizes.borderRadius}px;
    margin-bottom: ${theme.space(2)}px;
    position: relative;
  `
);

const StyledWatermark = styled(Typography)(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: ${theme.colors.white};
    width: 100%;
  `
);
