/**
 *
 * <<< MFE (Training) ENTRY FILE >>>
 *
 */
/// <reference types="./theme/styled-components" />
/// <reference types="./cordova/global" />

/**
 * Backbone is required to be available by the app.
 * Most places could be used from an import.
 * However, the communication in the thomas-bridge will run the
 * `Backbone.Events.trigger` in the window and require object to be available.
 */
import * as Backbone from 'backbone';
import { put, race, take } from 'redux-saga/effects';

import type { LoadOptions } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import type { RelativeToken } from '@edapp/request';
import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { NetworkMonitor } from '@maggie/core/network-monitor';
import { NewAppRouter } from '@maggie/core/router/router';
import { launchAnalyticTools, launchBrazeTools } from '@maggie/core/vendor-toolings';
import { FeatureFlagsActions } from '@maggie/store/feature-flags/actions';
import rootSaga from '@maggie/store/rootSagas';
import { SessionActionTypes, SessionActions } from '@maggie/store/session/actions';
import { configureStore } from '@maggie/store/store';

import './cordova/types';
import { InteractionApi } from './core/tracker/interaction-api';
import { addOnlineConfigListener } from './store/config/online-config-listener';
import './store/types';
import './theme/theme';
import './types';

export { App } from '@maggie/app/App';
export { Theme } from '@maggie/theme/theme';
export { FeatureFlagsProvider } from '@maggie/components/feature-flags/FeatureFlagsProvider';
export { loadEnv } from '@maggie/config/env';

window.Backbone = Backbone;

function* consumeTokens(tokens: RelativeToken[]): any {
  if (!tokens || tokens.length === 0) {
    ErrorLogger.captureEvent('No tokens available to consume', 'critical', {});
    HostedWebviewUtils.triggerBootstrapError('failed_to_consume_tokens');
    return;
  }

  if(window.__scWebview.platform && !window.__scWebview.isOnline) {
    return; // do not try to start session - the device is offline
  }

  yield put(SessionActions.sessionLogin(tokens, { redirect: false, skipLocaleCheck: true }));
  const { failure } = yield race({
    success: take(SessionActionTypes.SESSION_LOGIN_SUCCESS),
    failure: take(SessionActionTypes.SESSION_LOGIN_FAILURE)
  });

  if (failure) {
    ErrorLogger.captureEvent('Failed to sync user when consuming tokens', 'critical', {
      count: tokens.length,
      min: tokens.map(t => t.value.substring(0, 5))
    });
    HostedWebviewUtils.triggerBootstrapError('failed_to_consume_tokens');
  }
}

export const initialize = async (tokens: RelativeToken[], segmentOptions: Partial<LoadOptions>) => {
  // Segment + Itly
  launchAnalyticTools(segmentOptions);

  // Braze
  launchBrazeTools();

  // Datastore
  const { store, runSaga } = await configureStore();

  addOnlineConfigListener(store);

  window.__networkMonitor = new NetworkMonitor(store);
  window.__router = new NewAppRouter(store, '/training/learn');

  await runSaga(rootSaga);
  await runSaga(consumeTokens, tokens).done; // await for this saga to finish before proceeding

  InteractionApi.startupInteractionsApi();

  // Fetch Feature Flags
  store.dispatch(FeatureFlagsActions.fetchFeatureFlags());
};
