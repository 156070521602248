type Obj = {} | Array<{}>;
type MethodFn = (value: Obj) => Obj;
export const deepMap = (obj: Obj, method: MethodFn): any => {
  if (obj && Array.isArray(obj)) {
    return obj.map(t => deepMap(t, method));
  } else if (obj && typeof obj === 'object') {
    const result = {};
    Object.keys(obj).forEach(k => (result[k] = deepMap(obj[k], method)));
    return result;
  } else {
    return method(obj);
  }
};
