import type { ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';

import type { UnlockPayload } from '../types';
import type { CourseCertificateType } from './types';

enum CourseActionTypes {
  FETCH_SYNC_COURSE = 'FETCH_SYNC_COURSE',
  FETCH_SYNC_COURSE_SUCCESS = 'FETCH_SYNC_COURSE_SUCCESS',
  FETCH_SYNC_COURSE_FAILURE = 'FETCH_SYNC_COURSE_FAILURE',
  FETCH_SYNC_COURSE_COMPLETED = 'FETCH_SYNC_COURSE_COMPLETED',

  FETCH_COURSES_PROGRESS = 'FETCH_COURSES_PROGRESS',
  FETCH_COURSES_PROGRESS_SUCCESS = 'FETCH_COURSES_PROGRESS_SUCCESS',
  FETCH_COURSES_PROGRESS_BATCH_SUCCESS = 'FETCH_COURSES_PROGRESS_BATCH_SUCCESS',
  FETCH_COURSES_PROGRESS_FAILURE = 'FETCH_COURSES_PROGRESS_FAILURE',
  FETCH_COURSES_PROGRESS_BATCH_FAILURE = 'FETCH_COURSES_PROGRESS_BATCH_FAILURE',

  CALCULATE_COURSE_COMPLETION = 'CALCULATE_COURSE_COMPLETION',

  UPDATE_COURSE_OPENED = 'UPDATE_COURSE_OPENED',
  UPDATE_COURSE_COMPLETED = 'UPDATE_COURSE_COMPLETED',
  UPDATE_COURSES_UNLOCK = 'UPDATE_COURSES_UNLOCK',

  DID_OPEN_COURSE = 'DID_OPEN_COURSE',

  HAS_BEEN_PROMPT_RATING = 'HAS_BEEN_PROMPT_RATING',

  FETCH_COURSE_CERTIFICATE = 'FETCH_COURSE_CERTIFICATE',
  FETCH_COURSE_CERTIFICATE_SUCCESS = 'FETCH_COURSE_CERTIFICATE_SUCCESS',
  FETCH_COURSE_CERTIFICATE_FAILURE = 'FETCH_COURSE_CERTIFICATE_FAILURE',

  FETCH_COURSE_LESSONS = 'FETCH_COURSE_LESSONS',
  FETCH_COURSE_LESSONS_SUCCESS = 'FETCH_COURSE_LESSONS_SUCCESS',
  FETCH_COURSE_LESSONS_FAILURE = 'FETCH_COURSE_LESSONS_FAILURE'
}

const CourseActions = {
  fetchSyncCourse: (courseId: string) =>
    createAction(CourseActionTypes.FETCH_SYNC_COURSE, { courseId }),

  /**
   * It gets called either on failure or success.
   * Because the fetchSync is coupled with different stuff, and
   * we need a way to wait for everything that happens inside the saga to complete
   */
  fetchSyncCourseCompleted: () => createAction(CourseActionTypes.FETCH_SYNC_COURSE_COMPLETED),

  fetchCoursesProgress: (courseIds: string[]) =>
    createAction(CourseActionTypes.FETCH_COURSES_PROGRESS, { ids: courseIds }),

  calculateCourseCompletion: (courseId: string) =>
    createAction(CourseActionTypes.CALCULATE_COURSE_COMPLETION, { courseId }),

  updateCourseOpened: (courseId: string, courseVersionNumber: number) =>
    createAction(CourseActionTypes.UPDATE_COURSE_OPENED, { courseId, courseVersionNumber }),
  updateCourseCompleted: (
    courseId: string,
    completed: boolean,
    wasCompletedBefore: boolean,
    percentageCompleted: number,
    lessonsCompleted: number,
    lessonsUnlocked: number
  ) =>
    createAction(CourseActionTypes.UPDATE_COURSE_COMPLETED, {
      courseId,
      completed,
      wasCompletedBefore,
      percentageCompleted,
      lessonsCompleted,
      lessonsUnlocked
    }),
  updateCoursesUnlock: (items: UnlockPayload[]) =>
    createAction(CourseActionTypes.UPDATE_COURSES_UNLOCK, { items }),

  didOpenCourse: (courseId: string) =>
    createAction(CourseActionTypes.DID_OPEN_COURSE, { courseId }),

  hasBeenPromptRating: (courseId: string) =>
    createAction(CourseActionTypes.HAS_BEEN_PROMPT_RATING, { courseId }),

  fetchCourseCertificate: (courseId: string) =>
    createAction(CourseActionTypes.FETCH_COURSE_CERTIFICATE, { courseId }),
  fetchCourseCertificateSuccess: (courseId: string, data: CourseCertificateType) =>
    createAction(CourseActionTypes.FETCH_COURSE_CERTIFICATE_SUCCESS, { courseId, ...data }),

  fetchCourseLessons: (courseId: string) =>
    createAction(CourseActionTypes.FETCH_COURSE_LESSONS, { courseId })
};

type CourseActionsUnionType = ActionsUnion<typeof CourseActions>;

export { CourseActions, CourseActionTypes, CourseActionsUnionType };
