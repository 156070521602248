import * as React from 'react';

import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Box } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { NotificationsLink } from '@maggie/components/notifications/link';
import type { ViewHeadType } from '@maggie/core/router/types';
import { AppLayoutContext } from '@maggie/layout/AppLayoutContext';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';
import { NavigationIcons } from '@maggie/layout/navigation/navigation-icons/NavigationIcons';
import { MaxWidthLayoutStyles } from '@maggie/layout/styled';
import { UserSelectors } from '@maggie/store/user/selectors';
import type { ThemeType } from '@maggie/theme/theme';

import { ViewContext } from './ViewContext';
import { MenuToggle } from './menu-toggle/MenuToggle';
import { getViewTypeStyles, strictViewType } from './view-utils';

export const ViewHead: React.FC = React.memo(() => {
  const isNewNavbar = useSelector(UserSelectors.hasNewNavbarEnabled);
  const {
    locked,
    isAppMenuOpen,
    shouldShowStarBalance,
    isGameAvailable,
    balance,
    viewHeadComponents,
    hasBackButton
  } = React.useContext(AppLayoutContext);
  const {
    title,
    viewHeadType,
    setIsViewHeadSubtle,
    isViewHeadSubtle,
    viewHeadTextColor
  } = React.useContext(ViewContext);

  const isMobile = useIsMobile();
  const [expandTitle, setExpandTitle] = React.useState(false);
  const [isTitleTruncated, setIsTitleTruncated] = React.useState(false);
  const titleRef = React.useRef<HTMLHeadingElement>(null);
  const shouldDisplayMenuToggle = isNewNavbar ? hasBackButton : hasBackButton || isMobile;

  React.useEffect(() => {
    setIsViewHeadSubtle(strictViewType(viewHeadType, isMobile) === 'subtle');
  }, [isMobile]);

  React.useEffect(() => {
    if (!!titleRef.current && titleRef.current.scrollHeight > titleRef.current.offsetHeight) {
      setIsTitleTruncated(true);
    }
  }, [isMobile]);

  const handleClickTitle = () => {
    if (isTitleTruncated) {
      setExpandTitle(!expandTitle);
    }
  };

  if (locked) {
    return null;
  }

  return (
    <ViewHeadComponent
      data-testid="view-head"
      isSubtle={isViewHeadSubtle}
      isMobile={isMobile}
      viewType={viewHeadType}
      textColor={viewHeadTextColor}
      className={!!isViewHeadSubtle ? 'subtle-navbar' : ''}
    >
      <ContentWrapper
        flex
        flexDirection="row"
        alignItems="center"
        {...(!isMobile && { justifyContent: 'center' })}
      >
        {shouldDisplayMenuToggle && <MenuToggle />}

        <Box
          pl={isMobile ? 2 : 0}
          pr={!isMobile && hasBackButton ? 10.5 : 0}
          flex="1 0 auto"
          alignItems="center"
          {...(!isMobile && { justifyContent: 'center' })}
        >
          <Title<'h1'>
            ref={titleRef}
            component="h1"
            variant="labelLarge"
            showFullTitle={expandTitle || !isMobile}
            clamp={1}
            onClick={handleClickTitle}
            textAlign={isMobile ? 'left' : 'center'}
          >
            {title}
          </Title>
        </Box>
        {isMobile && <StyledNotificationsLink />}

        {!isNewNavbar && (
          <NavigationIcons
            shouldShiftIcons={isAppMenuOpen || expandTitle}
            shouldShowStarBalance={shouldShowStarBalance}
            isGameAvailable={isGameAvailable}
            balance={balance}
            viewHeadComponents={viewHeadComponents}
            useIconsFor={!isMobile ? 'viewHead' : undefined}
          />
        )}
      </ContentWrapper>
    </ViewHeadComponent>
  );
});

const Title = styled(Text)<{ showFullTitle: boolean }>`
  flex-grow: 1;
`;

const ContentWrapper = styled(Box)`
  position: relative;
  ${MaxWidthLayoutStyles}
  padding-left: 0;
  padding-right: 0;
`;

type ViewHeadComponentProps = {
  isSubtle: boolean;
  isMobile: boolean;
  viewType: ViewHeadType | { mobile: ViewHeadType; desktop: ViewHeadType };
  textColor: string;
};
const ViewHeadComponent = styled.div<ViewHeadComponentProps>(
  ({ theme, isSubtle, isMobile, viewType, textColor }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    z-index: 1;
    flex-shrink: 0;
    transition: all 300ms;
    height: ${theme.topContainerHeight}px;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);

    ${
      !isMobile &&
      css`
        padding-top: ${theme.space(1)}px;
        padding-bottom: ${theme.space(1)}px;
      `
    }

    ${getViewTypeStyles(viewType, isMobile, theme).viewHead}
    
    ${getSubtleStyles(viewType, isMobile, isSubtle, textColor, theme)}
  `
);

const getSubtleStyles = (
  viewType: ViewHeadType | { mobile: ViewHeadType; desktop: ViewHeadType },
  isMobile: boolean,
  isSubtle: boolean,
  textColor: string,
  theme: ThemeType
) => {
  const type = strictViewType(viewType, isMobile);

  if (type === 'subtle' && isSubtle) {
    return css`
      background: transparent;
      color: ${theme.colors[textColor] || textColor};
    `;
  } else {
    return css`
      background-color: ${theme.colors.white};
      color: ${theme.colors.text};
      border-bottom: 1px solid ${theme.colors.grey};
    `;
  }
};

const StyledNotificationsLink = styled(NotificationsLink)(
  ({ theme }) => css`
    margin-right: ${theme.space(1)}px;
  `
);
