import type { ILocalNotification } from 'cordova-plugin-local-notification';
import { get } from 'lodash';

import { ErrorLogger } from '@edapp/monitoring';
import { Platform } from '@maggie/cordova/platform';
import ConfigActions from '@maggie/store/config/actions';

import { config } from './config';
import { handleNotification } from './notificationHandlers';
import type { Notification } from './types';

let push: PhonegapPluginPush.PushNotification | undefined;
let registrationId: string | undefined;
let attempts = 0;
let listenersSet = false;

const getLocalPlugin = (): LocalNotification | undefined =>
  get(window, 'cordova.plugins.notification.local');
const getPushPlugin = (): PhonegapPluginPush.PushNotificationStatic | undefined =>
  window.PushNotification;

const schedule = (notif: ILocalNotification | ILocalNotification[]) => {
  if (!Array.isArray(notif)) {
    notif = [notif];
  }
  if (Platform.get() === 'Android') {
    notif = notif.map(n => ({ ...n, ...config.android }));
  }
  const plugin = getLocalPlugin();
  if (plugin) {
    plugin.schedule(notif);
  }
};

const cancel = async (ids: number | number[]) => {
  const plugin = getLocalPlugin();
  if (!plugin) {
    return;
  }
  return new Promise<void>((resolve, reject) => {
    try {
      plugin.cancel(ids, resolve);
    } catch (err) {
      reject(err);
    }
  });
};

const cancelAll = () => {
  const plugin = getLocalPlugin();
  if (!plugin) {
    return;
  }

  plugin.cancelAll?.();
};

const initLocalClickListener = () => {
  const plugin = getLocalPlugin();
  if (!listenersSet && plugin && plugin.on) {
    listenersSet = true;
    plugin.on('click', (notification?: any) => {
      if (typeof (notification ? notification.data : undefined) === 'object') {
        if (get(notification, 'data.type') === 'prizing') {
          window.__router.navigate('game', {});
        } else if (get(notification, 'data.type') === 'content' && notification.data.lessonId) {
          window.__router.navigate('lesson', { id: notification.data.lessonId });
        } else if (get(notification, 'data.type') === 'brain-boost') {
          window.__router.navigate('brainBoost', {});
        }
      }
    });
  }
};

/**
 * Register for push and local notifications with the cordova plugins
 */
const initialize = () => {
  const pushPlugin = getPushPlugin();
  if (!pushPlugin) {
    return;
  }
  if (push || registrationId) {
    return;
  }

  push = pushPlugin.init(config.push);
  push.on('registration', result => {
    registrationId = result ? result.registrationId : '';
    window.__store.dispatch(ConfigActions.setMobilePushToken(registrationId));
  });

  push.on('notification', notification => {
    if (notification.additionalData.topic) {
      handleNotification(notification as Notification);
    }
  });
  push.on('error', error => {
    console.error('Error registering for push notifications', error);
    attempts = attempts + 1;
    if (!(attempts > config.registerAttempts)) {
      push = undefined;
      initialize();
    }

    ErrorLogger.captureException(error);
  });
};

/**
 * Fire Queued Events from local notifications plugin
 */
const fireLocalNoticationsEvents = () => {
  const localPlugin = getLocalPlugin();
  if (!localPlugin) {
    return;
  }
  localPlugin.fireQueuedEvents();
};

const getDeviceToken = () => registrationId;

export const Notifier = {
  initialize,
  getDeviceToken,
  schedule,
  cancel,
  initLocalClickListener,
  fireLocalNoticationsEvents,
  cancelAll
};
