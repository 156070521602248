import type { DirectionProperty } from 'csstype';

import type { RequestTypes } from '@edapp/request';

import type { AssessmentsState } from './assessments/types';
import type { AwardedCertificatesState } from './awarded-certificates/types';
import type { CollectionType, CollectionsState, CourseSummaryType } from './collections/types';
import type { ConferencesState } from './conferences/types';
import type { CourseType, CoursesState, LessonSummaryType } from './courses/types';
import type { DiscussionsState } from './discussions/types';
import type { DocumentType, DocumentsState } from './documents/types';
import type { GroupTrainingState } from './group-training/types';
import type { LessonType, LessonsState } from './lessons/types';
import type { MicroCredentialsState } from './micro-credentials/types';
import type { ObservationsState } from './observations/types';
import type { PlaylistSummaryType, PlaylistsState } from './playlists/types';
import type { RapidRefreshesState } from './rapid-refreshes/types';

export type DictionaryPostComments<T = CommentType> = {
  [postId: string]: RequestTypes.PaginatedResponse<T>;
};

export type CommentType = {
  id: string;
  content: string;
  timestamp: string;
  userId: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userAvatarUrl: string;
  userCustomFields: string[];
  userIsAdministrator: boolean;
};

export type StyleType = {
  text: 'white' | 'black';
  background: {
    image?: string;
    color?: string;
  };
};

export type MetaTags = {};

export type ImportDetails = {
  originalApplicationId: string;
  originalCourseId: string;
  originalLessonId: string;
  originalDocumentId: string;
  timestamp: string;
};

export type AttemptRestrictionType = {
  resetCourseOnNumberAttempts: number;
  shouldLockOnCompletion: boolean;
  lockIntervalMin: number;
};

export type Features = {
  areStarsEnabled: boolean;
  attemptRestrictionApiResource: AttemptRestrictionType;
};

export type CompletionCriteria = {
  certificateEnabled: boolean;
  lessonIds: string[];
  milestone: 'percentage' | 'lessons';
  openToComplete: boolean;
  percentage: number;
};

export type PrerequisitesType = {
  id: string;
  title: string;
};

export type PlanningType = {
  startDateTime?: string;
  endDateTime?: string;
};

export type Audience = {
  allowAllUserGroups: boolean;
  applicationId: string;
};

export enum MediaType {
  IMAGE = 'Image',
  VIDEO = 'Video',
  RAW = 'Raw',
  NOT_SPECIFIED = 'NotSpecified'
}

export type Media = {
  location: string;
  type: MediaType;
  id?: string;
};

export enum CoursewareTypeNames {
  COLLECTION = 'CourseCollection',
  COURSE = 'Course',
  COURSE_SUMMARY = 'CourseSummary',
  LESSON = 'Lesson',
  LESSON_SUMMARY = 'LessonSummary',
  DOCUMENT = 'BriefcaseDocument',
  PLAYLIST = 'Playlist',
  PLAYLIST_SUMMARY = 'PlaylistSummary',
  CONFERENCE = 'Conference'
}

export enum WatchStatus {
  NOT_SPECIFIED = 'NotSpecified',
  WATCHING = 'Watching',
  NOT_WATCHING = 'NotWatching'
}

export type SummaryListItem = {
  correct: boolean;
  question: string;
  takeaway: string;
  answers: Array<{
    content: string;
    correct: boolean;
    userSelected: boolean;
  }>;
};

export type CoursewareState = {
  collections: CollectionsState;
  courses: CoursesState;
  lessons: LessonsState;
  documents: DocumentsState;
  isLoading: boolean;
  playlists: PlaylistsState;
  discussions: DiscussionsState;
  assessments: AssessmentsState;
  conferences: ConferencesState;
  microCredentials: MicroCredentialsState;
  rapidRefreshes: RapidRefreshesState;
  observations: ObservationsState;
  awardedCertificates: AwardedCertificatesState;
  groupTraining: GroupTrainingState;
};

export type CoursewareUnionType =
  | CollectionType
  | CourseType
  | CourseSummaryType
  | LessonType
  | LessonSummaryType
  | DocumentType
  | PlaylistSummaryType;

export type Colors = {
  background?: string;
  text?: 'white' | 'black';
  experimental?: boolean;
  theme?: string;
  primaryPaletteColor?: string;
  secondaryPaletteColor?: string;
  primaryFontColor?: string;
  secondaryPFonteColor?: string;
};

export type StyleConfiguration = {
  background: string;
  colors?: Colors;
  enableCustomCSS: boolean;
  customCSS: string;
  direction: DirectionProperty;
  disableInteractionSummary: boolean;
  disableLessonScore: boolean;
  hasAnswerFeedback: boolean;
  hasScormExitButton: boolean;
  preventLogoutOnExit: boolean;
  indicator: boolean;
  logo: string;
  minimumScore: number;
  pageNumbers: boolean;
  pagination: boolean;
  language: string;
  soundDisabled: boolean;
  preferences: StyleConfigurationPreferences;
};

export type StyleConfigurationPreferences = {
  useParentLogo: boolean;
  useParentBackgroundImage: boolean;
  useParentCustomCss: boolean;
  useParentColors: boolean;
};

export type UnlockPayload = {
  id: string;
  title: string;
  unlocked: boolean;

  // To track the course version of the collection/course/lesson
  courseVersionNumber?: number;
};

export enum CoursewareVersionType {
  Latest = 'Latest',
  Published = 'Published'
}
