import * as DOMPurify from 'dompurify';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, LockIcon, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { StyledDialog, StyledDialogActionButton } from '@maggie/components/dialogs/styled';

type Props = {
  onButtonClick: () => void;
  onClose: () => void;
  isOpen: boolean;
  contentText: string;
  title: string;
  acceptText?: string;
};

export const LockedDialog: React.FC<Props> = ({
  isOpen,
  onButtonClick,
  onClose,
  title,
  contentText,
  acceptText
}) => {
  const { t } = useTranslation('learners-experience');
  return (
    <StyledDialog isOpen={isOpen} onClose={onClose}>
      <StyledBox p="md" textAlign="center">
        <LockIcon color="navyMuted" size="xl" />
        <Text<'h2'>
          variant="titleLarge"
          component="h2"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
        />
        <Typography
          variant="paragraph"
          color="text"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contentText) }}
        />
      </StyledBox>
      <StyledDialogActionButton onClick={onButtonClick} variant="transparent">
        {acceptText ? acceptText : t('locked.accept')}
      </StyledDialogActionButton>
    </StyledDialog>
  );
};

const StyledBox = styled(Box)`
  color: ${({ theme }) => theme.colors.navy};
`;
