import * as React from 'react';

import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Box, Lottie, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import CONFETTI from '../assets/confetti_achievements_yellow.json';
import { AchievementColorScheme } from '../types';
import type { Achievement } from '../types';
import { getBackgroundColorFromScheme } from '../utils';

const animBadge = {
  opacity: [0, 1],
  y: [200, 0],
  transition: {
    duration: 0.5,
    delay: 0
  }
};

const animTitle = {
  ...animBadge,
  transition: {
    ...animBadge.transition,
    delay: 0.1
  }
};

const animSubtitle = {
  ...animTitle,
  transition: {
    duration: 0.4,
    delay: 0.3
  }
};

type Props = Pick<
  Achievement,
  'badgeBorderColor' | 'badgeMediaUrl' | 'completion' | 'colorScheme' | 'title'
> & {
  withBackgroundAnimation?: boolean;
  animationVideoUrl?: string;
};

export const AchievementPreview: React.FC<Props> = ({
  badgeBorderColor,
  badgeMediaUrl,
  withBackgroundAnimation = true,
  colorScheme,
  completion,
  title
}) => {
  const textColor = colorScheme === AchievementColorScheme.DARK ? 'white.default' : 'black.default';
  return (
    <Preview
      flex
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      background={getBackgroundColorFromScheme(colorScheme)}
      testId="custom-achievement-preview"
    >
      {withBackgroundAnimation && (
        <motion.div animate={{ opacity: [1, 0], transition: { duration: 0.3, delay: 7 } }}>
          <AnimationLottie data={CONFETTI} autoplay={true} loop={false} />
        </motion.div>
      )}
      <motion.div animate={animBadge}>
        <IconBox
          data-testid="custom-achievement-preview-icon"
          borderColor={badgeBorderColor}
          src={badgeMediaUrl}
        />
      </motion.div>
      <motion.div animate={animTitle}>
        <Box mx="xs" mb="xs">
          <TitleTypography variant="headlineSmall" color={textColor} textAlign="center">
            {title.toUpperCase()}
          </TitleTypography>
        </Box>
      </motion.div>
      <motion.div animate={animSubtitle}>
        <Box mx="xs" mb="xl">
          <BodyTypography component="p" variant="bodyMedium" color={textColor} textAlign="center">
            {completion}
          </BodyTypography>
        </Box>
      </motion.div>
    </Preview>
  );
};

const Preview = styled(Box)`
  background: ${(props: { background: string }) => props.background};
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
`;

const AnimationLottie = styled(Lottie)`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const TitleTypography = styled(Text)`
  opacity: 75%;
  margin-bottom: ${({ theme }) => theme.space()}px !important;
`;

const BodyTypography = styled(Text)`
  position: relative;
  line-height: 20px;
  padding: 0;
  @media (min-width: ${({ theme }) => (theme as any)?.screens?.screenMdMin}px) {
    padding: 0 32px;
  }
`;

const IconBox = styled.img<{ borderColor: string }>(
  ({ theme, borderColor }) => css`
    border: ${theme.space(0.5)}px solid ${borderColor};
    background-color: ${borderColor};
    border-radius: 100%;
    // Deep shadow with a 2px shift to the right
    box-shadow: 2px 2px 16px -2px rgb(0 0 0 / 64%);
    margin-bottom: ${theme.space(6)}px;
    width: 140px;
    height: 140px;
    object-fit: cover;
    position: relative;
  `
);
