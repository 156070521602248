import * as React from 'react';
import reactLoadingSkeletonStyles from 'react-loading-skeleton/dist/skeleton.css';
import ReactLoadingSkeleton from 'react-loading-skeleton/dist';
import type { SkeletonStyleProps } from 'react-loading-skeleton/dist';
import styled, { css, useTheme } from 'styled-components';
import { rgba } from '@edapp/themes';

type Props = {
  /**
   * determine sizing, shape.
   * block represents a rectangle, width/height follow the props
   * text will be slightly smaller relative to its container as text is. It's determined by line-height
   * circular represents a circle, for example avatar.
   * @default "block"
   */
  variant?: 'text' | 'circular' | 'block';
  /**
   * number of rows, mainly used for text variant. Can be used to represent multi-line text.
   */
  rows?: number;
  /**
   * height of the skeleton
   */
  height?: number | string;
  /**
   * width of the skeleton
   */
  width?: number | string;
  className?: string;
  style?: React.CSSProperties;
} & Omit<SkeletonStyleProps, 'width' | 'height' | 'count'>;

export const Skeleton: React.FC<Props> = ({
  variant = 'block',
  rows,
  width = '100%',
  height,
  ...rest
}) => {
  const theme = useTheme();
  if (variant === 'circular') {
    return (
      <StyledSkeleton
        variant="circular"
        circle
        width={width}
        height={height ?? width} // a circle's height is the same as its width by default
        baseColor={theme.colors.grey}
        highlightColor={rgba(theme.colors.lightGrey, 0.3)}
        {...rest}
      />
    );
  }
  return (
    <StyledWrapper width={width} height={height}>
      <StyledSkeleton
        variant={variant}
        width="100%"
        height="100%"
        count={rows}
        baseColor={theme.colors.grey}
        highlightColor={rgba(theme.colors.lightGrey, 0.3)}
        {...rest}
      />
    </StyledWrapper>
  );
};

const StyledSkeleton = styled(ReactLoadingSkeleton)<Pick<Props, 'variant'>>(
  ({ variant }) => css`
    ${reactLoadingSkeletonStyles}
    ${(() => {
      switch (variant) {
        case 'circular':
        case 'block': {
          return 'line-height: unset;'; // without this, the width/height will not be accurate due to line-height: 1 from the skeleton. See https://github.com/dvtng/react-loading-skeleton/issues/23
        }
      }
    })()}
  `
);

const StyledWrapper = styled.div<Pick<Props, 'width' | 'height'>>(
  ({ width, height }) => css`
    width: ${typeof width === 'string' ? width : `${width}px`};
    ${height ? `height: ${typeof height === 'string' ? height : `${height}px`};` : ''}
  `
);
