import * as React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { StarRating } from '@edapp/ed-components';
import {
  Box,
  Button,
  CharacterCountTextArea,
  Dialog,
  Divider,
  Typography
} from '@edapp/ed-components';
import { CoursewareUtils } from '@maggie/store/courseware/utils';
import { RatingDialogActions } from '@maggie/store/rating-dialog/actions';
import type { LxStoreState } from '@maggie/store/types';

type Props = {
  open: boolean;
  onClose: () => void;
  backgroundImage: string | undefined;
  title: string;
  userRating: number | null;
  userReview: string | null;
};

const CourseRatingDialog: React.FC<Props> = ({
  open,
  onClose,
  backgroundImage,
  title,
  userRating,
  userReview
}) => {
  const { t } = useTranslation('learners-experience');
  const [rating, setRating] = React.useState<number | null>(userRating);
  const [review, setReview] = React.useState<string>(userReview || '');

  React.useEffect(() => {
    setRating(userRating);
  }, [userRating]);

  React.useEffect(() => {
    if (userReview) {
      setReview(userReview);
    }
  }, [userReview]);

  React.useEffect(() => {
    if (!open) {
      setReview('');
    }
  }, [open]);

  const onCloseDialog = () => {
    setRating(userRating);
    onClose();
  };

  const course = { brandingImage: backgroundImage };
  const styleObject = CoursewareUtils.getBrandingStyleObject(course);

  const dispatch = useDispatch();
  const courseId = useSelector((state: LxStoreState) => state.navigation.course.id);

  const starRatingOnClick = (returnRating: number) => {
    setRating(returnRating);
  };

  const onSubmit = () => {
    if (!rating) return;

    dispatch(RatingDialogActions.setCourseRating(courseId, rating, review));
  };

  const onChangeReview = (e: any) => {
    setReview(e.target.value);
  };

  const reviewCharacterLimit = 200;

  return (
    <StyledDialog isOpen={open} onClose={onCloseDialog}>
      {styleObject && (
        <StyledBackgroundImage
          backgroundImage={styleObject.background.image}
          backgroundColor={styleObject.background.color}
        />
      )}
      <RatingContainer px="lg" py="sm">
        {!rating && (
          <Box pb="md">
            <StyledTypography color="text">
              <Trans
                i18nKey="rating.how-was"
                values={{ course: title }}
                components={{ title: <CourseTitle color="text" as="b" /> }}
              />
            </StyledTypography>
          </Box>
        )}
        <RatingWrapper>
          <StarRating value={rating} onClick={starRatingOnClick} secondaryStarColor="grey" />
        </RatingWrapper>
        <Typography variant="subtitle2" color={rating ? 'text' : 'grey'}>
          {rating ? t('rating.tell-us-more') : t('rating.tap-to-rate')}
        </Typography>
      </RatingContainer>
      {rating && (
        <ReviewContainer>
          <Divider />
          <CharacterCountTextArea
            defaultValue={userReview || ''}
            onChange={onChangeReview}
            placeholder={t('rating.review-placeholder')}
            characterLimit={reviewCharacterLimit}
          />
          <Divider />
        </ReviewContainer>
      )}
      {rating ? (
        <StyledBox p="sm">
          <CancelButton variant="transparent" onClick={onCloseDialog}>
            <Typography color="greyActive" variant="paragraph">
              {t('common.cancel')}
            </Typography>
          </CancelButton>

          <Button
            disabled={!!review && review.length > reviewCharacterLimit}
            variant="primary"
            onClick={onSubmit}
          >
            <Typography color="white" variant="paragraph">
              {t('common.submit')}
            </Typography>
          </Button>
        </StyledBox>
      ) : (
        <Box flex={true} justifyContent="flex-end">
          <NotNowButton variant="transparent" onClick={onCloseDialog}>
            {t('common.not-now')}
          </NotNowButton>
        </Box>
      )}
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  flex-direction: column;
`;

type BackgroundProps = {
  backgroundImage?: string;
  backgroundColor?: string;
};

const StyledBackgroundImage = styled.div<BackgroundProps>`
  background: ${props => props.theme.colors.darkGrey} no-repeat center center/cover;
  background-image: ${props => props.theme.colors.brandedHeaderBranding};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: scale(1) translate3d(0, 0, 0);
  width: ${({ theme }) => theme.space(42)}px;
  height: ${({ theme }) => theme.space(15)}px;
  transform-origin: bottom center;
  ${({ backgroundColor, backgroundImage }) => `
    ${!!backgroundColor && `background-color: ${backgroundColor};`};
    ${!!backgroundImage && `background-image: ${backgroundImage};`}
  `}
`;

const RatingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: ${({ theme }) => theme.space(34)}px;
`;

const ReviewContainer = styled(Box)``;

const RatingWrapper = styled(Box)`
  padding-bottom: ${({ theme }) => theme.space(1.5)}px;
`;

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CancelButton = styled(Button)`
  ${({ theme }) => css`
    bottom: ${theme.space(2)}px;
    right: ${theme.space(2)}px;
  `}
`;

const NotNowButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.colors.blue};
    &:hover {
      color: ${theme.colors.blueHover};
    }
  `}
`;

const StyledTypography = styled(Typography)`
  font-size: 18px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: initial;
  ${({ theme }) => theme.lineClamp(4)}
`;

const CourseTitle = styled(StyledTypography)`
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  padding: 0;
  display: inline !important;
  text-align: center;
`;

export default CourseRatingDialog;
