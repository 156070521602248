import * as React from 'react';
import { Box } from '@edapp/ed-components';
import styled from 'styled-components';
import { Card, Text, Image } from '@edapp/sc-web-ui';

type Props = {
  image: string;
  title: string;
  description: string;
  linkText: string;
  linkUrl?: string;
  target: string;
  onClick?: () => void;
};

export const BrazeCaptionedImageCard: React.FC<Props> = ({
  image,
  title,
  description,
  linkUrl,
  target,
  linkText,
  onClick
}) => {
  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onClick?.();
  };

  return (
    <Container
      {...(linkUrl && {
        as: 'a',
        href: linkUrl,
        target: target,
        onClick: onClick ? handleOnClick : undefined
      })}
    >
      <StyledCard>
        <Card.MediaLayout aspectRatio={2} media={<Image src={image} />}>
          {title && <Text variant="labelLarge">{title}</Text>}
          {description && <Text variant="bodyExtraSmall">{description}</Text>}
          {linkText && <Text variant="bodyExtraSmall">{linkText}</Text>}
        </Card.MediaLayout>
      </StyledCard>
    </Container>
  );
};

const Container = styled(Box)`
  margin: 0 ${({ theme }) => theme.space(1)}px ${({ theme }) => theme.space(1)}px
    ${({ theme }) => theme.space(1)}px;
  &:hover {
    text-decoration: none;
  }
`;

const StyledCard = styled(Card)`
  max-width: 320px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`;
