import { darkColors } from './common/colors';
import type { ThemeType as CommonThemeType } from './common/theme';
import type { SchemeType } from './types';

export const schemes: SchemeType[] = ['ed-light', 'ed-dark', 'sc-light', 'sc-dark'];

export const getTheme = <T extends CommonThemeType>(themeType: SchemeType, baseTheme: T): T => {
  switch (themeType) {
    case 'sc-dark':
      return {
        ...baseTheme,
        colors: {
          ...baseTheme.colors,
          ...darkColors,
          ...scColors,
          ...scDarkColors
        }
      };
    case 'sc-light':
      return {
        ...baseTheme,
        colors: {
          ...baseTheme.colors,
          ...scColors
        }
      };
    case 'ed-dark':
      return {
        ...baseTheme,
        colors: {
          ...baseTheme.colors,
          ...darkColors
        }
      };
    case 'ed-light':
    default:
      return baseTheme;
  }
};

export const scColors = {
  blue: '#675DF4',
  lightBlue: '#F0F1FE',
  blueHover: '#736FF1',
  blueActive: '#564BE7',
  textBlue: '#675DF4'
};

const scDarkColors = {
  lightBlue: '#2A2947'
};
