import * as React from 'react';

import type { TargetAndTransition } from 'framer-motion';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { ChevronLeftIcon } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { rgba } from '@edapp/themes';
import type { BackButtonStyles } from '@maggie/core/router/types';
import { ToolbarBehaviour } from '@maggie/core/router/types';
import { AppLayoutContext } from '@maggie/layout/AppLayoutContext';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';
import { MaxWidthLayoutStyles } from '@maggie/layout/styled';
import { NavigationActions } from '@maggie/store/navigation/actions';
import type { ThemeType } from '@maggie/theme/theme';

import { SplitViewContext } from '../../split-view-context/SplitViewContext';
import { MainToolbarContext } from './MainToolbarContext';

const getPosition = (toolbarBehaviour: ToolbarBehaviour): React.CSSProperties['position'] => {
  switch (toolbarBehaviour) {
    case ToolbarBehaviour.sticky:
      return 'sticky';

    case ToolbarBehaviour.fixed:
      return 'relative';

    case ToolbarBehaviour.transparent:
    default:
      return 'absolute';
  }
};

const getContainerStyles = (
  toolbarBehaviour: ToolbarBehaviour,
  offset: number,
  backgroundColor: string
): TargetAndTransition => {
  switch (toolbarBehaviour) {
    case ToolbarBehaviour.transparent: {
      return {
        backgroundColor: rgba(backgroundColor, offset === 0 ? 0 : 1)
      };
    }

    case ToolbarBehaviour.fixed: {
      return {
        backgroundColor: backgroundColor
      };
    }

    default: {
      return {};
    }
  }
};

const getTitleStyles = (toolbarBehaviour: ToolbarBehaviour, offset: number) => {
  switch (toolbarBehaviour) {
    case ToolbarBehaviour.sticky: {
      return {
        opacity: offset === 0 ? 1 : 1 - (offset / 100 + 0.25),
        y: offset
      };
    }

    case ToolbarBehaviour.fixed: {
      return { opacity: 1 };
    }

    default: {
      return { opacity: offset === 0 ? 0 : 1 };
    }
  }
};

const getBackButtonStyles = (
  toolbarBehaviour: ToolbarBehaviour,
  theme: ThemeType,
  backButtonStyles?: BackButtonStyles
) => {
  if (!!backButtonStyles) {
    return css`
      color: ${theme.colors[backButtonStyles.color]};
      background-color: ${rgba(theme.colors[backButtonStyles.bgColor], 0.5)};
    `;
  }

  switch (toolbarBehaviour) {
    case ToolbarBehaviour.sticky:
    case ToolbarBehaviour.fixed:
    default:
      return css`
        background-color: ${rgba(theme.colors.fixedBlack, 0.5)};
        color: ${theme.colors.fixedWhite};
      `;
  }
};

type Props = {
  title: string;
  textColor: string;
  backgroundColor: string;
  toolbarBehaviour: ToolbarBehaviour;
  backButtonStyles?: BackButtonStyles;
};

export const MainToolbarContent: React.FC<Props> = React.memo(
  ({ title, textColor, backgroundColor, toolbarBehaviour, backButtonStyles }) => {
    const dispatch = useDispatch();

    const isMobile = useIsMobile();
    const { titleOffset } = React.useContext(MainToolbarContext);
    const { hasBackButton } = React.useContext(AppLayoutContext);
    const { mainRoute, mainParams } = React.useContext(SplitViewContext);

    const handleClick = () => {
      dispatch(NavigationActions.goBack(mainRoute, mainParams));
    };

    const offset = Math.max(titleOffset - 4, 0);

    return (
      <Container
        data-testid="main-toolbar"
        color={textColor}
        toolbarBehaviour={toolbarBehaviour}
        animate={getContainerStyles(toolbarBehaviour, offset, backgroundColor)}
        isMobile={isMobile}
      >
        {/* Go back */}
        {hasBackButton ? (
          <GoBackButton
            backButtonStyles={backButtonStyles}
            toolbarBehaviour={toolbarBehaviour}
            data-testid="go-back-button"
            onClick={handleClick}
          >
            <ChevronLeftIcon color="fixedBlack" />
          </GoBackButton>
        ) : (
          <div />
        )}

        {/* Title */}
        <Title
          data-testid="toolbar-title"
          variant="labelLarge"
          textAlign="center"
          clamp={1}
          animate={getTitleStyles(toolbarBehaviour, offset)}
          color="currentColor"
        >
          {title}
        </Title>

        {/* Actions */}
        <div />
      </Container>
    );
  }
);

const Title = motion(Text);

type GoBackButtonProps = {
  toolbarBehaviour: ToolbarBehaviour;
  backButtonStyles?: BackButtonStyles;
};
const GoBackButton = styled.div<GoBackButtonProps>(
  ({ theme, toolbarBehaviour, backButtonStyles }) => css`
    width: 32px;
    height: 32px;
    border-radius: ${theme.sizes.borderRadius}px;

    ${toolbarBehaviour === ToolbarBehaviour.transparent && `z-index: 12`};

    ${getBackButtonStyles(toolbarBehaviour, theme, backButtonStyles)}

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      fill: currentColor;
    }
  `
);

type ContainerProps = { toolbarBehaviour: ToolbarBehaviour; isMobile: boolean };
const Container = styled(motion.div)<ContainerProps>(
  ({ color, toolbarBehaviour, isMobile }) => css`
    display: grid;
    align-items: center;
    grid-template-columns: 48px 1fr 48px;
    color: ${color};

    .is-ios & {
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);
    }

    ${toolbarBehaviour !== ToolbarBehaviour.transparent && `z-index: 12`};

    position: ${getPosition(toolbarBehaviour)};
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    height: ${({ theme }) => theme.toolbarHeight}px;
    overflow: hidden;
    ${MaxWidthLayoutStyles}

    box-sizing: ${isMobile ? 'content-box' : 'border-box'};
  `
);
