import type { DictionaryType } from '@edapp/utils';

import type { UnlockPayload } from '../types';
import { CoursewareUtils } from '../utils';
import type { LessonProgressType } from './types';

const lessonProgressCompleteUpdated = (
  progress: LessonProgressType,
  lessonId: string,
  score: number
) => {
  return {
    [lessonId]: {
      ...progress,
      completed: true,
      completedDate: new Date().toISOString(),
      bestScore: !progress.bestScore || score > progress.bestScore ? score : progress.bestScore,
      previousBestScore: progress.bestScore,
      numberOfTimesCompletionHasBeenReset: 0
    }
  };
};

const lessonProgressAttemptFailureUpdated = (
  progress: LessonProgressType,
  lessonId: string,
  score: number
): DictionaryType<LessonProgressType> => ({
  [lessonId]: {
    ...progress,
    lastFailedAttemptDate: new Date().toISOString(),
    bestScore: !progress.bestScore || score > progress.bestScore ? score : progress.bestScore,
    numberOfFailedAttempts: progress.numberOfFailedAttempts + 1,
    // even if user fails - we will set a completed date to make sure clientIsAhead
    // server will process the attempt as an async job - so that means when user gets back to
    // see the score, we need to make sure the "completedDate" is ahead
    completedDate: progress.completedDate || new Date().toISOString()
  }
});

const lessonProgressUnlockUpdated = (
  lessonsProgress: DictionaryType<LessonProgressType>,
  items: UnlockPayload[]
) => {
  const updatedProgress = {};

  for (const item of items) {
    const progress = lessonsProgress[item.id];

    if (progress == null || progress.unlocked === item.unlocked) {
      continue; // nothing to update
    }

    updatedProgress[item.id] = {
      ...progress,
      unlocked: item.unlocked,
      unlockedDate: new Date()
    };
  }

  return updatedProgress;
};

const lessonProgressOpenUpdated = (progress: LessonProgressType, lessonId: string) => ({
  [lessonId]: {
    ...progress,
    opened: true,
    openedDate: new Date().toISOString()
  }
});

const lessonProgressEarnedStarsUpdated = (
  progress: LessonProgressType,
  lessonId: string,
  stars: number
) => ({
  [lessonId]: {
    ...progress,
    earnedStars: progress.earnedStars + stars
  }
});

const lessonsProgressReset = (
  lessonsProgress: DictionaryType<LessonProgressType>,
  lessonIds: string[]
) => {
  let updatedProgress: DictionaryType<LessonProgressType> = {};
  const resetDate = new Date().toISOString();
  for (const id of lessonIds) {
    const progress = lessonsProgress[id];

    if (progress == null) {
      break;
    }
    updatedProgress = {
      ...updatedProgress,
      [id]: {
        ...progress,
        completed: false,
        completedDate: null,
        completionReset: true,
        completionResetDate: resetDate,
        bestScore: 0,
        lastFailedAttemptDate: resetDate,
        numberOfFailedAttempts: 0
      }
    };
  }
  return updatedProgress;
};

/**
 * This functions receives two states of progress and updates the fields
 * according to timestamp logic.
 *
 * The main use case is in case client completed a lesson and server hasn't processed
 * the request yet.
 */
const applyProgressUpdates = (
  client: LessonProgressType,
  server: LessonProgressType
): LessonProgressType => {
  const unlockClientAhead = CoursewareUtils.isClientAhead(client, server, 'unlockedDate');
  const completionClientAhead = CoursewareUtils.isClientAhead(client, server, 'completedDate');
  const openClientAhead = CoursewareUtils.isClientAhead(client, server, 'openedDate');

  return {
    ...server,
    unlocked: unlockClientAhead ? client.unlocked : server.unlocked,
    unlockedDate: unlockClientAhead ? client.unlockedDate : server.unlockedDate,
    completed: completionClientAhead ? client.completed : server.completed,
    completedDate: completionClientAhead ? client.completedDate : server.completedDate,
    opened: openClientAhead ? client.opened : server.opened,
    openedDate: openClientAhead ? client.openedDate : server.openedDate,
    bestScore: completionClientAhead ? client.bestScore : server.bestScore
  };
};

export {
  lessonProgressCompleteUpdated,
  lessonProgressAttemptFailureUpdated,
  lessonProgressOpenUpdated,
  lessonProgressUnlockUpdated,
  lessonProgressEarnedStarsUpdated,
  lessonsProgressReset,
  applyProgressUpdates
};
