import * as React from 'react';
import { TextArea as EdTextArea, Typography } from '@edapp/ed-components';
import { rgba } from '@edapp/themes';
import styled, { css } from 'styled-components';
import type { TextAreaField } from '../demographic-question-form/types';

type Props = {
  name: string;
  onChange: (name: string, value: TextAreaField) => void;
  field: TextAreaField;
};

export const TextArea: React.FC<Props> = props => {
  const onInternalChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    let value = e.currentTarget.value;

    // property maxLength doesn't work for android - so we have to do this workaround here
    // https://codepen.io/K-ro/pen/NPWBNO
    // https://ed-app.atlassian.net/browse/ED-12261
    if (props.field.max >= 0 && value.length > props.field.max) {
      value = value.substring(0, props.field.max);
    }

    props.onChange(props.name, { ...props.field, value });
  };

  // We need to normaliize the value when there is break lines
  // maxLength counts break lines as two chars "\n"
  // https://bugzilla.mozilla.org/show_bug.cgi?id=702296
  // Example from: https://github.com/jquery-validation/jquery-validation/issues/2101
  // https://ed-app.atlassian.net/browse/ED-12262
  const normalizedValue = props.field.value.replace(/\n/g, '\r\n');

  return (
    <Wrapper value={normalizedValue}>
      <StyledTextArea
        isResizable={false}
        isFullWidth={true}
        onChange={onInternalChange}
        value={normalizedValue}
        placeholder={props.field.placeholder}
        maxLength={props.field.max}
      />
      <CharCount>
        {(normalizedValue || '').length || 0}/ <strong>{props.field.max}</strong>
      </CharCount>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ value: string | undefined }>(
  ({ theme, value }) => css`
    height: 125px;

    position: relative;

    border: 1px solid ${theme.colors.white} !important;
    border-radius: ${theme.sizes.borderRadius}px;
    background-color: ${rgba(theme.colors.white, 0.16)};

    padding: ${theme.space(1)}px;
    padding-right: ${theme.space(0.5)}px;
    padding-bottom: ${theme.space(3)}px;

    ${!!value && `box-shadow: 0 10px 12px -1px rgba(0,0,0,0.12);`}
  `
);

const CharCount = styled(Typography)`
  position: absolute;
  bottom: ${({ theme }) => theme.space(0.5)}px;
  right: ${({ theme }) => theme.space(0.5)}px;

  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.1875rem;
`;

const StyledTextArea = styled(EdTextArea)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;
    border: none !important;
    background: transparent;

    font-size: 1rem;
    line-height: 1.1875rem;
    font-weight: 500;
    color: ${theme.colors.white};

    ::placeholder {
      color: ${rgba(theme.colors.white, 0.8)};
    }
  `
);
