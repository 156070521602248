import { put, race, select, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { Urls } from '@maggie/store/constants';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { DocumentActions } from '../documents/actions';
import { DocumentSelectors } from '../documents/selectors';
import { LessonActions } from '../lessons/actions';
import { LessonSelectors } from '../lessons/selectors';
import type { UnlockPayload } from '../types';
import { CourseActionTypes, CourseActions } from './actions';
import type { CourseAction } from './sagas';
import { CourseSelectors } from './selectors';

type ProgressRaceType = {
  coursesProgress: CourseAction<CourseActionTypes.FETCH_COURSES_PROGRESS_SUCCESS>;
  failureProgress: CourseAction<CourseActionTypes.FETCH_COURSES_PROGRESS_FAILURE>;
};

function* handleFetchSyncCourse(action: CourseAction<CourseActionTypes.FETCH_SYNC_COURSE>): any {
  const { courseId } = action.payload;

  const isReviewer: boolean = yield select<LxStoreState>(s => UserSelectors.isReviewer(s.user));
  yield put(
    RequestActions.getAuthed(
      Urls.COURSE(courseId),
      CourseActionTypes.FETCH_SYNC_COURSE_SUCCESS,
      CourseActionTypes.FETCH_SYNC_COURSE_FAILURE,
      undefined,
      { includeDrafts: isReviewer }
    )
  );

  const { success, failure } = yield race({
    success: take(CourseActionTypes.FETCH_SYNC_COURSE_SUCCESS),
    failure: take(CourseActionTypes.FETCH_SYNC_COURSE_FAILURE)
  });

  if (failure) {
    return yield put(CourseActions.fetchSyncCourseCompleted());
  }

  const { payload } = success as CourseAction<CourseActionTypes.FETCH_SYNC_COURSE_SUCCESS>;
  const course = payload.courseInfo;

  // fetch lesson attempts in the course
  yield put(
    LessonActions.fetchLessonAttemptList(course?.lessonSummaries.map(l => l.lessonId) || [])
  );

  // Update unlock based on prerequisites
  if (course) {
    const storeState: LxStoreState = yield select(s => s);

    const items: UnlockPayload[] = yield select(
      LessonSelectors.getUnlockPayloadFromPrerequisites(course.lessonSummaries)
    );
    yield put(LessonActions.updateLessonsUnlock(items));

    const noProgressPrerequisiteIds = course.prerequisites.filter(
      prereqId => !CourseSelectors.getCourseProgress(prereqId, storeState)
    );

    let coursesProgress: ProgressRaceType['coursesProgress'] | undefined = undefined;
    if (noProgressPrerequisiteIds.length > 0) {
      // Fetching course progress for prerequisites in case it is accessed via deep link
      yield put(CourseActions.fetchCoursesProgress(noProgressPrerequisiteIds));

      const result: ProgressRaceType = yield race({
        coursesProgress: take(CourseActionTypes.FETCH_COURSES_PROGRESS_SUCCESS),
        failureProgress: take(CourseActionTypes.FETCH_COURSES_PROGRESS_FAILURE)
      });
      coursesProgress = result.coursesProgress;

      if (result.failureProgress) {
        yield put({
          type: CourseActionTypes.FETCH_SYNC_COURSE_FAILURE,
          payload: result.failureProgress.payload
        });
        return yield put(CourseActions.fetchSyncCourseCompleted());
      }
    }

    const existingProgress = course.prerequisites
      .map(prereqId => CourseSelectors.getCourseProgress(prereqId, storeState))
      .filter(progress => !!progress);

    const courseProgress = [...existingProgress, ...(coursesProgress?.payload.items || [])];
    const allCompleted = courseProgress.reduce((all, progress) => {
      return all && !!progress && progress.completed;
    }, true);

    yield put(
      CourseActions.updateCoursesUnlock([
        {
          id: course.id,
          title: course.title,
          unlocked: allCompleted,
          courseVersionNumber: course.versionNumber
        }
      ])
    );

    if (course.completionCriteria.certificateEnabled && payload.courseProgress.completed) {
      yield put(CourseActions.fetchCourseCertificate(courseId));

      // Workaround for the OldCourse screen whereby the "Course of Completion" label
      // is hidden when loaded after the course itself, awaiting fixes this issue.
      yield race({
        courseCertificate: take(CourseActionTypes.FETCH_COURSE_CERTIFICATE_SUCCESS),
        courseCertificateFailure: take(CourseActionTypes.FETCH_COURSE_CERTIFICATE_FAILURE)
      });
    }
  }

  /**
   * Calculate what documents in the course should be unlocked
   */
  const { briefcaseDocumentsProgress } = payload;
  if (briefcaseDocumentsProgress && briefcaseDocumentsProgress.length > 0) {
    const documentIdsToUnlock: UnlockPayload[] = [];

    for (const item of briefcaseDocumentsProgress) {
      const shouldUnlock: boolean = yield select(DocumentSelectors.shouldUnlockDocument(item));
      if (shouldUnlock) {
        documentIdsToUnlock.push({
          id: item.briefcaseDocumentId,
          title: item.briefcaseDocumentId,
          unlocked: true
        });
      }
    }

    if (documentIdsToUnlock.length >= 1) {
      yield put(DocumentActions.updateDocumentsUnlock(documentIdsToUnlock));
    }
  }

  yield put(CourseActions.fetchSyncCourseCompleted());
}

export function* watchFetchSyncCourse() {
  yield takeLatest(CourseActionTypes.FETCH_SYNC_COURSE, handleFetchSyncCourse);
}
