import { matchPath } from 'react-router';

import { parseQueryString } from '@maggie/store/navigation/utils';

import type { GenericRoutesType, RouteName, RouteParams } from './types';

type TemplateByRouteName = { [key: string]: RouteName };
type FindMatchResult = { routeName: RouteName; params: RouteParams<RouteName> } | undefined;

/**
 *
 * This class is converting hash router & query arguments to match react-router.
 *
 */
export class HashManager {
  private basename: string;
  private templatesByRouteName: TemplateByRouteName;

  constructor(routes: GenericRoutesType, basename: string = '') {
    this.templatesByRouteName = this.buildTemplatesDictionary(routes);
    this.basename = basename;
  }

  /**
   *
   * It creates a dictionary of urls by the name of the routes
   * example:
   * {
   *    'content-library/course/:courseId': 'contentLibraryCourse',
   *    'content-library/section/:sectionId': contentLibrarySection'
   * }
   *
   * @param routes
   */
  private buildTemplatesDictionary(routes: GenericRoutesType) {
    return Object.keys(routes).reduce((acc, routeName: RouteName) => {
      const template = routes[routeName].template();
      acc[template] = routeName;
      return acc;
    }, {});
  }

  /**
   * Finds the routeName and extract params from the navigated route.
   * Example:
   *    in: `#content-library/course/courseid-123`
   *    out: { routeName: 'contentLibraryCourse', params: {courseId: 'courseid-123'} }
   *
   * @param route - '#course/course-id123'
   */
  public findMatch(route: string): FindMatchResult {
    if (route[0] !== '#' && route[0] !== '/') {
      route = '/' + route;
    }

    const [normalizedRoute, queryArgsStr] = route.replace(/\/#|#/, '/').split('?');

    let queryArgs = {};
    if (!!queryArgsStr) {
      queryArgs = { query: parseQueryString(queryArgsStr) };
    }

    for (const template of Object.keys(this.templatesByRouteName)) {
      const m = matchPath(normalizedRoute, {
        path: [this.basename + template, template],
        exact: true
      });

      if (!!m) {
        return {
          routeName: this.templatesByRouteName[template],
          params: {
            ...m.params,
            ...queryArgs
          }
        };
      }
    }
  }
}
