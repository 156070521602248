import * as React from 'react';
import styled, { css } from 'styled-components';
import { TextArea } from './TextArea';
import type { CharacterCountTextAreaProps } from './types';
import { Typography } from '../../typography';
import { Box } from '../../box';

export const CHARACTER_COUNT_TEXT_AREA_TEST_ID = 'character-count-text-area';

type Props = CharacterCountTextAreaProps;

export const CharacterCountTextArea: React.FC<Props> = ({
  characterLimit,
  onChange,
  defaultValue,
  placeholder
}) => {
  const [textLength, setTextLength] = React.useState<number>(defaultValue?.length || 0);

  const onChangeTextArea = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    const length = target.value.toString().length;
    setTextLength(length);
    onChange?.(e);
  };

  let characterCounter: React.ReactNode;
  if (characterLimit) {
    characterCounter = (
      <CharCount
        color={textLength > characterLimit ? 'red' : 'text'}
      >{`${textLength}/${characterLimit}`}</CharCount>
    );
  } else {
    characterCounter = <CharCount color="text">{`${textLength}`}</CharCount>;
  }

  return (
    <TextAreaContainer>
      <StyledTextArea
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChangeTextArea}
        error={characterLimit ? textLength > characterLimit : false}
      />
      {characterCounter}
    </TextAreaContainer>
  );
};

const TextAreaContainer = styled(Box)`
  position: relative;
  display: flex;
`;

const CharCount = styled(Typography)`
  ${({ theme }) => css`
    position: absolute;
    bottom: ${theme.space()}px;
    right: ${theme.space()}px;
  `}
`;

type CombinedCCTextAreaProps = CharacterCountTextAreaProps & { error: boolean };

const StyledTextArea = styled(TextArea).attrs<CombinedCCTextAreaProps>(
  ({ testId = CHARACTER_COUNT_TEXT_AREA_TEST_ID }) => ({
    'data-testid': testId,
    isResizable: false,
    fitContents: true
  })
)<CombinedCCTextAreaProps>`
  ${({ theme, error }) => css`
    background-color: transparent;
    width: 100%;
    color: ${theme.colors.text};
    ${!!error &&
    `border: 1px solid ${theme.colors.red}; &:focus { border: 1px solid ${theme.colors.red}}`}
    margin-bottom: 0;
  `}
`;
