import styled from 'styled-components';

import type { TypographyVariant } from '@edapp/themes';

import { appendMargins, appendPaddings } from '../box/utils';
import type { Props } from './types';

export const TYPOGRAPHY_TEST_ID = 'typography';

const variantTagMapping = (variant: TypographyVariant) => {
  switch (variant) {
    case 'subtitle1':
      return 'h4';
    case 'subtitle2':
      return 'h5';
    case 'paragraph':
      return 'p';
    case 'small':
      return 'small';
    default:
      return 'span';
  }
};

const typographyAttrs = ({
  variant = 'normal',
  testId = TYPOGRAPHY_TEST_ID,
  as,
  isLink
}: Props) => ({
  as: isLink ? 'a' : as || variantTagMapping(variant),
  'data-testid': testId
});
/** @deprecated Use import { Text } from '@edapp/sc-web-ui' from SC */
export const Typography = styled.span
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['color'].includes(prop) && defaultValidatorFn(prop)
  })
  .attrs<Props>(typographyAttrs)<Props>(({ theme, ...props }) => {
  let css = `
      font-family: ${theme.fontFamily};
      margin: 0;
      color: ${theme.colors[props.color || 'text']};
      ${theme.typography[props.variant || 'normal']}
      > * {
        color: ${theme.colors[props.color || 'text']};
      }
    `;

  if (props.isLink) {
    css += `
      cursor: pointer;
      color: ${theme.colors[props.color || 'blue']};
      text-decoration: none;
      :hover, :active, :focus {
        text-decoration: underline;
        color: ${theme.colors[props.color || 'blue']};
      }
    `;
  }

  if (props.weight) {
    css += `font-weight: ${props.weight};`;
  }

  if (props.textAlign) {
    css += `text-align: ${props.textAlign};`;
  }

  // Add paddings and margins for individual sides
  css += `
      ${appendPaddings(props)}
      ${appendMargins(props)}
    `;

  if (props.textTransform) {
    css += `text-transform: ${props.textTransform};`;
  }

  if (props.textDecoration) {
    css += `text-decoration: ${props.textDecoration};`;
  }

  if (typeof props.lineClamp === 'number') {
    css += theme.lineClamp(props.lineClamp);
  }

  if (props.width && typeof props.width === 'number') {
    css += `width: ${props.width}px;`;
  }

  if (props.width && typeof props.width === 'string') {
    css += `width: ${props.width};`;
  }
  if (props.wordBreak) {
    css += `word-break: ${props.wordBreak};`;
  }

  if (props.whiteSpace) {
    css += `white-space: ${props.whiteSpace};`;
  }

  return css;
});
