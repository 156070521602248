import type { Store } from 'redux';

import { Platform } from '@maggie/cordova/platform';
import type { LxStoreState } from '@maggie/store/types';
import { UserActions } from '@maggie/store/user/actions';
import { UserSelectors } from '@maggie/store/user/selectors';

export const LOGGED_OUT_ELSEWHERE = 'logged-out-elsewhere';

export const handleLocalStorageChange = (store: Store<LxStoreState>, key: string | null) => {
  const state = store.getState();

  if (key === LOGGED_OUT_ELSEWHERE) {
    // `thomas-bridge` doesn't listen to redux - therefore we trigger backbone event
    Backbone.Events.trigger(LOGGED_OUT_ELSEWHERE);

    // triggered from a different tab and token hasn't been cleared yet
    if (UserSelectors.getToken(state)) {
      store.dispatch(UserActions.userLogout(true, false));
    }
  }
};

/**
 * Listens to local storage changes
 *
 * If change is related to LOGGED_OUT_ELSEWHERE it will trigger side effects.
 *
 * Background:
 * Learners App uses local storage and IndexDB shared accross the whole browser.
 * In case a user logs in (via deeplink & etc) in the same browser with a different tab,
 * we have to make sure we listen to that new sessionLogin from other tabs and act on it.
 */
const addLocalStorageListener = (store: Store<LxStoreState>) => {
  if (!Platform.isWeb()) {
    return;
  }

  window.addEventListener('storage', evt => handleLocalStorageChange(store, evt.key));
};

export { addLocalStorageListener };
