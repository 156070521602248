import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { itly } from '@edapp/analytics-tracking';
import { Box, SearchIcon, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { SearchCoursewareSVG } from '@maggie/components/content-library/search-course-list/SearchCoursewareSVG';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';
import { SearchActions } from '@maggie/store/search/actions';
import { SearchSelectors } from '@maggie/store/search/selectors';

export const RecentSearches: React.FC = () => {
  const { t } = useTranslation('learners-experience');
  const recentSearchTerms = useSelector(SearchSelectors.getRecentSearchTerms, shallowEqual);

  if (recentSearchTerms.length === 0) {
    return (
      <EmptyBox
        flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        py={2}
      >
        <StyledSearchCoursewareSVG />

        <Box mt={2}>
          <Text variant="titleLarge" component="h2">
            {t('home.search')}
          </Text>
        </Box>
        <Typography variant="small" color="textMuted" mt={2}>
          {t('home.search-help')}
        </Typography>
      </EmptyBox>
    );
  }

  return (
    <Box flex={true} flexDirection="column" gap={1}>
      <Box p={3}>
        <Text variant="labelLarge" component="h4">
          {t('home.recent-searches')}
        </Text>
      </Box>
      <StyledList>
        {recentSearchTerms.map((item, i) => (
          <RecentSearchItem key={i + item}>{item}</RecentSearchItem>
        ))}
      </StyledList>
    </Box>
  );
};

const RecentSearchItem: React.FC<{ children: string }> = ({ children }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(SearchActions.setSearchTerm(children));
    dispatch(SearchActions.setIsPreviewMode(false));
    itly.recentSearchItemClicked({ text: children });

    if (!isMobile) {
      window.__router.navigate('search', {}, true);
    }
  };

  return (
    <StyledListItem>
      <StyledItemSearchIcon />

      <StyledTypography variant="paragraph" ml={1.5} onClick={handleClick} lineClamp={2}>
        {children}
      </StyledTypography>
    </StyledListItem>
  );
};

const StyledItemSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.grey};
  padding: ${({ theme }) => theme.space(1)}px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-sizing: border-box;
`;

const StyledTypography = styled(Typography)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledList = styled.ul`
  padding: 0;
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: ${({ theme }) => theme.space(3)}px;
`;

const StyledSearchCoursewareSVG = styled(SearchCoursewareSVG)`
  height: 113px;
  width: 122px;
`;

const EmptyBox = styled(Box)`
  max-width: 240px;
  margin: auto;
`;
