import { changeLanguage, generateTitle, initI18NOnce } from '@edapp/translations/src/i18n';
import { queryParamAccessor } from '@edapp/utils';
import { ENV } from '@maggie/config/env';

import { supportedLanguages } from './constants/locale';

const CONTENT_LANG_QUERY_PARAM = 'content_lang';
const CONTENT_LANG_LOCAL_STORAGE = 'ed:content_lang';

// simple validation check - nothing too sophisticated to validate the actual locale code
const validateLangQueryParam = (value: string) => value.length < 10;

const initialize = async () => {
  // Initializes the window variable to be used when consuming content
  // It will be sent in the headers of all requests to hippo as `locale` - see `maggie/request/sagas`.
  const contentLang = queryParamAccessor(
    CONTENT_LANG_QUERY_PARAM,
    CONTENT_LANG_LOCAL_STORAGE,
    validateLangQueryParam
  );
  window.__contentLang = contentLang;

  // Initializes the resource strings in the correct language
  const l10n = await initI18NOnce({
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupQuerystring: 'lang'
    },
    supportedLngs: supportedLanguages,
    debug: ENV.NODE_ENV !== 'production',
    ns: ['learners-experience', 'sc-web-ui', 'lesson-navigation']
  });

  // Initializes dom html `lang` selector with the correct locale
  document.documentElement.lang = l10n.language;

  return l10n;
};

const change = async (language: string) => {
  // Initializes dom html `lang` selector with the correct locale
  document.documentElement.lang = language;

  await changeLanguage(language);
};

export const l10n = {
  initialize,
  change,
  generateTitle
};
