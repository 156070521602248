import type { MigrationManifest } from 'redux-persist';
import { KEY_PREFIX } from 'redux-persist';

import { Platform } from '@maggie/cordova/platform';
import { addDaysImmutably } from '@maggie/utils/date-time';

import type { SyncPropertiesToIgnore, UserState } from './user/types';

// UserState and things which used to be in UserState
type EternalUserState = UserState & Partial<SyncPropertiesToIgnore>;

const migrations: MigrationManifest = {
  /*
   * If it finds userInfo in the localStorage, it loads into redux
   *
   * === migrate campus token ===
   */
  0: (state: StoreWithPersistState) => {
    if (Platform.isWeb()) {
      const userInfo = window.localStorage.getItem('userInfo');
      if (!userInfo) {
        return state;
      }

      // tslint:disable-next-line: no-string-literal
      const userToken = JSON.parse(userInfo as string)['campus_token'];
      window.localStorage.removeItem('userInfo'); // clear userInfo - so it doesn't migrate the data every time
      (state.user as EternalUserState).campusToken = userToken;
      return { ...state };
    } else {
      // the migration has already happened when creating the store
      return state;
    }
  },
  1: (state: StoreWithPersistState) => {
    state.user.ed.notifications = {
      enabled: false,
      content: '',
      prizing: ''
    };
    return { ...state };
  },
  /**
   * Moves data store from `profile.upcomingLocalNotifications` to `local_notifications`
   */
  2: (state: StoreWithPersistState) => {
    const lastLessonIdScheduled = (state.profile as any)?.upcomingLocalNotifications?.lessonId;
    if (!lastLessonIdScheduled) {
      return state;
    }

    state.local_notification.lastLessonIdScheduled = lastLessonIdScheduled;
    return { ...state };
  },
  /**
   * Migrates from navigation.rapidRefreshId to navigation.rapidRefreshSessionId
   */
  3: (state: StoreWithPersistState) => {
    const rapidRefreshId = (state.navigation as any)?.rapidRefreshId;
    if (!rapidRefreshId) {
      return state;
    }

    state.navigation.rapidRefreshSessionId = { rapidRefreshId, session: 1 };
    return { ...state };
  },
  /**
   * Migrates the shape of the navigation datastore (considering users that never had rapidRefreshId)
   */
  4: (state: StoreWithPersistState) => {
    const rapidRefreshId = (state.navigation as any)?.rapidRefreshId;
    if (!rapidRefreshId) {
      state.navigation.rapidRefreshSessionId = { rapidRefreshId: '', session: 0 };
      return { ...state };
    }

    state.navigation.rapidRefreshSessionId = { rapidRefreshId, session: 1 };
    return { ...state };
  },
  /**
   * Migrates `navigation.courseId` to `navigation.course.id`
   */
  5: (state: StoreWithPersistState) => {
    const courseId = state.navigation.courseId;
    return { ...state, navigation: { ...state.navigation, course: { id: courseId, from: {} } } };
  },
  /**
   * Migrates 'streak' to 'brainBoost' state
   */
  6: (state: StoreWithPersistState) => {
    return {
      ...state,
      brainBoost: { ...state.brainBoost, toast: { ...state.brainBoost.toast, streak: 0 } }
    };
  },
  /**
   * Remove username from user state
   */
  7: (state: StoreWithPersistState) => {
    const newState = { ...state };
    delete (newState.user as any).username;
    return newState;
  },
  /**
   * 1. Remove `switchingAccounts` from profile persisted state
   * 2. Move `accounts` from user store to `profile` store
   */
  8: (state: StoreWithPersistState) => {
    const newState = { ...state };

    delete newState.profile['switchingAccounts'];
    newState.profile.accounts = state.user.accounts;

    return { ...newState };
  },
  /**
   * Removes resume lessons remnants
   */
  9: (state: StoreWithPersistState) => {
    window.localStorage.removeItem(`${KEY_PREFIX}:ED-thomas`);

    return state;
  },
  /**
   * Migrates playlists to offline state
   */
  10: (state: StoreWithPersistState) => {
    return {
      ...state,
      offline: {
        ...state.offline,
        courseware: {
          ...state.offline.courseware,
          playlists: {}
        }
      }
    };
  },
  /**
   * Make campusToken(Expiry) a sibling of other supported token types – currently EdToken access and refresh.
   */
  11: (state: StoreWithPersistState): StoreWithPersistState => {
    const user = state.user as EternalUserState;
    const { campusToken, campusTokenExpiry } = user;

    // C.f. Hippo.Domain.Common.AppUsers.AppUserConstants.SessionCampusTokens.ExpiryInDays
    const campusTokenExpiryDays = 30;

    return {
      ...state,
      user: {
        ...user,
        tokens: !campusToken
          ? []
          : [
              {
                type: 'CampusToken',
                value: campusToken,
                expiresAt: campusTokenExpiry
                  ? campusTokenExpiry
                  : addDaysImmutably(new Date(), campusTokenExpiryDays).toISOString()
              }
            ]
      }
    };
  },
  /**
   * Remove explicit campus token properties. Any campus token will be inside the 'tokens' array now.
   */
  12: (state: StoreWithPersistState): StoreWithPersistState => {
    const user = state.user as EternalUserState;
    delete user.campusToken;
    delete user.campusTokenExpiry;
    return {
      ...state,
      user
    };
  }
};

export { migrations };
